import { acceptationMessage, declinedMessage } from "./registration.constants";
import { RegistrationActions } from "containers/event/event.types";
import { InvitationActionType } from "components/EventInvitation/useEventInvitations";
import { SGBSColor } from "@sgbs-ui/core";

export interface Props {
  registrationStatus?: RegistrationActions;
  slotType?: string;
  isUpdate?: boolean;
}

export interface RegistrationData {
  icon?: "check" | "event_busy";
  message: any;
  title?: string;
  status?: SGBSColor;
}

const declinedEvent = (): RegistrationData => ({
  status: "warning",
  icon: "event_busy",
  message: declinedMessage,
  title: "Registration declined",
});

const confirmedEvent = (): RegistrationData => ({
  status: "success",
  icon: "check",
  message: acceptationMessage,
  title: "Registration confirmed",
});

export const getRegistrationModalData = (invitationActionType: InvitationActionType): RegistrationData => {
  switch (true) {
    case invitationActionType === "declineAllEvent":
      return declinedEvent();
    case invitationActionType === "modify":
      return confirmedEvent();
    case invitationActionType === "decline":
      return declinedEvent();
    case invitationActionType !== "decline":
      return confirmedEvent();
  }
};

import { DefaultOptions } from "@tanstack/react-query";

export const defaultQueryClientOptions: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    enabled: false,
    staleTime: Infinity,
    cacheTime: 0,
    retry: false,
  },
};

import * as React from "react";
import { EventDetail } from "containers/event/event.types";
import { first, flatten, get, isEmpty, sortBy } from "lodash";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import { ContactsGroups } from "../Contact/ContactsGroups";
import { CollapsibleContactsCard } from "../Contact/CardItem/CollapsibleContactsCard";
import { useTrackOnDisplayPage } from "../../../common/event.tracking";

const accordionId = "accordionContacts";

const Handout: React.VFC<{ handout: string }> = ({ handout }) => (
  <div className="my-3 px-4">
    <div className="text-medium font-weight-bold">Handout</div>
    <div>{handout}</div>
  </div>
);

export const EventCorporateContactsTab: React.VFC<{ eventDetail: EventDetail }> = ({ eventDetail }) => {
  useTrackOnDisplayPage(features.eventDetailsContactsDisplay);
  const corporateContacts = eventDetail.event.corporateContacts;
  const corporateHandout = eventDetail.event.corporateContactHandout;

  const externalSpeakers = eventDetail.event.externalSpeakerContacts;
  const investors = sortBy(flatten(eventDetail.slots.map((slot) => slot.invitees)), ["accountName", "friendlyName"]);

  const bankers = eventDetail.event.bankerContacts;

  return (
    <>
      <h3 className="mb-4 mt-4 mt-md-5 ml-2">Contacts</h3>
      <div className="row w-100 mx-0">
        <div id={accordionId} className="col-md-10 col-lg-8 border-bottom">
          {!isEmpty(externalSpeakers) && (
            <CollapsibleContactsCard
              id={1}
              accordionId={accordionId}
              title={get(first(externalSpeakers), "accountName")}
              skipFirst={true}
              onCollapse={logParticipantsSectionAction}
            >
              <ContactsGroups skipFirst={true} contacts={externalSpeakers} />
            </CollapsibleContactsCard>
          )}

          {!isEmpty(corporateContacts) && (
            <CollapsibleContactsCard
              id={0}
              accordionId={accordionId}
              title={"Corporate contacts"}
              bottomText={<Handout handout={corporateHandout} />}
            >
              <ContactsGroups contacts={corporateContacts} />
            </CollapsibleContactsCard>
          )}

          {!isEmpty(investors) && (
            <CollapsibleContactsCard
              id={2}
              accordionId={accordionId}
              title={"Investors"}
              onCollapse={logInvestorsSectionAction}
            >
              <ContactsGroups contacts={investors} />
            </CollapsibleContactsCard>
          )}

          {!isEmpty(bankers) && (
            <CollapsibleContactsCard
              id={3}
              accordionId={accordionId}
              title={"Other arranging banks"}
              onCollapse={logBanksSectionAction}
            >
              <ContactsGroups contacts={bankers} />
            </CollapsibleContactsCard>
          )}
        </div>
      </div>
    </>
  );
};

const FEATURE_NAME = "Contact page loading";
const logSectionAction = (sectionName: string, isExpanded: boolean): void => {
  const featureName = `${sectionName}${isExpanded ? "Extend" : "Collapse"}`;
  logMessage({
    ...features[featureName],
    type: LogTypes.functional,
    feature: "event",
    name: FEATURE_NAME,
  });
};

const logParticipantsSectionAction = (isExpanded: boolean): void => {
  logSectionAction("contactPageRoadshowParticipantsSection", isExpanded);
};

const logBanksSectionAction = (isExpanded: boolean): void => {
  logSectionAction("contactPageRoadshowBanksSection", isExpanded);
};

const logInvestorsSectionAction = (isExpanded: boolean): void => {
  logSectionAction("contactPageRoadshowInvestorsSection", isExpanded);
};

import * as React from "react";
import { ModifyRegistrationButton } from "../Modals/ModifyRegistrationButton";
import { MyInvitationDto } from "api/events/event.types";
import { InvitationStatus } from "common/event/event.types";
import { Alert, SGBSColor } from "@sgbs-ui/core";
import { InvitationStatusToMessageStatus, RegistrationMessageStatusColors } from "../Modals/registration.constants";

const getDisplayedStatus = (invitation: MyInvitationDto) =>
  invitation.status === InvitationStatus.Pending
    ? "pending"
    : invitation.status === InvitationStatus.Accepted
    ? "confirmed"
    : "declined";

type Props = {
  invitation: MyInvitationDto;
  isOneSlot: boolean;
  isUpdating: boolean;
  modifyRegistrationOneSlot: () => void;
  modifyRegistrationMultiSlot: () => void;
  someSlotsAvailable?: boolean;
};

export const CardModifyRegistration: React.VFC<Props> = ({
  invitation,
  isOneSlot,
  isUpdating,
  modifyRegistrationOneSlot,
  modifyRegistrationMultiSlot,
  someSlotsAvailable = true,
}) => {
  const color: SGBSColor = RegistrationMessageStatusColors[InvitationStatusToMessageStatus[invitation.status]];
  return (
    <div className="row mx-auto">
      <div className="col mt-3">
        <Alert color={color} className={`mb-0 bg-${color}-alpha-md text-${color}`}>
          <h3 className="d-none d-lg-flex">Your invitation</h3>
          <p>
            {someSlotsAvailable ? (
              <>
                {`Your participation to this event is ${getDisplayedStatus(invitation)}.`}
                <span className="ml-2 d-none d-md-inline">You can modify your registration at your convenience.</span>
              </>
            ) : (
              <span>There is no more slot available for booking. Please contact your event manager.</span>
            )}
          </p>
          <div className="d-flex flex-row-reverse">
            {someSlotsAvailable && (
              <ModifyRegistrationButton
                isOneSlotRegistration={isOneSlot}
                isUpdating={isUpdating}
                onModifyRegistrationOneSlot={modifyRegistrationOneSlot}
                onModifyRegistrationMultiSlot={modifyRegistrationMultiSlot}
                color={color}
              />
            )}
          </div>
        </Alert>
      </div>
    </div>
  );
};

import * as React from "react";

const Footer: React.FC = () => {
  const sgwtMiniFooter = (type: string): JSX.Element => (
    <sgwt-mini-footer
      id="ic-mini-footer"
      mode="sg-markets"
      type={type}
      design="light"
      container="container-fluid"
      contact-us="{'mail': 'sgmarkets@sgcib.com'}"
      contact-us-by-help-center="sgwt-help-center"
    />
  );
  return (
    <>
      <div className="d-lg-block d-none d-print-none">{sgwtMiniFooter("medium")}</div>
      <div className="d-lg-none d-print-none">{sgwtMiniFooter("micro")}</div>
    </>
  );
};

export default Footer;

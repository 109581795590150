import { atom } from "jotai";
import { UserInfo, UserProfileState } from "api/userProfile.types";

export const defaultUserProfile: UserInfo = {
  userId: null,
  isIcUser: false,
  userEmail: null,
  id: null,
};

const initialState: UserProfileState = {
  userInfo: defaultUserProfile,
  loggedIn: false,
  error: "",
};

export const userProfileStateAtom = atom<UserProfileState>(initialState);

export const userInfoAtom = atom((get): UserInfo => {
  const userInfo = get(userProfileStateAtom).userInfo;
  return {
    userId: userInfo.userId,
    isIcUser: !!userInfo.userId && userInfo.isIcUser,
    userEmail: userInfo.userEmail,
    id: userInfo.id,
  };
});

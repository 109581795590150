import { logMessage } from "utils/logger/logger";
import { FeatureData } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import * as React from "react";

export const featureTracking = (feature: FeatureData) => {
  logMessage({
    event: feature.event,
    description: feature.description,
    type: LogTypes.functional,
    feature: "event",
    name: feature.description,
  });
};

export const useTrackOnDisplayPage = (feature: FeatureData) => {
  React.useEffect(() => {
    featureTracking(feature);
  }, []);
};

import { endsWith, startsWith } from "lodash";

export interface AfpUrls {
  v2: string;
  default: string;
}

const ENV_URLS = {
  icProxified: {
    localOrHomolgation: "",
    iso: "",
    production: ""
  },
  ic: {
    localOrHomolgation: "https://ic-hom.fr.world.socgen",
    iso: "https://api-s-uat-tmp.sgmarkets.com/services/ic/crm/ui-private/v1",
    production: "https://api-s.sgmarkets.com/services/ic/crm/ui-private/v1"
  },
  api: {
    localOrHomolgation: {
      v2: "https://ic-api-hom.fr.world.socgen/2.0",
      default: "https://ic-api-hom.fr.world.socgen"
    },
    iso: {
      v2: "https://api-s-uat-tmp.sgmarkets.com/services/ic/crm/v2",
      default: "https://api-s-uat-tmp.sgmarkets.com/services/ic/crm/private/v1"
    },
    production: {
      v2: "https://api-s.sgmarkets.com/services/ic/crm/v2",
      default: "https://api-s.sgmarkets.com/services/ic/crm/private/v1"
    }
  }
};

export const isCrmServicesV2 = (relativeUrl: string) => startsWith(relativeUrl, "/2.0");

export const isIsoOrProdEnv = () =>
  window["globalConfiguration"].env === "iso" || window["globalConfiguration"].env === "production";

export const getApiServiceUrl = (url: string): string | AfpUrls => {
  const isMockFileCall = endsWith(url, ".json");
  if (isMockFileCall) {
    return "/";
  }

  const globalEnv = (window["globalConfiguration"] || { env: "homologation" }).env;
  return getUrlFromEnv(globalEnv, url);
};

const getUrlFromEnv = (globalEnv: string, relativeUrl: string): string | AfpUrls => {
  const envUrls =
    globalEnv !== "local" ? (startsWith(relativeUrl, "/api") ? ENV_URLS.ic : ENV_URLS.api) : ENV_URLS.icProxified;
  switch (globalEnv) {
    case "local":
    case "development":
    case "homologation":
      return envUrls.localOrHomolgation;
    case "iso":
      return envUrls.iso;
    case "production":
      return envUrls.production;
    default:
      throw Error("Cannot get current environment from configuration");
  }
};

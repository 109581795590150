import { getApmEnvUrlForRum, RumConfig } from "@ic-anywhere/ic-components";
import { getServiceUrlsByEnv, SERVICE_URLS } from "@ic-anywhere/ic-dal";
import { Environment } from "@ic-anywhere/ic-utils";

declare const APM_SERVICE_VERSION: string;

export const icEventsRumConfig = (env: Environment, isInLan = false): RumConfig => {
  return {
    serviceName: "ic-sgm-events",
    serviceVersion: APM_SERVICE_VERSION,
    distributedTracingOrigins: getServiceUrlsByEnv(env, true, isInLan, SERVICE_URLS),
    ...getApmEnvUrlForRum(env),
  };
};

import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const tokenAtom = atom(undefined as string);

export type NavigateAsState = {
  id: string;
  email: string;
  name: string;
};

export const navigateAsAtom = atomWithStorage<NavigateAsState | null>("navigateAsState", null);

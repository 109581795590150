import { useInfiniteQuery } from "@tanstack/react-query";
import useCrmRepository from "api/useCrmRepository";
import { getInvitations } from "api/events/event.api";
import { twoMinutesInMs } from "@ic-anywhere/ic-dal";
import { useEffect } from "react";

export const useGetInvitationsQuery = (eventId: string, statuses?: string) => {
  const repo = useCrmRepository();

  const response = useInfiniteQuery(
    [eventId, "invitation"],
    ({ pageParam = 0 }) => getInvitations(repo, eventId, { page: pageParam, statuses }),
    {
      getNextPageParam: (lastPage) => {
        const page = (lastPage?.page ?? 0) + 1;
        return page < lastPage?.totalPages ? page : null;
      },
      enabled: !!eventId,
      cacheTime: twoMinutesInMs,
    }
  );

  const data = response.data?.pages.map((page) => page._embedded.invitations ?? []);

  useEffect(() => {
    if (response.hasNextPage && !response.isFetching) {
      response.fetchNextPage();
    }
  }, [response]);

  return {
    ...response,
    data,
  };
};

import * as React from "react";
import { isEmpty } from "lodash";
import * as styles from "./ComEvent.scss";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import { REGISTER } from "containers/event/event.types";
import { ErrorPage } from "components/layout/ErrorPage/ErrorPage";
import { useParams } from "react-router";
import { useGetMyInvitationsQuery } from "queries/useGetMyInvitationsQuery";
import { usePerformanceMeasure } from "utils/logger/usePerformanceMeasure";
import { Loader } from "@sgbs-ui/core";

const BusinessEventsLoadingPerformanceKey = "BusinessEventsLoadingPerformanceKey";

const ComEvent: React.FC<void> = () => {
  usePerformanceMeasure(BusinessEventsLoadingPerformanceKey);

  const { id: eventId, action: urlAction } = useParams<{ id: string; action: string }>();

  const { data: eventInvitationInfo, isFetching } = useGetMyInvitationsQuery(eventId);

  if (isFetching) {
    return <Loader isVisible={true} />;
  }
  const errorNotInvitedAndNotEM = !eventInvitationInfo.id && !eventInvitationInfo.isEventManager;
  if (errorNotInvitedAndNotEM) {
    return (
      <ErrorPage
        shown={true}
        header="The event cannot be opened."
        message="Please consult your sales representative if you want to participate to this event."
      />
    );
  }

  const eventContentUrl = getWebsiteUrlByAction(eventInvitationInfo, urlAction);

  return (
    <div className={`d-flex flex-fill ${styles["full-size"]}`}>
      <iframe
        src={eventContentUrl}
        className={"d-flex flex-fill"}
        frameBorder="0"
        scrolling="yes"
        allowFullScreen={true}
        onLoad={
          eventInvitationInfo.webSiteUrl === eventContentUrl
            ? logWebSitePageBusinessEventsLoading
            : logRegistrationPageBusinessEventsLoading
        }
      />
    </div>
  );
};

const logRegistrationPageBusinessEventsLoading = () => {
  logMessage({
    ...features.registrationPageBusinessEventsLoading,
    type: LogTypes.functional,
    feature: "event",
    name: "Registration page for business events loading",
  });
};

const logWebSitePageBusinessEventsLoading = () => {
  logMessage({
    ...features.webSitePageBusinessEventsLoading,
    type: LogTypes.functional,
    feature: "event",
    name: "Website page for business events loading",
  });
};

const getWebsiteUrlByAction = (invitation, action) => {
  return action === REGISTER
    ? isEmpty(invitation.inviteeWebSiteUrl)
      ? invitation.webSiteUrl
      : invitation.inviteeWebSiteUrl
    : isEmpty(invitation.inviteeDeclineLink)
    ? invitation.webSiteUrl
    : invitation.inviteeDeclineLink;
};

export default ComEvent;

import * as React from "react";

export interface Props {
  shown: boolean;
  header?: string;
  message?: string;
  goBack?: boolean;
  match?: any;
}

const Page404: React.FunctionComponent<Props> = ({ shown = true }) => {
  const openHelpCenter = () => {
    const selector = "sgwt-help-center";
    const elt = document.querySelector(selector);
    if (elt) {
      (elt as any).form();
    }
  };

  const reload = () => {
    window.location.reload();
  };
  return shown ? (
    <body className="d-flex flex-column mt-5">
      <section className="section-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
                <span
                  className="mt-n3 d-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: "56px" }}
                >
                  404
                </span>
                <span className="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt font-weight-medium">
                  404
                </span>
                <span
                  className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: "96px", overflow: "hidden" }}
                >
                  404
                </span>
              </div>
              <div className="col-lg-5  bg-white d-flex flex-column justify-content-between">
                <div>
                  <h1 className="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3">
                    Page not found.
                  </h1>
                  <h1 className="d-lg-none display-3 font-weight-medium line-height-1 py-2">Page not found.</h1>

                  <p className="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">
                    Sorry, we couldn't find this page
                  </p>

                  <p className="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                    Sorry, we couldn't find this page.
                  </p>

                  <p className="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                    Sorry, we couldn't find this page.
                  </p>
                </div>
                <div className="mb-5 d-none d-md-flex">
                  <button className="btn btn-xl btn-primary" onClick={reload}>
                    Try again
                  </button>
                  <button className="btn btn-xl btn-flat-secondary ml-1" onClick={openHelpCenter}>
                    Contact us
                  </button>
                </div>
                <div className="d-block d-md-none">
                  <button className="btn btn-xl btn-primary btn-block mb-1" onClick={reload}>
                    Try again
                  </button>
                  <button className="btn btn-xl btn-flat-secondary ml-1 btn-block" onClick={openHelpCenter}>
                    Contact us
                  </button>
                </div>
              </div>
              <div className="col-4 px-0 position-relative">
                <div
                  className="d-none d-lg-block"
                  style={{
                    paddingTop: "100%",
                    background:
                      "#fff url(https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap-v4/master/demo/templates/error-pages/404.svg) center no-repeat",
                    backgroundSize: "130%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-3 flex-grow-1 d-flex">
        <div className="container-fluid">
          <div className="container h-100 d-none d-lg-block">
            <div className="row h-100">
              <div className="offset-2 col-5 pt-5 text-muted" style={{ backgroundColor: "#fafafa" }} />
            </div>
          </div>
          <footer
            className="container-fluid d-lg-none position-absolute text-muted"
            style={{ bottom: "0", left: "0", right: "0", backgroundColor: "#fafafa" }}
          >
            <div className="container py-4" />
          </footer>
        </div>
      </section>
    </body>
  ) : null;
};
export default Page404;

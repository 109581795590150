import * as React from "react";
import { MainLayout } from "components/layout/MainLayout/MainLayout";
import { SgwtSplashScreen, ToastContainer, useLoadJsScript, useRum } from "@ic-anywhere/ic-components";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "components/App/AppRoutes";
import { ErrorHandler } from "components/common/ErrorHandler/ErrorHandler";
import { SgwtWebAnalytics } from "components/common/SgwtWebAnalytics";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defaultQueryClientOptions } from "queries/constants";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Authentication } from "components/Authentication/Authentication";
import { HelpCenter } from "components/layout/HelpCenter/HelpCenter";
import { useAtomValue } from "jotai";
import { userProfileStateAtom } from "state/userProfileAtoms";
import { icEventsRumConfig } from "common/rumConfig";
import { isInLanAtom } from "state/isInLanAtom";
import { SERVICE_URLS } from "@ic-anywhere/ic-dal";

export const queryClient = new QueryClient({
  defaultOptions: defaultQueryClientOptions,
});

export const App: React.FC = () => {
  const siteId = window.PiwikConfiguration.piwikSiteId;
  const isDevMode = process.env.NODE_ENV === "development";
  useLoadJsScript("licences.js");

  const userProfile = useAtomValue(userProfileStateAtom);

  const isInLan = useAtomValue(isInLanAtom);

  useRum(icEventsRumConfig, userProfile?.userInfo, true, isInLan, SERVICE_URLS);

  return (
    <>
      <SgwtSplashScreen applicationName="sg-markets-events" debug={isDevMode} />
      <QueryClientProvider client={queryClient}>
        {isDevMode && <ReactQueryDevtools initialIsOpen={false} />}
        <ErrorHandler>
          <Authentication>
            <BrowserRouter>
              <HelpCenter>
                <React.StrictMode>
                  <MainLayout>
                    <AppRoutes />
                    <ToastContainer hideProgressBar={true} autoClose={5000} />
                    <SgwtWebAnalytics siteId={siteId} />
                  </MainLayout>
                </React.StrictMode>
              </HelpCenter>
            </BrowserRouter>
          </Authentication>
        </ErrorHandler>
      </QueryClientProvider>
    </>
  );
};

import * as React from "react";
import Header from "components/layout/Header/PageHeader/Header";
import { Event } from "common/event/event.types";
import { NavItem } from "components/common/NavItem";
import { generateUrl } from "utils/generateUrl";
import { ROUTES } from "common/routes";
import { NavPills } from "@ic-anywhere/ic-components";

type Props = {
  event: Event;
  showInvestorProfiles: boolean;
  showFeedbacks: boolean;
};

export const EventNavigation: React.VFC<Props> = ({ showInvestorProfiles, showFeedbacks, event }) => {
  const eventNavBar: JSX.Element = (
    <NavPills className="nav-header nav-compact-pills text-large mt-lg-3 row">
      <NavItem title="Roadshow" to={generateUrl(ROUTES.EVENT_DETAIL_CORPORATE, event.id)} className="pt-3" />
      <NavItem title="Contacts" to={generateUrl(ROUTES.EVENT_DETAIL_CONTACT, event.id)} className="pt-3" />
      <NavItem
        title="Invitation status"
        to={generateUrl(ROUTES.EVENT_DETAIL_INVITATION_STATUS, event.id)}
        className="pt-3"
      />
      {showInvestorProfiles && (
        <NavItem title="Investor profiles" to={generateUrl(ROUTES.EVENT_INVESTOR_PROFILE, event.id)} className="pt-3" />
      )}
      {showFeedbacks && <NavItem title="Feedbacks" to={generateUrl(ROUTES.EVENT_FEEDBACK, event.id)} />}
    </NavPills>
  );

  return <Header mainTitle={event.name} headerOptions={{ navBar: eventNavBar, event }} />;
};

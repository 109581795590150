import { useEffect, useReducer } from "react";
import { useDeclineEventInvitationMutation, useUpdateBookingsMutation } from "queries/invitationMutations";
import { useGetBookingDetailsQuery } from "queries/useGetBookingDetailsQuery";
import { initialState, invitationStateReducer } from "components/EventInvitation/useEventInvitations.reducer";
import {
  getOneSlotBookingModel,
  mapToUpdateModel,
  updateOneSlotBookingModel,
} from "components/EventInvitation/invitation.mapper";
import { BookingStatus } from "common/event/event.types";
import { useHistory } from "react-router";
import { buildUrl } from "utils/generateUrl";
import { ROUTES } from "common/routes";
import { BookingEditModel } from "components/EventInvitation/EditableSlotItem/EditableSlotItemBody";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "state/userProfileAtoms";
import { some } from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

export type InvitationActionType = "register" | "decline" | "declineAllEvent" | "modify" | undefined;

export const useEventInvitations = (eventId: string, urlAction: InvitationActionType) => {
  const history = useHistory();
  const userInfo = useAtomValue(userInfoAtom);
  const {
    data: { event, invitation, bookings: loadedBookings },
    isFetching,
    errorStatus: getBookingsErrorStatus,
  } = useGetBookingDetailsQuery(eventId, userInfo.userId);

  const { mutate: mutateDeclineEvent, isLoading: isDeclining } = useDeclineEventInvitationMutation(eventId);
  const { mutate: mutateUpdateBooking, isLoading: isUpdating } = useUpdateBookingsMutation(eventId);

  const [state, dispatch] = useReducer(invitationStateReducer, initialState());
  const { isOneSlot, currentModal, showModifyRegistration, bookings, urlRegistrationType, programView } = state;

  useEffect(() => {
    dispatch({
      type: "load_initial_state",
      payload: {
        initialBookings: [],
      },
    });
  }, []);

  useDeepCompareEffect(() => {
    if (invitation) {
      dispatch({
        type: "update_bookings",
        payload: {
          bookings: loadedBookings,
          invitation,
          urlAction,
        },
      });
    }
  }, [loadedBookings, invitation]);

  useEffect(() => {
    if (!urlRegistrationType) {
      return;
    }
    if (urlRegistrationType === "register") {
      registerInvitation();
    }
    if (urlRegistrationType === "decline") {
      if (isOneSlot) {
        declineBooking();
      } else {
        declineEvent();
      }
    }
    if (urlRegistrationType === "declineAllEvent") {
      declineEvent();
    }
    history.push(buildUrl(ROUTES.EVENT_INVITATION_ROADSHOW, { id: eventId }));
  }, [urlRegistrationType]);

  const registerInvitation = () => {
    if (isOneSlot) {
      modifyRegistrationOneSlot(BookingStatus.Booked);
    } else {
      dispatch({ type: "register_multi_slot" });
    }
  };

  const declineBooking = () => {
    modifyRegistrationOneSlot(BookingStatus.Declined);
  };

  const declineEvent = () => {
    mutateDeclineEvent(null, {
      onSuccess: () => {
        dispatch({ type: "decline_all_event" });
      },
    });
  };

  const modifyRegistrationOneSlot = (forcedStatus?: BookingStatus.Booked | BookingStatus.Declined) => {
    const newStatus =
      forcedStatus ??
      (!bookings[0].booking || bookings[0].booking.status === BookingStatus.Declined
        ? BookingStatus.Booked
        : BookingStatus.Declined);

    const onSuccess = () => {
      dispatch({
        type: newStatus === BookingStatus.Booked ? "confirm_mono_slot" : "decline_mono_slot",
      });
    };

    if (bookings[0].booking) {
      mutateUpdateBooking(
        updateOneSlotBookingModel(bookings[0].booking?.id, bookings[0].slot.id, userInfo.userId, newStatus),
        { onSuccess }
      );
    } else {
      mutateUpdateBooking(getOneSlotBookingModel(undefined, bookings[0].slot.id, userInfo.userId, newStatus), {
        onSuccess,
      });
    }
  };

  const modifyRegistrationMultiSlot = () => {
    dispatch({ type: "register_multi_slot" });
  };

  const saveMultiSlotEdition = (bookingsUpdated: BookingEditModel[], allDeclined: boolean) => {
    const onSuccess = () => {
      dispatch({ type: "close_registration_multiSlot", payload: allDeclined ? "declineAllEvent" : "register" });
    };

    mutateUpdateBooking(mapToUpdateModel(bookingsUpdated, userInfo.userId), {
      onSuccess,
    });
  };

  const cancelMultiSlotEdition = () => {
    dispatch({ type: "hide_registration_multiSlot" });
  };
  const isUserEventManager: boolean = some(event?.eventManagerContacts, ({ id }) => id === userInfo.userId);
  return {
    event,
    isFetching: isFetching,
    isUpdating: isDeclining || isUpdating,
    getBookingsErrorStatus,
    bookings,
    invitation,
    isOneSlot,
    currentModal,
    showModifyRegistration,
    programView,
    isUserEventManager,
    modifyRegistrationOneSlot,
    modifyRegistrationMultiSlot,
    saveMultiSlotEdition,
    cancelMultiSlotEdition,
    closeModal: () => dispatch({ type: "close_modal" }),
  };
};

import * as React from "react";
import { Link } from "react-router-dom";
import { noop } from "lodash";
import { SGBSColor, SGBSSize } from "@sgbs-ui/core";

export interface Props {
  to: string;
  prepend?: boolean;
  logAction?: () => void;
  label?: string;
  className?: string;
  icon?: string;
  size?: SGBSSize;
  color?: SGBSColor;
}

const ButtonLink: React.FC<React.PropsWithChildren<Props>> = ({
  to,
  className,
  label,
  prepend,
  children,
  logAction,
  icon,
}: React.PropsWithChildren<Props>) => {
  return (
    <Link className={className ? className : "btn btn-lg btn-primary"} to={to} onClick={logAction || noop}>
      {prepend ? children : null}
      {icon && <em className="icon mr-1">{icon}</em>}
      {label}
      {!prepend ? children : null}
    </Link>
  );
};

export default ButtonLink;

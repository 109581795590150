import { Environment as LoggerEnvironment } from "@dcc-cli/cli-web-logger";
import { Environment, getEnvironment } from "@ic-anywhere/ic-utils";

export const getLoggerEnvironment = (): LoggerEnvironment => mapEnvToIcLoggerEnv(getEnvironment());

const mapEnvToIcLoggerEnv = (env: Environment): LoggerEnvironment => {
  switch (env) {
    case "homologation":
      return "HOM";
    case "iso":
      return "ISO";
    case "production":
      return "PRD";
    default:
      return "HOM";
  }
};

import * as React from "react";
import * as ReactDOM from "react-dom";
import "styles/main.scss";
import { App } from "components/App/App";

const sgConnectWidget = document.body.querySelector("sgwt-connect") as any;
if (sgConnectWidget === null) {
  console.error("The widget sgwt-connect was not found on the page");
} else if (sgConnectWidget.sgwtConnect) {
  login();
} else {
  sgConnectWidget.addEventListener("sgwt-connect--ready", login);
}

function login(): void {
  const sgwtConnect = sgConnectWidget.sgwtConnect;
  sgConnectWidget.removeEventListener("sgwt-connect--ready", login);
  if (sgwtConnect.getAuthorizationError()) {
    console.error({
      source: "authorization",
      error: `Authorization failed with error ${sgwtConnect.getAuthorizationError()}`,
    });
  } else if (sgwtConnect.isAuthorized()) {
    ReactDOM.render(<App />, document.getElementById("root"));
  } else {
    sgwtConnect.requestAuthorization();
  }
}

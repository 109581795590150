import * as React from "react";
import { isEmpty } from "lodash";
import { hasInvestorProfiles } from "api/events/event.mapper";
import { Redirect, useParams } from "react-router";
import { EventNavigation } from "components/Event/Corporate/EventNavigation";
import { useGetEventDetailQuery } from "queries/useGetEventDetailQuery";
import { EventCorporateRoadshowTab } from "components/Event/Corporate/Tabs/EventCorporateRoadshowTab";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "common/routes";
import { EventCorporateContactsTab } from "components/Event/Corporate/Tabs/EventCorporateContactsTab";
import { EventCorporateInvestorProfilesTab } from "components/Event/Corporate/Tabs/EventCorporateInvestorProfilesTab";
import { EventCorporateFeedbacksTab } from "components/Event/Corporate/Tabs/EventCorporateFeedbacksTab";
import { useEventFeedbacksQuery } from "queries/useEventFeedbacksQuery";
import { EventCorporateInvitationStatusTab } from "./Tabs/EventCorporateInvitationStatusTab";
import { useCheckEventAccess } from "components/Event/useCheckEventAccess";
import { Event } from "common/event/event.types";
import { Loader } from "@sgbs-ui/core";
import { useTrackOnDisplayPage } from "components/common/event.tracking";
import { features } from "utils/logger/features";

export const EventDetailCorporate: React.VFC = () => {
  const { id: eventId } = useParams<{ id: string }>();
  const accessCheck = useCheckEventAccess(eventId);
  useTrackOnDisplayPage(features.eventDetailsRoadshowDisplay);
  const { data: eventDetail, isLoading } = useGetEventDetailQuery(eventId, accessCheck === "access");
  const { data: feedbacks } = useEventFeedbacksQuery(eventId);

  if (accessCheck === "not_access") {
    return <Redirect to={ROUTES.EVENTS_DASHBOARD} />;
  }

  if (accessCheck === "pending" || !eventDetail || isLoading) {
    return <Loader isVisible={true} />;
  }

  const eventName = eventDetail.event.name;
  const eventInfo: Event = {
    startDate: eventDetail.event.startDate,
    endDate: eventDetail.event.endDate,
    id: eventId,
    name: eventName,
    venue: eventDetail.event.venue,
  };
  return (
    <>
      <EventNavigation
        event={eventInfo}
        showInvestorProfiles={hasInvestorProfiles(eventDetail.event)}
        showFeedbacks={!isEmpty(feedbacks)}
      />
      <div className="container mt-4">
        <Switch>
          <Route exact={true} path={ROUTES.EVENT_DETAIL_CORPORATE}>
            <EventCorporateRoadshowTab eventDetail={eventDetail} />
          </Route>
          <Route exact={true} path={ROUTES.EVENT_DETAIL_CONTACT}>
            <EventCorporateContactsTab eventDetail={eventDetail} />
          </Route>
          <Route exact={true} path={ROUTES.EVENT_DETAIL_INVITATION_STATUS}>
            <EventCorporateInvitationStatusTab eventDetail={eventDetail} />
          </Route>
          <Route exact={true} path={ROUTES.EVENT_INVESTOR_PROFILE}>
            <EventCorporateInvestorProfilesTab eventDetail={eventDetail} />
          </Route>
          <Route exact={true} path={ROUTES.EVENT_FEEDBACK}>
            <EventCorporateFeedbacksTab eventDetail={eventDetail} />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default EventDetailCorporate;

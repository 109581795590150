import * as React from "react";
import { EventDetail } from "containers/event/event.types";
import { chain, isEmpty, zipObject } from "lodash";
import { CardUsefulContacts } from "components/EventInvitation/CardItem/CardUsefulContacts";
import { EventDocumentsList } from "components/Event/Corporate/Tabs/CardItem/EventDocumentsList";
import { IssuerItem } from "components/Event/Corporate/Tabs/CardItem/IssuerItem";
import SlotsDetailList from "components/Event/Corporate/Tabs/SlotsDetail/SlotsDetailList";
import EventExtractProgram from "components/Event/Corporate/EventExtractProgram/EventExtractProgram";
import { EventContact } from "api/events/event.types";
import { Card } from "components/common/Card";
import { Button } from "@sgbs-ui/core";
import { features } from "utils/logger/features";
import { featureTracking } from "../../../common/event.tracking";

export const issuersGroupByAccountName = (issuers: EventContact[]) => {
  return chain(issuers)
    .sortBy("accountName")
    .groupBy("accountName")
    .toPairs()
    .map((issuer) => {
      return zipObject(["accountName", "issuers"], issuer);
    })
    .value();
};

export const EventCorporateRoadshowTab: React.VFC<{ eventDetail: EventDetail }> = ({ eventDetail }) => {
  const travelDocuments = eventDetail.event.resources.filter((r) => r.category === "TravelDocuments");
  const businessContacts = eventDetail.useFulContacts?.businessContacts ?? [];
  const drivers = eventDetail.useFulContacts?.yourDriver ?? [];
  const eventManagers = eventDetail.useFulContacts?.eventManagerContacts ?? [];
  const internalSpeakers = eventDetail.useFulContacts?.internalSpeakers ?? [];

  const handleOnClickExtractProgram = () => {
    featureTracking(features.eventDetailsRoadshowProgramExport);
    print();
  };
  return (
    <>
      <div className="roadshow-container">
        <div className="d-lg-flex d-none d-print-none ml-auto flex-lg-row-reverse mb-4">
          <Button text="Extract Program" color="primary" onClick={handleOnClickExtractProgram} />
        </div>
        <div className="d-flex col justify-content-end d-lg-none d-print-none mb-4">
          <Button color="primary" onClick={handleOnClickExtractProgram} icon="print" />
        </div>
        <div className="row w-100 mx-0">
          <div className="col-md-8 pl-md-0">
            <Card className="bg-lvl2">
              <SlotsDetailList slots={eventDetail.slots} eventVenue={eventDetail.event.venue} />
            </Card>
          </div>
          <div className="col-md-4 pr-md-0">
            {!isEmpty(eventDetail.issuers) && (
              <Card className="bg-lvl2 mb-4">
                <IssuerItem issuers={issuersGroupByAccountName(eventDetail.issuers)} />
              </Card>
            )}
            <CardUsefulContacts
              classNames="bg-lvl2 mb-4"
              eventManagers={eventManagers}
              internalSpeakers={internalSpeakers}
              businessContacts={businessContacts}
              drivers={drivers}
            />
            {!isEmpty(travelDocuments) && (
              <Card className="bg-lvl2" title="Travel documents">
                <EventDocumentsList documents={travelDocuments} />
              </Card>
            )}
          </div>
        </div>
      </div>
      <EventExtractProgram eventDetail={eventDetail} />
    </>
  );
};

import { SGWTConnectCore } from "@sgwt/connect-core";

export type SGWTTopic = "sg-connect.access-token" | "sg-connect.user-connection" | "sg-connect.user-info";

export interface SGWTEventBus {
  publish(topic: SGWTTopic, value: any): void;
  subscribe(topic: SGWTTopic, listener: (value: any) => void): void;
  unsubscribe(topic: SGWTTopic, listener: (value: any) => void): void;
}

export interface SGWTWidgetConfiguration {
  bus?: SGWTEventBus;
  environment: string;
  environmentHack: string;
  sharedCssUrls: string[];
}

export const getWidgetBus = (): SGWTEventBus | undefined => window.SGWTWidgetConfiguration?.bus;

export const sgwtConnectComponent = (): SGWTConnectCore => {
  const sgwtConnectElement = document.body.querySelector("sgwt-connect") as any;
  return sgwtConnectElement.sgwtConnect;
};

import * as React from "react";

export const AuthenticationErrorPage: React.VFC = () => {
  return (
    <div className="flex-center flex-column mt-5 mx-auto w-50">
      <div className="bg-primary-alpha-md flex-center mb-3" style={{ height: "48px", width: "48px", borderRadius: 24 }}>
        <i className="icon icon-lg line-height-1">info_outline</i>
      </div>
      <h1 className="display-3">Authentication error</h1>
      <p className="lead">Please contact the event organiser.</p>
    </div>
  );
};

import { noop } from "lodash";
import * as React from "react";
import { startPerformanceMeasure } from "utils/logger/logger";

export const usePerformanceMeasure = (performanceKey: string, loadingAction: () => void = noop): void => {
  React.useEffect(() => {
    startPerformanceMeasure(performanceKey);
    loadingAction();
  }, []);
};

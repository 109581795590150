import { LogMessage } from "./logger.typings";

interface SgwtAnalyticsWidget extends Element {
  trackEvent: (category: string, action: string | undefined, name?: string, value?: string) => void;
  trackSearch: (keyword: string, category: string, count: number) => void;
  trackLink: (href: string, linkType: string) => void;
  trackPageView: (href?: string, title?: string) => void;
}

let sgwtAnalyticsWidget: SgwtAnalyticsWidget | null;

export const trackAnalyticsEvent = ({ feature, event }: LogMessage): void => {
  if (!sgwtAnalyticsWidget) {
    sgwtAnalyticsWidget = document.querySelector<SgwtAnalyticsWidget>("sgwt-web-analytics");
  }

  if (sgwtAnalyticsWidget?.trackEvent) {
    sgwtAnalyticsWidget.trackEvent(feature, event);
  }
};

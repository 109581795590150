import { useGetMyEventsQuery } from "queries/useGetMyEventsQuery";
import { useAtom } from "jotai";
import { activeFiltersAtom } from "components/Event/Dashboard/utils/filtersAtom";
import {
  eventItemsGroupByGroupName,
  getEventsFilteredByTagFilters,
} from "components/Event/Dashboard/utils/events.utils";
import { EventItem } from "common/event/event.types";

export const useEventsDashboard = (): { events: EventItem[]; isFetching: boolean } => {
  const { data, isFetching } = useGetMyEventsQuery();
  const [filters] = useAtom(activeFiltersAtom);

  const mappedEvents = eventItemsGroupByGroupName(data);
  const events = getEventsFilteredByTagFilters(mappedEvents, filters);

  return {
    events,
    isFetching,
  };
};

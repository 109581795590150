import { useQueries } from "@tanstack/react-query";
import { EventDetail } from "containers/event/event.types";
import useCrmRepository from "api/useCrmRepository";
import { getEventById, getEventSlotsById } from "api/events/event.api";
import { ApiRepository, twoMinutesInMs } from "@ic-anywhere/ic-dal";
import { mapToEventDetail, normalizeEventDtoToEvent, normalizeSlotDtoToSlot } from "api/events/event.mapper";
import { every, map, some } from "lodash";
import { MergedQueryResult } from "queries/queries.typings";

export const eventQueryKey = (eventId: string) => [eventId, "getEventById"];
export const slotsQueryKey = (eventId: string) => [eventId, "getEventSlotsById"];

export const getEventByIdQuery = (repo: ApiRepository, eventId: string, enabled = true) => ({
  queryKey: eventQueryKey(eventId),
  queryFn: () => getEventById(repo, eventId),
  enabled: enabled && !!eventId,
  cacheTime: twoMinutesInMs,
});

export const getEventSlotsByIdQuery = (repo: ApiRepository, eventId: string, enabled = true) => ({
  queryKey: slotsQueryKey(eventId),
  queryFn: () => getEventSlotsById(repo, eventId),
  enabled: enabled && !!eventId,
  cacheTime: twoMinutesInMs,
});

export const useGetEventDetailQuery = (eventId: string, enabled = true): MergedQueryResult<EventDetail> => {
  const repo = useCrmRepository();

  const queries = useQueries({
    queries: [getEventByIdQuery(repo, eventId, enabled), getEventSlotsByIdQuery(repo, eventId, enabled)],
  });

  const isError = some(queries, (q) => q.isError);
  const isFetching = some(queries, (q) => q.isFetching);
  const isLoading = some(queries, (q) => q.isLoading);
  const isSuccess = every(queries, (q) => q.isSuccess);

  const [eventsResult, slotsResult] = queries;

  const { data: eventDto } = eventsResult;
  const { data: slotsDto } = slotsResult;

  const event = isSuccess ? normalizeEventDtoToEvent(eventDto) : undefined;
  const slots = isSuccess ? map(slotsDto?.slots, (dto) => normalizeSlotDtoToSlot(dto)) : [];

  const data = isSuccess ? mapToEventDetail(event, slots) : undefined;

  return { data, isFetching, isLoading, isSuccess, isError };
};

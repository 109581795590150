export interface FeatureData {
  event: string;
  description: string;
}

const welcomePageFeatures: { [key: string]: FeatureData } = {
  welcomePageDisplay: {
    event: "welcomePage.display",
    description: "Welcome page display",
  },
  welcomePageCorporateAccessFilterActivate: {
    event: "welcomePage.corporateAccessFilter.activate",
    description: "Welcome page corporate access filter activated",
  },
  welcomePageCorporateAccessFilterRemove: {
    event: "welcomePage.corporateAccessFilter.remove",
    description: "Welcome page corporate access filter removed",
  },
  welcomePageCreditRoadshowFilterActivate: {
    event: "welcomePage.creditRoadshowFilter.activate",
    description: "Welcome page credit roadshow filter activated",
  },
  welcomePageCreditRoadshowFilterRemove: {
    event: "welcomePage.creditRoadshowFilter.remove",
    description: "Welcome page credit roadshow filter removed",
  },
  welcomePageResearchFilterActivate: {
    event: "welcomePage.researchFilter.activate",
    description: "Welcome page research filter activated",
  },
  welcomePageResearchFilterRemove: {
    event: "welcomePage.researchFilter.remove",
    description: "Welcome page research filter removed",
  },
  welcomePageBusinessFilterActivate: {
    event: "welcomePage.businessFilter.activate",
    description: "Welcome page business filter activated",
  },
  welcomePageBusinessFilterRemove: {
    event: "welcomePage.businessFilter.remove",
    description: "Welcome page business filter removed",
  },
  welcomePagePendingFilterActivate: {
    event: "welcomePage.pendingFilter.activate",
    description: "Welcome page pending filter activated",
  },
  welcomePagePendingFilterRemove: {
    event: "welcomePage.pendingFilter.remove",
    description: "Welcome page pending filter removed",
  },
  welcomePageAttendingFilterActivate: {
    event: "welcomePage.attendingFilter.activate",
    description: "Welcome page attending filter activated",
  },
  welcomePageAttendingFilterRemove: {
    event: "welcomePage.attendingFilter.remove",
    description: "Welcome page attending filter removed",
  },
  welcomePageDeclinedFilterActivate: {
    event: "welcomePage.declinedFilter.activate",
    description: "Welcome page declined filter activated",
  },
  welcomePageDeclinedFilterRemove: {
    event: "welcomePage.declinedFilter.remove",
    description: "Welcome page declined filter removed",
  },
  welcomePageIssuedFilterActivate: {
    event: "welcomePage.issuedFilter.activate",
    description: "Welcome page issued filter activated",
  },
  welcomePageIssuedFilterRemove: {
    event: "welcomePage.issuedFilter.remove",
    description: "Welcome page issued filter removed",
  },
  welcomePageClearButtonReset: {
    event: "welcomePage.clearButton.reset",
    description: "Welcome page clear button reset",
  },
};
const eventDetailsFeatures: { [key: string]: FeatureData } = {
  eventDetailsRoadshowDisplay: {
    event: " eventDetails.roadshow.display",
    description: "Display event details of roadshow",
  },
  eventDetailsRoadshowProgramExport: {
    event: "eventDetails.roadshow.program.export",
    description: "In event details of roadshow click extract program",
  },
  eventDetailsContactsDisplay: {
    event: "eventDetails.contacts.display",
    description: "Display contact page for an event",
  },
  eventDetailsInvitationStatusDisplay: {
    event: "eventDetails.invitationStatus.display",
    description: "Display invitation status page for an event",
  },
  eventDetailsInvestorProfilesDisplay: {
    event: "eventDetails.investorProfiles.display",
    description: "Display investor profiles page for an event",
  },
  eventDetailsFeedbackDisplay: {
    event: "eventDetails.feedback.display",
    description: "Display feedback page for an event",
  },
  eventDetailsFeedbackExport: {
    event: "eventDetails.feedback.export",
    description: "Click on export feedback of events",
  },
};

const corporateWebsiteFeatures: { [key: string]: FeatureData } = {
  roadshowPageLoading: {
    event: "roadshowPage.loading",
    description: "Roadshow page loading",
  },
  roadshowPageAttendeesListExtend: {
    event: "roadshowPage.attendeesList.extend",
    description: "Roadshow page attendees list extend",
  },
  roadshowPageAttendeesListCollapse: {
    event: "roadshowPage.attendeesList.collapse",
    description: "Roadshow page attendees list collapse",
  },
  roadshowPageGoogleMapOpen: {
    event: "roadshowPage.googleMap.open",
    description: "Roadshow page googleMap open",
  },
  contactPageLoading: {
    event: "contactPage.loading",
    description: "Contact page loading",
  },
  contactPageRoadshowParticipantsSectionExtend: {
    event: "contactPage.roadshowParticipantsSection.extend",
    description: "Contact page roadshow Participants Section extend",
  },
  contactPageRoadshowParticipantsSectionCollapse: {
    event: "contactPage.roadshowParticipantsSection.collapse",
    description: "Contact page roadshow Participants Section collapse",
  },
  contactPageRoadshowBanksSectionExtend: {
    event: "contactPage.roadshowBanksSection.extend",
    description: "Contact page roadshow Banks Section extend",
  },
  contactPageRoadshowBanksSectionCollapse: {
    event: "contactPage.roadshowBanksSection.collapse",
    description: "Contact page roadshow Banks Section collapse",
  },
  contactPageRoadshowInvestorsSectionExtend: {
    event: "contactPage.roadshowInvestorsSection.extend",
    description: "Contact page roadshow Investors Section extend",
  },
  contactPageRoadshowInvestorsSectionCollapse: {
    event: "contactPage.roadshowInvestorsSection.collapse",
    description: "Contact page roadshow Investors Section collapse",
  },
  investorProfilesPageResourceVisualize: {
    event: "investorProfilesPage.resource.visualize",
    description: "Investor profile page resource visualize",
  },
  investorProfilesPageResourceExport: {
    event: "investorProfilesPage.resource.export",
    description: "Investor profile page resource export",
  },
  feedbackPageResourceVisualize: {
    event: "feedbackPage.resource.visualize",
    description: "Feedback page resource visualize",
  },
  feedbackPageResourceExport: {
    event: "feedbackPage.resource.export",
    description: "Feedback page resource print",
  },
  roadshowGroupPageLoading: {
    event: "roadshowGroupPage.loading",
    description: "Roadshow group page loading",
  },
  roadshowGroupPageMoreInfoNavigate: {
    event: "roadshowGroupPage.moreInfo.navigate",
    description: "Roadshow group page more info navigate",
  },
};

const investorWebsiteFeatures: { [key: string]: FeatureData } = {
  registrationPageBusinessEventsLoading: {
    event: "registrationPage.businessEvents.loading",
    description: "Registration page log for business events loading",
  },
  webSitePageBusinessEventsLoading: {
    event: "webSitePage.businessEvents.loading",
    description: "Website page for log business events loading",
  },
};

const registrationPageFeatures: { [key: string]: FeatureData } = {
  registrationPageDisplay: {
    event: "registrationPage.display",
    description: "Display registration page",
  },
  registrationPageClickOnModifyRegistration: {
    event: "registrationPage.modifyRegistration",
    description: "Registration page for log click on modify registration",
  },
  registrationPageClickOnCancelFromModal: {
    event: "registrationPage.roadshowModal.cancel",
    description: "Registration page for log click on cancel from modal",
  },
  registrationPageClicOnMyProgramSave: {
    event: "registrationPage.MyProgram.save",
    description: "Registration page for log click on save from my program",
  },
  registrationPageClicOnMyProgramCancel: {
    event: "registrationPage.roadshowMyProgram.cancel",
    description: "Registration page for log click on cancel from my program",
  },
};

export const features: { [key: string]: FeatureData } = {
  ...welcomePageFeatures,
  ...corporateWebsiteFeatures,
  ...investorWebsiteFeatures,
  ...registrationPageFeatures,
  ...eventDetailsFeatures,
};

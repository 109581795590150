import * as React from "react";
import { Slot } from "containers/event/event.types";
import { VenueInfo } from "common/event/event.types";
import {
  getReorderedSlotsByDateWithTimeZone,
  isOneDateForAllSlot,
} from "components/Event/Corporate/Tabs/SlotsDetail/SlotsDetailBody.utils";
import { isEmpty, map } from "lodash";
import { mapDateWithTimeZone } from "@ic-anywhere/ic-utils";
import { SlotsDetailBody } from "./SlotsDetailBody";

interface SlotProps {
  slots: Slot[];
  eventVenue?: VenueInfo;
}

const SlotsDetailList: React.FunctionComponent<SlotProps> = ({ slots, eventVenue }) => {
  const isOnlyOneDate = isOneDateForAllSlot(slots);
  const reorderSlotsGroupByDate = getReorderedSlotsByDateWithTimeZone(slots);

  const displaySlotDetail = (slots: Slot[], eventVenue?: VenueInfo): JSX.Element => (
    <>
      {map(slots, (slot, index) => (
        <ul className="list-unstyled mb-5" key={index}>
          <SlotsDetailBody slot={{ ...slot, eventVenue }} position={`${index}`} />
        </ul>
      ))}
    </>
  );
  return (
    <div className="row">
      <div>
        <>
          {!isEmpty(reorderSlotsGroupByDate) && (
            <>
              {isOnlyOneDate ? (
                <div className="px-3">{displaySlotDetail(reorderSlotsGroupByDate[0].slots, eventVenue)}</div>
              ) : (
                <ul className="sgbs-timeline sgbs-timeline-vertical align-items-start px-lg-3">
                  {map(reorderSlotsGroupByDate, ({ slots, date }, index) => (
                    <li className="sgbs-timeline-item w-100 col-12 active" key={index}>
                      <span className="sgbs-timeline-day">{mapDateWithTimeZone(date, "yyyy")}</span>
                      <span className="sgbs-timeline-date display-4">{mapDateWithTimeZone(date, "dd MMM")}</span>
                      {displaySlotDetail(slots, eventVenue)}
                    </li>
                  ))}
                  <li className="mr-2 d-inline-block w-100" />
                </ul>
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default SlotsDetailList;

import * as React from "react";
import { isEmpty, map, uniqueId } from "lodash";
import { Slot } from "containers/event/event.types";
import { EventContact, showCommentForSlotTypes } from "api/events/event.types";
import {
  getReorderedSlotsByDateWithTimeZone,
  isOneDateForAllSlot,
  renderInfoOfSlotInProgram,
} from "../Tabs/SlotsDetail/SlotsDetailBody.utils";

interface SlotProps {
  slots: Slot[];
  issuers: EventContact[];
}

export const SlotIssuersTimeline: React.FunctionComponent<SlotProps> = ({ slots, issuers }) => {
  const reorderSlotsGroupByDate = getReorderedSlotsByDateWithTimeZone(slots);
  const isOnlyOneDate = isOneDateForAllSlot(slots);

  const displaySlotDetail = (slots: Slot[]): JSX.Element => (
    <>
      {map(slots, (slot) => (
        <React.Fragment key={slot.id}>
          {renderInfoOfSlotInProgram(slot)}
          {slot.comment && showCommentForSlotTypes(slot.type) ? (
            <li key={slot.comment + uniqueId()}>{slot.comment}</li>
          ) : null}
        </React.Fragment>
      ))}
    </>
  );

  const displayIssuers = (issuers: EventContact[]): JSX.Element => (
    <>
      {map(issuers, (issuer) => (
        <li key={uniqueId()}>
          <ul className="list-unstyled">
            <li>{issuer.friendlyName}</li>
          </ul>
        </li>
      ))}
    </>
  );

  return (
    <div className="row">
      <div className="pt-4 pl-3">
        {isOnlyOneDate ? (
          <ul className="list-unstyled">
            <React.Fragment>
              <li>
                <div className="mr-2 d-inline-block">
                  <ul className="list-unstyled">
                    <li className="font-weight-bold">Program</li>
                    {displaySlotDetail(reorderSlotsGroupByDate[0].slots)}
                  </ul>
                </div>
                <div className="pl-4 d-inline-block align-top">
                  <ul className="list-unstyled">
                    {!isEmpty(issuers) && (
                      <>
                        <li className="font-weight-bold mb-2">Company speakers</li>
                        {displayIssuers(issuers)}
                      </>
                    )}
                  </ul>
                </div>
              </li>
            </React.Fragment>
          </ul>
        ) : (
          <ul className={"sgbs-timeline sgbs-timeline-vertical align-items-start"}>
            {map(reorderSlotsGroupByDate, ({ slots }, index) => (
              <li className={"w-100 col-12"} key={index}>
                <div className="mr-2 d-inline-block">
                  <ul className="list-unstyled">
                    <li className="font-weight-bold">Program</li>
                    {displaySlotDetail(slots)}
                  </ul>
                </div>
                <div className="pl-md-5 d-inline-block mt-md-5 pt-3 align-top">
                  <ul className="list-unstyled">
                    {!isEmpty(issuers) && (
                      <>
                        <li style={{ height: "4px" }} />
                        <li className="font-weight-bold mb-2">Company speakers</li>
                        {displayIssuers(issuers)}
                      </>
                    )}
                  </ul>
                </div>
              </li>
            ))}
            <li className="d-inline-block w-100" />
          </ul>
        )}
      </div>
    </div>
  );
};

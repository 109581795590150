import { EventFeedbackDTO, EventInviteeFeedback } from "api/events/event.types";
import { Slot } from "containers/event/event.types";
import { find, flatten, map, uniqBy } from "lodash";
import { useEventFeedbacksQuery } from "queries/useEventFeedbacksQuery";

export const useEventFeedbacks = (
  eventId: string,
  slots: Slot[]
): { feedbacks: EventInviteeFeedback[]; isFetching: boolean } => {
  const { data: feedbacks, isFetching } = useEventFeedbacksQuery(eventId);

  const invitees = getAllInviteesInSlots(slots);
  const feedbackByInvitees = mapFeedbacksToInvitees(feedbacks, invitees);

  return { feedbacks: feedbackByInvitees, isFetching };
};

const mapFeedbacksToInvitees = (
  feedbacks: EventFeedbackDTO[],
  invitees: Omit<EventInviteeFeedback, "feedback">[]
): EventInviteeFeedback[] => {
  return map(invitees, (invitee) => ({
    ...invitee,
    feedback: find(feedbacks, (feedback) => feedback?.invitee?.id === invitee?.id),
  }));
};

const getAllInviteesInSlots = (slots: Slot[]) => {
  const bookings = flatten(map(slots, ({ bookings, type }) => map(bookings, (b) => ({ ...b, slotType: type }))));
  return uniqBy(
    map(bookings, ({ invitee, slotType }) => ({ id: invitee.id, friendlyName: invitee.friendlyName, slotType })),
    "id"
  );
};

import { BaseEventInvitationStatus, EventContact, EventInvitationDto } from "api/events/event.types";

const eventStatus = [
  {
    id: BaseEventInvitationStatus.PENDING,
    name: "Pending",
  },
  {
    id: BaseEventInvitationStatus.DECLINED_NOT_AVAILABLE,
    name: "Refused- Not available",
  },
  {
    id: BaseEventInvitationStatus.DECLINED_NOT_INTERESTED,
    name: "Refused - Not interested",
  },
];

export function groupInvitationByStatus(
  data: EventInvitationDto[]
): { invitees: EventContact[]; id: BaseEventInvitationStatus; name: string }[] {
  const eventInvitationByStatus = eventStatus.map((status) => {
    return {
      ...status,
      invitees: data.filter((invitee) => invitee.status === status.id).map(({ invitee }) => invitee),
    };
  });

  const eventInvitationNoShow = {
    id: BaseEventInvitationStatus.NO_SHOW,
    name: "No show",
    invitees: data.filter((invitee) => invitee.noShow).map(({ invitee }) => invitee),
  };

  return [...eventInvitationByStatus, eventInvitationNoShow];
}

import * as React from "react";
import { Address, BookingStatus, BookingWriteModel } from "common/event/event.types";
import { get, identity, includes, isEmpty, map } from "lodash";
import { isAddressComplete } from "utils/address/addressUtil";
import { groupSlots } from "components/EventInvitation/useEventInvitations.rules";
import { SlotAddressDetails } from "./SlotAddressDetails";
import { BookingDetail } from "api/events/event.types";
import { Card, GoogleStaticMap } from "@ic-anywhere/ic-components";

interface Props {
  eventAddress: Address;
  bookingDetails: BookingDetail[];
}

const isBookedOrAttending = (booking: BookingWriteModel) =>
  booking == null || (booking && booking.status === BookingStatus.Booked);

const filterCompleteAddressBooking = (bookingDetails: BookingDetail[]) =>
  bookingDetails
    .filter(({ booking, slot }) => isBookedOrAttending(booking) && isAddressComplete(slot.address))
    .filter((bookingDetail) =>
      includes(
        [BookingStatus.Booked, BookingStatus.Pending],
        get(bookingDetail, "booking.status", BookingStatus.Pending)
      )
    );

export const CardMap: React.FunctionComponent<Props> = ({ eventAddress, bookingDetails }) => {
  const completeAddressBookings = filterCompleteAddressBooking(bookingDetails);
  if (completeAddressBookings.length === 0) {
    return null;
  }
  const addressesToBeShowInMap = map(completeAddressBookings, (bookingDetail) => get(bookingDetail, "slot.address"));
  const groupedSlots = groupSlots<BookingDetail>(completeAddressBookings, identity);

  return (
    <Card title="Map">
      <SlotAddressDetails groupedSlots={groupedSlots} />
      {!isEmpty(eventAddress) ? (
        <GoogleStaticMap
          containerClass="d-flex flex-column"
          address={addressesToBeShowInMap[0]}
          width={352}
          height={220}
        />
      ) : null}
    </Card>
  );
};

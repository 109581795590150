import * as React from "react";
import { isEmpty } from "lodash";
import { EventContact } from "api/events/event.types";
import { AvatarInformations } from "components/EventInvitation/CardItem/AvatarInformations";
import { Card } from "@ic-anywhere/ic-components";

interface Props {
  title: string;
  speakers: EventContact[];
}

export const CardSpeakers: React.FunctionComponent<Props> = ({ title, speakers }) => (
  <>
    {!isEmpty(speakers) ? (
      <Card title={title}>
        <ul className="list-unstyled">
          <AvatarInformations contacts={speakers} />
        </ul>
      </Card>
    ) : null}
  </>
);

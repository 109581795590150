import { VirtualLocation, Address } from "common/event/event.types";
import { isEmpty } from "lodash";
import * as React from "react";

const getLocationType = (isAdress: boolean, isVirtual: boolean): string => {
  if (isAdress && isVirtual) {
    return "In person and online";
  }
  if (isVirtual) {
    return "Online";
  }
  if (isAdress) {
    return "In person";
  }
  return "";
};

export const SlotLocationType: React.FC<{
  isSlotFull: boolean;
  address?: Address;
  virtualLocation?: VirtualLocation;
}> = ({ isSlotFull, address, virtualLocation }) => {
  const isVirtual =
    !isEmpty(virtualLocation?.url) || !isEmpty(virtualLocation?.phone) || !isEmpty(virtualLocation?.phoneCode);
  const locationType = getLocationType(!!address, isVirtual);
  return (
    <span className="font-weight-bold">
      {!isEmpty(locationType) && (
        <>
          <span className="d-none d-lg-inline pl-1">| </span>
          {locationType}
        </>
      )}
      {isSlotFull && " (Complete session)"}
    </span>
  );
};

import { Address } from "common/event/event.types";
import { values, get, isEmpty } from "lodash";

export const COMMA_AND_SPACE = ", ";
export const SPACE = " ";

const getPartOneOfAddressFormatted = (address: Address): string => {
  const addressFormatted = {
    venueName: get(address, "venueName", undefined),
    street1: get(address, "street1", undefined),
    street2: get(address, "street2", undefined),
    street3: get(address, "street3", undefined),
  };

  const addressValues = [...values(addressFormatted)].filter(Boolean);

  return isEmpty(addressValues) ? null : addressValues.join(COMMA_AND_SPACE);
};

const getPartTwoOfAddressFormatted = (address: Address): string => {
  const addressFormatted = {
    zipCode: get(address, "zipCode", undefined),
    city: get(address, "city", undefined),
    country: get(address, "country.name", undefined),
  };

  const addressValues = [...values(addressFormatted)].filter(Boolean);

  return isEmpty(addressValues) ? null : addressValues.join(SPACE);
};

export const getAddressFormatted = (address: Address): string => {
  if (!address) {
    return "";
  }

  const firstPart = getPartOneOfAddressFormatted(address);
  const secondPart = getPartTwoOfAddressFormatted(address);

  return !isEmpty(firstPart) ? `${firstPart}, ${secondPart}` : `${secondPart}`;
};

export const isAddressComplete = (address: Address): boolean => {
  return Boolean(
    address &&
      address.city &&
      address.country &&
      address.country.name &&
      (address.venueName || address.street1 || address.street2 || address.street3)
  );
};

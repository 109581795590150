import * as React from "react";
import { EventDetail } from "containers/event/event.types";
import { formatTitleDate } from "@ic-anywhere/ic-utils";
import * as sgLogo from "assets/images/socgen_logo_full.svg";
import Refusals from "components/Event/Corporate/EventExtractProgram/Refusals";
import Contacts from "components/Event/Corporate/EventExtractProgram/Contacts";
import Program from "components/Event/Corporate/EventExtractProgram/Program";

export type EventExtractProgramProps = {
  eventDetail: EventDetail;
};

export default function EventExtractProgram({ eventDetail, eventDetail: { event } }: EventExtractProgramProps): JSX.Element {
  return (
    <div className="roadshow-print">
      <header className="mt-5 mb-5">
        <img className="w-25 mr-5 mb-4" src={sgLogo} alt="Application Logo" />
        <hgroup>
          <h1 className="socgen-color mb-2">{event.name}</h1>
          <h3>{formatTitleDate(event.startDate, event.endDate)}</h3>
        </hgroup>
      </header>
      <hr />
      <Contacts eventDetail={eventDetail} />
      <hr />
      <Program eventDetail={eventDetail} />
      <Refusals eventDetail={eventDetail} />
    </div>
  );
}

import { Modal } from "@sgbs-ui/core";
import { VirtualLocation as VirtualLocationProps } from "common/event/event.types";
import * as React from "react";
import VirtualLocation from "../EditableSlotItem/VirtualLocation";

interface Props {
  onClose: () => void;
  virtualLocation: VirtualLocationProps;
}

export const VirtualLocationInfoModal: React.VFC<Props> = ({ onClose, virtualLocation}) => {  
  return (
    <Modal
      show={true}
      isConfirmValid={false}
      backdropClose={true}
      size="md"
      title="Virtual location information"
      onClose={onClose}
    >
      <VirtualLocation {...virtualLocation} className="d-block mt-2 text-primary" />
    </Modal>
  );
};

import * as React from "react";
import EventCard from "components/Event/Dashboard/EventCard/EventCard";
import { EventItem } from "common/event/event.types";
import Header from "components/layout/Header/PageHeader/Header";
import { useAtomValue } from "jotai";
import { searchTermAtom } from "components/Event/Dashboard/utils/filtersAtom";
import { filter, isEmpty, partition, orderBy } from "lodash";
import { isContainSubString } from "@ic-anywhere/ic-utils";
import { Loader } from "@sgbs-ui/core";
import { EventsListHeader } from "./EventsListHeader";
import { isDateBeforeToday } from "utils/dateUtils";

type Props = {
  events: EventItem[];
  isFetching: boolean;
};

export const filterAndSortEvents = (events: EventItem[], searchTerm: string): EventItem[] => {
  const filteredEvents = filter(events, ({ name }) => isContainSubString(name, searchTerm));
  const [passedEvents, eventsToCome] = partition(filteredEvents, (event) => isDateBeforeToday(event.endDate));
  const orderedEventsToCome = orderBy(eventsToCome, ["startDate"], ["desc"]);
  const orderedPassedEvents = orderBy(passedEvents, ["startDate"], ["desc"]);
  return [...orderedEventsToCome, ...orderedPassedEvents];
};
export const EventsList: React.FC<Props> = (props) => {
  const { events, isFetching } = props;
  const searchTerm = useAtomValue(searchTermAtom);
  const filteredAndSortedEvents = filterAndSortEvents(events, searchTerm);

  return (
    <>
      <Header headerOptions={{ displayBackButton: false }} />
      <div className="container">
        {isFetching ? (
          <Loader isVisible={true} />
        ) : (
          <>
            <EventsListHeader />
            <div className="col-lg-8 col-12">
              {isEmpty(filteredAndSortedEvents) ? (
                <span className="text-left">No event available</span>
              ) : (
                <>
                  {filteredAndSortedEvents.map((event, index) => (
                    <EventCard key={index} event={event} displayMoreInfo={true} searchTerm={searchTerm} />
                  ))}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

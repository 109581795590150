import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { createBooking, declineEventInvitation, updateBooking } from "api/events/event.api";
import useCrmRepository from "api/useCrmRepository";
import { BookingModificationAction, BookingUpdateModel } from "common/event/event.types";
import { filter } from "lodash";
import { useInvitQueriesInvalidation, useSlotsQueriesInvalidation } from "queries/useInvitQueriesInvalidation";
import { toastifyErrorMessage } from "@ic-anywhere/ic-components";
import { ApiError } from "@ic-anywhere/ic-dal";
import { fromPromise } from "rxjs/internal-compatibility";
import { concatMap, map } from "rxjs/operators";
import { from } from "rxjs";

export const useDeclineEventInvitationMutation = (eventId: string) => {
  const repo = useCrmRepository();
  const invalidate = useInvitQueriesInvalidation(eventId);

  return useMutation({ mutationFn: () => declineEventInvitation(repo, eventId), onSuccess: () => {
      invalidate();
    }, onError: (err: ApiError) => {
      toastifyErrorMessage(err.message.toString());
      invalidate();
    } });
};

export const useUpdateBookingsMutation = (eventId: string): UseMutationResult<void, any, BookingUpdateModel[], any> => {
  const repo = useCrmRepository();
  const invalidateAll = useInvitQueriesInvalidation(eventId);
  const invalidateSlots = useSlotsQueriesInvalidation(eventId);

  return useMutation({ mutationFn: (bookings: BookingUpdateModel[]) => {
      const creations = filter(bookings, (b) => b.action === BookingModificationAction.Create).map(
        (b) => () => createBooking(repo, eventId, b.slotId, b.booking).then(() => null)
      );
      const updates = filter(bookings, (b) => b.action === BookingModificationAction.Update).map(
        (b) => () => updateBooking(repo, eventId, b.slotId, b.booking)
      );

      const promises = from([...creations, ...updates]);

      return promises.pipe(concatMap((prom) => fromPromise(prom()).pipe(map((_) => null)))).toPromise();
    }, onSuccess: () => {
        invalidateAll();
      }, onError: (err: ApiError) => {
        toastifyErrorMessage(err.message.toString());
        invalidateAll();
      } });
};

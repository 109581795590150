import * as React from "react";
import { EventExtractProgramProps } from "components/Event/Corporate/EventExtractProgram/EventExtractProgram";
import { useGetInvitationsQuery } from "queries/useGetInvitationsQuery";
import { groupInvitationByStatus } from "components/Event/Corporate/invitationStatus.mapper";
import { flatten } from "lodash";
import { Loader } from "@sgbs-ui/core";

export default function Refusals({ eventDetail }: EventExtractProgramProps): JSX.Element {
  const { data, isFetching, hasNextPage } = useGetInvitationsQuery(eventDetail.event.id);

  const inviteeByStatus = groupInvitationByStatus(flatten(data));

  if (isFetching || hasNextPage) {
    return (
      <div className="mb-4 mt-5">
        <Loader isVisible={true} />
      </div>
    );
  }

  return (
    <div className="mt-5 roadshow-invitations">
      {inviteeByStatus.map((inviteeStatus, index) => {
        if (inviteeStatus.invitees.length === 0) {
          return;
        }
        return (
          <ul key={index} className="list-unstyled">
            <h4 className="mb-4 socgen-color">{inviteeStatus.name}</h4>
            <ul className="list-unstyled">
              {inviteeStatus.invitees.map((invitee) => (
                <li key={invitee.id} className="text-left d-flex mb-3 break-inside-avoid">
                  <p className="w-50 font-weight-bold">{invitee.accountName}</p>
                  <div>
                    <p className="mb-1">{invitee.friendlyName}</p>
                    <p className="mb-1">{invitee.mainEmail}</p>
                  </div>
                </li>
              ))}
            </ul>
          </ul>
        );
      })}
    </div>
  );
}

import { Level } from "@dcc-cli/cli-web-logger";

export const enum LogTypes {
  functional = "functional",
  technical = "technical",
  performance = "performance",
}

export { Level as LogLevels };

export const enum AsyncActionTypes {
  started = "STARTED",
  done = "DONE",
  failed = "FAILED",
}

export interface LogMessage {
  name: string;
  type: LogTypes;
  feature: string;
  event?: string;
  description: string;
  level?: Level;
  watchKey?: string;
}

import * as React from "react";
import { getWidgetBus } from "common/auth/getWidgetBus";
import { tokenAtom } from "state/authentAtoms";
import { useAtom, useSetAtom } from "jotai";
import { useRequestMeQuery } from "queries/useContactQueries";
import { userProfileStateAtom } from "state/userProfileAtoms";
import { AuthenticationErrorPage } from "components/layout/ErrorPage/AuthenticationErrorPage";
import { isInLanAtom } from "state/isInLanAtom";
import { SgConnectUserInfo } from "@ic-anywhere/ic-dal";

const getSgwtConnectElement = () => document.head.querySelector("sgwt-connect");
const getSgwtAccountCenterElement = () => document.querySelector("sgwt-account-center");

const signOut = (): void => {
  const sgwtAccountCenter = getSgwtAccountCenterElement() as any;
  sgwtAccountCenter.signOut();
};

export const Authentication: React.FC = ({ children }) => {
  const [userConnection, setUserConnection] = React.useState<{ connected: boolean } | undefined>();
  const [token, setToken] = useAtom(tokenAtom);
  const [_, setUserProfile] = useAtom(userProfileStateAtom);
  const setIsInLan = useSetAtom(isInLanAtom);

  const handleUserInfoChanged = (userInfo: SgConnectUserInfo): void => {
    if (userInfo) {
      setIsInLan(userInfo.origin_network === "LAN");
    }
  };

  React.useEffect(() => {
    const handleTokenChanged = (token: string | undefined): void => {
      if (token) {
        setToken(token);
      } else {
        signOut();
      }
    };

    const bus = getWidgetBus();
    bus?.subscribe("sg-connect.access-token", handleTokenChanged);
    bus?.subscribe("sg-connect.user-connection", setUserConnection);
    bus?.subscribe("sg-connect.user-info", handleUserInfoChanged);

    const sgwtConnect = getSgwtConnectElement() as any;

    sgwtConnect?.sgwtConnect?.on("authorizationExpired", () => signOut());

    return () => {
      bus?.unsubscribe("sg-connect.access-token", handleTokenChanged);
      bus?.unsubscribe("sg-connect.user-connection", setUserConnection);
      bus?.unsubscribe("sg-connect.user-info", handleUserInfoChanged);
    };
  }, [setToken]);

  const { data: fetchedUser, isFetching, isSuccess, isError } = useRequestMeQuery(!!userConnection?.connected);

  React.useEffect(() => {
    if (isSuccess && fetchedUser) {
      setUserProfile({ userInfo: fetchedUser, loggedIn: true, error: undefined });
    }
  }, [isSuccess, fetchedUser, setUserProfile]);

  if (isError) {
    return <AuthenticationErrorPage />;
  }

  if (isFetching || !token) {
    return <span>...loading</span>;
  }
  return <>{children}</>;
};

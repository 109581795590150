import * as React from "react";
import { map } from "lodash";
import { EventContact } from "api/events/event.types";
import { ContactInfoWithLink } from "../../Contact/ContactInfoWithLink";
import { FieldPhoneOrMail } from "./FieldPhoneOrMail";

type Props = {
  contacts: EventContact[];
  title: string;
  containerClassName?: string;
};

export const ContactNameAndPhoneNumber: React.VFC<Props> = ({ title, contacts, containerClassName }) => {
  return (
    <div>
      <ul className="list-unstyled">
        <li className={"mb-2"}>
          <strong>{title}</strong>
        </li>
        {map(contacts, ({ id, friendlyName, businessPhone, mobilePhone, comment, mainEmail }) => (
          <ul key={id} className={`list-unstyled mb-2 ${containerClassName ?? ""}`}>
            <li className="d-flex align-items-center line-height-lg justify-content-between">
              <span>{friendlyName}</span>
              <div className="d-flex d-lg-none d-print-none">
                <ContactInfoWithLink
                  businessPhone={businessPhone}
                  mobilePhone={mobilePhone}
                  displayIcon={true}
                  className="d-flex justify-content-around"
                />
              </div>
            </li>
            <div className="d-none d-lg-block d-print-none">
              <ContactInfoWithLink businessPhone={businessPhone} mobilePhone={mobilePhone} />
            </div>
            <div>
              <ul className="d-none list-unstyled m-0 d-print-block">
                {businessPhone && (
                  <li>
                    <FieldPhoneOrMail field={businessPhone} type={"Tel"} />
                  </li>
                )}
                {mobilePhone && (
                  <li>
                    <FieldPhoneOrMail field={mobilePhone} type={"Mob"} />
                  </li>
                )}
                {mainEmail && (
                  <li>
                    <FieldPhoneOrMail field={mainEmail} type={"Email"} />
                  </li>
                )}
              </ul>
            </div>
            {comment && (
              <li className="d-none d-lg-block d-print-flex">
                <span className="text-secondary">{comment}</span>
              </li>
            )}
          </ul>
        ))}
      </ul>
    </div>
  );
};

import * as React from "react";
import { useWidget } from "@ic-anywhere/ic-components";

type Props = {
  siteId: string;
};

export const SgwtWebAnalytics: React.FC<Props> = ({ siteId }) => {
  useWidget("sgwt-web-analytics", "sgwt-analytics");
  return <sgwt-web-analytics site-id={siteId} />;
};

import * as React from "react";
import { EventContact } from "api/events/event.types";
import { isEmpty, isEqual, size, union, uniqWith } from "lodash";
import { ContactNameAndPhoneNumber } from "components/Event/Corporate/Tabs/CardItem/ContactNameAndPhoneNumber";
import { pluralize } from "@ic-anywhere/ic-utils";
import { Card } from "components/common/Card";

export interface Props {
  eventManagers: EventContact[];
  businessContacts: EventContact[];
  internalSpeakers: EventContact[];
  drivers?: EventContact[];
  classNames?: string;
}

export const CardUsefulContacts: React.FunctionComponent<Props> = ({
  eventManagers,
  businessContacts,
  internalSpeakers,
  drivers,
  classNames,
}) => {
  const businessContactsList = uniqWith(union(businessContacts || [], internalSpeakers || []), isEqual);
  return (
    <>
      {!isEmpty(eventManagers) || !isEmpty(businessContactsList) || !isEmpty(drivers) ? (
        <Card className={classNames} title="Contacts">
          {!isEmpty(eventManagers) && (
            <ContactNameAndPhoneNumber
              title={`Event ${pluralize("manager", size(eventManagers))}`}
              contacts={eventManagers}
              containerClassName="border-top pt-2"
            />
          )}
          {!isEmpty(businessContactsList) && (
            <ContactNameAndPhoneNumber
              title={`Business ${pluralize("contact", size(businessContactsList))}`}
              contacts={businessContactsList}
              containerClassName="border-top pt-2"
            />
          )}
          {!isEmpty(drivers) && (
            <ContactNameAndPhoneNumber
              title={`Your ${pluralize("driver", size(drivers))}`}
              contacts={drivers}
              containerClassName="border-top pt-2"
            />
          )}
        </Card>
      ) : null}
    </>
  );
};

import * as React from "react";
import { assign, filter, findIndex, isEmpty } from "lodash";
import { BookingDetail, EventDateRange } from "api/events/event.types";
import { BookingStatus, BookingWriteModel } from "common/event/event.types";
import {
  GroupedSlot,
  GroupedSlots,
  groupSlots,
  isSlotEditable,
} from "components/EventInvitation/useEventInvitations.rules";
import { mapToIsoDateFormat } from "utils/dateUtils";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import { useModal } from "@ic-anywhere/ic-components";
import { DeclineMultiConfirmationModal } from "components/EventInvitation/Modals/DeclineMultiConfirmationModal";
import { Slot } from "containers/event/event.types";
import useDeepCompareEffect from "use-deep-compare-effect";
import { renderInfoOfSlotInProgram } from "components/Event/Corporate/Tabs/SlotsDetail/SlotsDetailBody.utils";
import { Button } from "@sgbs-ui/core";

export type BookingEditModel = { checked: boolean; slot: Slot; booking: BookingWriteModel };
type GroupedSlotByDay = GroupedSlot & {
  initialChecked: boolean;
  checked: boolean;
  editable: boolean;
  booking: BookingWriteModel;
  isFull: boolean;
};

interface SlotItemBodyProps {
  bookingDetails: BookingDetail[];
  eventDateRange: EventDateRange;
  isUpdating: boolean;
  onSave: (bookingsToModify: BookingEditModel[], allDeclined: boolean) => void;
  onCancel: () => void;
}

const isSlotChecked = (b: BookingDetail): boolean => {
  return b.booking != null && b.booking.status === BookingStatus.Booked;
};

export const isSlotFull = ({ slot, booking }: BookingDetail): boolean => {
  return (
    filter(slot.bookings, (b) => b.status === BookingStatus.Booked && b.id !== booking?.id).length >= slot.capacity
  );
};

export const EditableSlotItemBody: React.FC<SlotItemBodyProps> = ({
  bookingDetails,
  eventDateRange,
  onSave,
  onCancel,
  isUpdating,
}) => {
  const groupedSlotsByDay: GroupedSlots<GroupedSlotByDay>[] = !isEmpty(bookingDetails)
    ? groupSlots(bookingDetails, (b) => ({
        initialChecked: isSlotChecked(b),
        checked: isSlotChecked(b),
        editable: isSlotEditable(b),
        booking: b.booking,
        isFull: isSlotFull(b),
      }))
    : [];

  const [daysWithSlots, setDaysWithSlots] = React.useState(groupedSlotsByDay);
  const [showConfirmation, openConfirmation, closeConfirmation] = useModal();

  useDeepCompareEffect(() => {
    setDaysWithSlots(groupedSlotsByDay);
  }, [groupedSlotsByDay]);

  if (isEmpty(bookingDetails)) {
    return null;
  }

  const isMultiDayEvent = groupedSlotsByDay.length > 1;
  const checkedSlots = (): BookingEditModel[] =>
    daysWithSlots.reduce((acc, item) => {
      return [...acc, ...item.slotsOfDay.filter((x) => x.checked)];
    }, []);

  const handleChange = (slotId: string) => {
    const immutableGroupedSlots = daysWithSlots.reduce((acc, day) => {
      const index = findIndex(day.slotsOfDay, (x) => x.slot.id === slotId);
      if (index > -1) {
        const slot = day.slotsOfDay[index];
        const updatedSlot = { ...slot, checked: !slot.checked };
        const immutableSlots = assign([], day.slotsOfDay, { [index]: updatedSlot });
        day = { ...day, slotsOfDay: immutableSlots };
      }
      return [...acc, day];
    }, []);
    setDaysWithSlots(immutableGroupedSlots);
  };

  const trySave = () => {
    if (isEmpty(checkedSlots())) {
      openConfirmation();
    } else {
      confirmSave();
    }
  };

  const confirmSave = () => {
    const bookingsToModify: BookingEditModel[] = daysWithSlots.reduce((acc, item) => {
      return [...acc, ...item.slotsOfDay.filter((x) => x.checked !== x.initialChecked)];
    }, []);
    logRegistrationPageClicOnMyProgramSave();
    onSave(bookingsToModify, isEmpty(checkedSlots()));
  };

  const cancel = () => {
    logRegistrationPageClickOnCancelFromModal();
    onCancel();
  };

  return (
    <>
      <div className="text-secondary">Select sessions you want to attend to confirm your participation</div>
      <ul className="list-unstyled pt-2">
        {daysWithSlots.map(({ startDateDayMonth, startDateYear, slotsOfDay, startDateIso }, index) => {
          return (
            <li key={index}>
              {isMultiDayEvent ? (
                <strong className={"d-inline-block pt-3"}>{`Sessions ${startDateDayMonth} ${startDateYear} | Day ${
                  index + 1
                }`}</strong>
              ) : startDateIso !== mapToIsoDateFormat(eventDateRange.startDate) ? (
                <strong className={"d-inline-block pt-3"}>{`Sessions ${startDateDayMonth} ${startDateYear}`}</strong>
              ) : null}
              {renderOneDaySlots(slotsOfDay, handleChange, index)}
            </li>
          );
        })}
      </ul>
      <div className="d-flex pt-4 justify-content-end">
        <Button text="Save" size="lg" onClick={trySave} disabled={isUpdating} loading={isUpdating} />
        <Button text="Cancel" btnType="flat" className="ml-2" onClick={cancel} />
      </div>
      {showConfirmation && (
        <DeclineMultiConfirmationModal onConfirm={confirmSave} onClose={closeConfirmation} isSaving={isUpdating} />
      )}
    </>
  );
};

const renderOneDaySlots = (slotsOfDay: GroupedSlotByDay[], handleChange: (slotId: string) => void, key: number) => (
  <ul className="list-unstyled pt-3 ml-4" key={key}>
    {slotsOfDay.map(({ checked, slot, editable, isFull }) => (
      <div key={slot.id} className={`d-flex align-items-center line-height-lg ${editable ? "" : "disabled"}`}>
        <input
          id={`checkbox-${slot.id}`}
          type="checkbox"
          className="form-check-input"
          checked={checked}
          disabled={!editable || (isFull && !checked)}
          onChange={handleChange.bind(null, slot.id)}
        />
        <label
          className={`${isFull && !checked ? "text-secondary" : "text-primary"} ml-3 mb-0`}
          htmlFor={`checkbox-${slot.id}`}
        >
          {renderInfoOfSlotInProgram(slot, {
            className: "font-weight-bold",
            slotTypeLocation: { isSlotFull: isFull, address: slot.address, virtualLocation: slot.virtualLocation },
          })}
        </label>
      </div>
    ))}
  </ul>
);

const logRegistrationPageClicOnMyProgramSave = () => {
  logMessage({
    ...features.registrationPageClicOnMyProgramSave,
    type: LogTypes.functional,
    feature: "event",
    name: "Registration page for log click on save from my program",
  });
};

const logRegistrationPageClickOnCancelFromModal = () => {
  logMessage({
    ...features.registrationPageClicOnMyProgramCancel,
    type: LogTypes.functional,
    feature: "event",
    name: "Registration page for log click on cancel from my program",
  });
};

import { ApiRepository } from "@ic-anywhere/ic-dal";
import { UserPermissionsDTO } from "api/users/users.typings";

const url = "/private/0.0/users";

export const impersonateAllPermission = "ImpersonateAll";

export const fetchUserPermission = async (repo: ApiRepository, userContactId: string): Promise<UserPermissionsDTO> => {
  const result = await repo.get<UserPermissionsDTO>(`${url}/${userContactId}`);
  return result.data;
};

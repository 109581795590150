import * as React from "react";
import { useAtom } from "jotai";
import { userProfileStateAtom } from "state/userProfileAtoms";
import { getEnvironment } from "@ic-anywhere/ic-utils";

const defaultRecipient = getEnvironment() === "production" ? "sgmarkets@sgcib.com" : "emmanuel.pelletier@sgcib.com";

const applicationId = "sg-markets-events";

export const HelpCenter: React.FC = ({ children }) => {
  const [userState] = useAtom(userProfileStateAtom);

  return (
    <>
      {children}
      {applicationId && (
        <aside>
          <sgwt-help-center
            id="my-help-center"
            introduction-tour="true"
            sg-connect-support="sg-connect-v2"
            user={JSON.stringify(userState.userInfo.userEmail)}
            default-send-to={defaultRecipient}
            application-id={applicationId}
          />
        </aside>
      )}
    </>
  );
};

import { Button, SGBSColor } from "@sgbs-ui/core";
import * as React from "react";
import { featureTracking } from "components/common/event.tracking";
import { features } from "utils/logger/features";

type Props = {
  isOneSlotRegistration: boolean;
  onModifyRegistrationOneSlot: () => void;
  onModifyRegistrationMultiSlot: () => void;
  isUpdating: boolean;
  color: SGBSColor;
};

export const ModifyRegistrationButton: React.VFC<Props> = ({
  isOneSlotRegistration,
  onModifyRegistrationOneSlot,
  onModifyRegistrationMultiSlot,
  isUpdating,
  color,
}) => {
  const modifyRegistration = () => {
    featureTracking(features.registrationPageClickOnModifyRegistration);
    if (isOneSlotRegistration) {
      featureTracking(features.registrationPageClicOnMyProgramSave);
      onModifyRegistrationOneSlot();
    } else {
      onModifyRegistrationMultiSlot();
    }
  };

  return (
    <Button
      color={color}
      className="ml-2"
      size="lg"
      text="Modify registration"
      disabled={isUpdating}
      onClick={modifyRegistration}
    />
  );
};

import * as React from "react";

type Props = {
  field: string;
  type: "Tel" | "Mob" | "Email";
};

export const FieldPhoneOrMail: React.VFC<Props> = ({ field, type }) => {
  return (
    <>
      <span className="text-primary mr-1">{type}</span>
      <span className="text-secondary">{field}</span>
    </>
  );
};

import {
  Address,
  AddressType,
  Booking,
  BookingStatus,
  BookingWriteModel,
  ContactShortReference,
  EmailDeliveryStatus,
  InvitationStatus,
  VenueInfo,
  VirtualLocation,
} from "common/event/event.types";
import { PDFReaderObject, Slot } from "containers/event/event.types";
import { includes } from "lodash";

export interface MyEventInvitationDto {
  event: MyEventDto;
  invitation: MyInvitationDto;
  isExternalSpeaker: boolean;
}

export interface MyEventDto {
  id: string;
  category?: string;
  startDate: Date;
  endDate: Date;
  name: string;
  groupName?: string;
  webSiteUrl: string;
  type?: string;
  status?: number;
  venue: VenueInfo;
  description?: string;
  usefulInformation?: string;
}

export interface MyInvitationDto {
  id?: string;
  status?: InvitationStatus;
  emailDeliveryStatus?: EmailDeliveryStatus;
  inviteeWebSiteUrl?: string;
  inviteeDeclineLink?: string;
  inviteeContactId?: string;
  webSiteUrl?: string;
  isEventManager?: boolean;
}

export interface EventBookingDetails {
  event: Event;
  bookings: BookingDetail[];
  invitation: MyInvitationDto;
}

export interface MyEventInvitationsDto {
  firstPageUri?: string;
  events: MyEventInvitationDto[];
  page?: number;
  pageSize?: number;
}

export interface EventDateRange {
  startDate: Date;
  endDate: Date;
}

export interface BookingDetail {
  slot: Slot;
  booking: BookingWriteModel;
}

export interface BookingModificationResult {
  slotId: string;
  bookingId: string;
  status: BookingStatus;
}

export interface Event {
  id: string;
  category?: string;
  startDate: Date;
  endDate: Date;
  name: string;
  groupName?: string;
  webSiteUrl: string;
  inviteeWebSiteUrl?: string;
  inviteeDeclineLink?: string;
  type?: string;
  status?: number;
  venue: VenueInfo;
  eventManagerContacts?: EventContact[];
  eventFrontOfficerContacts?: EventContact[];
  internalSpeakerContacts?: EventContact[];
  externalSpeakerContacts?: EventContact[];
  businessContacts?: EventContact[];
  bankerContacts?: EventContact[];
  corporateContacts?: EventCorporateContact[];
  corporateContactHandout?: string;
  resources?: EventResourceView[];
  drivers?: EventContact[];
  description?: string;
  usefulInformation?: string;
}

export interface EventDto extends MyEventDto {
  invitationStatus?: number;
  eventManagerContacts?: EventContact[];
  eventFrontOfficerContacts?: EventContact[];
  internalSpeakerContacts?: EventContact[];
  externalSpeakerContacts?: EventContact[];
  businessContacts?: EventContact[];
  bankerContacts?: EventContact[];
  resources?: EventResource[];
  drivers?: DriverContact[];
  corporateContacts?: EventCorporateContactDto[];
  corporateContactHandout?: string;
}

export interface EventContact {
  id: string;
  accountName?: string;
  friendlyName: string;
  mobilePhone?: string;
  businessPhone?: string;
  jobTitle?: string;
  mainEmail?: string;
  comment?: string;
}

export interface DriverContact {
  lastName?: string;
  firstName?: string;
  phone?: string;
  comment?: string;
}

export type EventCorporateContactDto = {
  id: string;
  friendlyName: string;
  jobTitle: string;
  accountName: string;
  mainEmail: string;
};

export type EventCorporateContact = EventCorporateContactDto;

export interface SlotsDto {
  firstPageUri?: string;
  slots: SlotDto[];
  page?: number;
  pageSize?: number;
  uri?: string;
  previousPageUri?: string;
  nextPageUri?: string;
}

export interface SlotDto {
  id: string;
  speakers?: ContactShortReference[];
  bookings?: Booking[];
  address?: Address;
  name?: string;
  startDate?: string;
  endDate?: string;
  capacity?: number;
  type?: EventSlotType;
  addressType?: AddressType;
  venueName?: string;
  online: boolean;
  comment?: string;
  virtualLocation?: VirtualLocation;
  timeZone?: string;
}

export enum INVITATION_STATUS {
  NONE = "",
  PENDING = "Pending",
  ATTENDING = "Attending",
  DECLINED_NOT_AVAILABLE = "Declined",
  DECLINED_NOT_INTERESTED = "Declined",
}

export const INVITATION_STATUS_LIST = [
  INVITATION_STATUS.NONE,
  INVITATION_STATUS.PENDING,
  INVITATION_STATUS.ATTENDING,
  INVITATION_STATUS.DECLINED_NOT_AVAILABLE,
  INVITATION_STATUS.DECLINED_NOT_INTERESTED,
];

export type EventResourceCategory =
  | "InvitationImage"
  | "WebsiteHeaderImage"
  | "InvestorProfiles"
  | "TravelDocuments"
  | "Feedback";

export interface EventResource {
  id: string;
  category: EventResourceCategory;
  contentType: string;
  fileName: string;
  size: number;
}

export interface EventResourceView {
  id: string;
  category: EventResourceCategory;
  resourceUrlAndHeader: PDFReaderObject;
  fileName: string;
}

export enum EventSlotType {
  OneToOne = "One To One",
  GroupMeeting = "Group meeting",
  GroupLunch = "Group lunch",
  GroupBreakfast = "Group breakfast",
  Cocktail = "Cocktail",
  Accomodation = "Accomodation",
  Travel = "Travel",
  AllDay = "All day",
}

export const showCommentForSlotTypes = (eventSlotType: EventSlotType) =>
  includes(
    [
      EventSlotType.Accomodation,
      EventSlotType.Travel,
      EventSlotType.GroupLunch,
      EventSlotType.GroupBreakfast,
      EventSlotType.Cocktail,
      EventSlotType.OneToOne,
    ],
    eventSlotType
  );

export type EventInvitedBy = {
  id: string;
  friendlyName: string;
};

type FeedbackInvitee = EventInvitedBy & {
  fullName: string;
  purged: boolean;
};

export type EventFeedbackDTO = {
  description: string;
  feedbackBy: FeedbackInvitee;
  feedbackByContactId: string;
  id: string;
  invitee: FeedbackInvitee;
  inviteeContactId: string;
  modifiedOn: string;
};

export type EventFeedback = EventFeedbackDTO;

export type EventInviteeFeedback = {
  id: string;
  friendlyName: string;
  feedback: EventFeedback;
  slotType: EventSlotType;
};

export type EventInvitationDto = {
  id: string;
  invitee: EventContact;
  status: EventInvitationStatus;
  noShow?: boolean;
};

export type GetInvitationsDto = {
  page: number;
  pageSize: number;
  totalPages: number;
  totalResults: number;
  _embedded: {
    invitations: EventInvitationDto[];
  };
};

export enum BaseEventInvitationStatus {
  PENDING = 1,
  DECLINED_NOT_AVAILABLE = 3,
  DECLINED_NOT_INTERESTED = 4,
  NO_SHOW,
}

export enum EventInvitationSuccess {
  SUCCESS = 2,
}

export type EventInvitationStatus = BaseEventInvitationStatus | EventInvitationSuccess;

import * as React from "react";
import { map } from "lodash";

type Props = {
  issuers: any[];
};
export const IssuerItem: React.VFC<Props> = ({ issuers }) => (
  <div>
    <ul className="list-unstyled">
      {map(issuers, (issuersGroupByAccountName, index) => (
        <div
          key={issuersGroupByAccountName.accountName}
          className={`mb-2 ${index < issuers.length - 1 ? "pb-4 border-bottom" : ""}`}
        >
          <h3 className="card-title mb-2">{issuersGroupByAccountName.accountName}</h3>
          {map(issuersGroupByAccountName.issuers, (issuer) => (
            <ul key={issuer.id} className="list-unstyled">
              <li>{`${issuer.friendlyName} (${issuer.jobTitle})`}</li>
            </ul>
          ))}
        </div>
      ))}
    </ul>
  </div>
);

import * as React from "react";
import { map, size } from "lodash";
import { AddressPoint } from "components/Event/Corporate/AddressPoint/AddressPoint";
import { Slot } from "containers/event/event.types";
import { GroupedSlot, GroupedSlots } from "components/EventInvitation/useEventInvitations.rules";
import { BookingDetail } from "api/events/event.types";
import { renderInfoOfSlotInProgram } from "components/Event/Corporate/Tabs/SlotsDetail/SlotsDetailBody.utils";

export interface BookingDetailsProps {
  groupedSlots: Array<GroupedSlots<GroupedSlot & BookingDetail>>;
}

export const DayTitle: React.FunctionComponent<{ index: number }> = ({ index }) => (
  <span className="d-inline-block font-weight-bold mt-2 js-day-title">{`Day ${index + 1}`}</span>
);

export const DailySlotAddress: React.FunctionComponent<{ slot: Slot }> = ({ slot }) => (
  <>
      {renderInfoOfSlotInProgram(slot, {className: "font-weight-bold"})}
    <li className="mb-1">
      <AddressPoint address={slot.address} />
    </li>
  </>
);

export const DailySlotAddresses: React.FunctionComponent<{
  slotsOfDay: Array<GroupedSlot & BookingDetail>;
}> = ({ slotsOfDay }) => (
  <ul className="list-unstyled">
    {map(slotsOfDay, ({ slot }, index) => (
      <React.Fragment key={index}>
        <DailySlotAddress slot={slot} />
      </React.Fragment>
    ))}
  </ul>
);

export const SlotAddressDetails: React.FunctionComponent<BookingDetailsProps> = ({ groupedSlots }) => {
  const isMultiDayEvent = size(groupedSlots) > 1;
  return (
    <ul className="list-unstyled">
      {map(groupedSlots, ({ slotsOfDay }, index) => (
        <React.Fragment key={index}>
          <li>{isMultiDayEvent ? <DayTitle index={index} /> : null}</li>
          <DailySlotAddresses slotsOfDay={slotsOfDay} />
        </React.Fragment>
      ))}
    </ul>
  );
};

import { SGBSColor } from "@sgbs-ui/core";
import * as React from "react";

type CircleIconProps = {
  status: SGBSColor;
};

export const CircleIcon: React.FC<CircleIconProps> = ({ status = "info", children }) => (
  <div
    className={`rounded-circle bg-${status}-alpha-md d-flex align-items-center justify-content-center mb-4`}
    style={{ width: 48, height: 48 }}
  >
    {children}
  </div>
);

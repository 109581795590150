import { useQuery, UseQueryResult } from "@tanstack/react-query";
import useCrmRepository from "api/useCrmRepository";
import { downloadInvestorPdf } from "api/events/event.api";
import { ExportFile, tenMinutesInMs } from "@ic-anywhere/ic-dal";

export const saveToFile = (file: ExportFile, overrideFileName?: string): void => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(file.blob);
  link.setAttribute("download", overrideFileName ?? file?.fileName ?? "report");
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const useExportPdf = (eventId: string, resourceId: string): UseQueryResult<void> => {
  const repo = useCrmRepository();

  return useQuery<void, string>({
    queryKey: ["exportPdf", eventId, resourceId],
    queryFn: () =>
      downloadInvestorPdf(repo, eventId, resourceId).then((fileBlob: ExportFile) => {
        saveToFile(fileBlob);
      }),
    enabled: false,
    cacheTime: tenMinutesInMs,
  });
};

import { getInvitationConfiguration } from "components/EventInvitation/useEventInvitations.rules";
import { InvitationActionType } from "components/EventInvitation/useEventInvitations";
import { BookingDetail } from "api/events/event.types";

export type ReduceActionType =
  | "load_initial_state"
  | "update_bookings"
  | "decline_mono_slot"
  | "confirm_mono_slot"
  | "decline_all_event"
  | "register_multi_slot"
  | "close_registration_multiSlot"
  | "hide_registration_multiSlot"
  | "close_modal";

type Action = {
  type: ReduceActionType;
  payload?: any;
};

export type InvitationHandlingState = {
  isOneSlot: boolean | undefined;
  currentModal: InvitationActionType;
  showModifyRegistration: boolean;
  bookings: BookingDetail[];
  urlRegistrationType: InvitationActionType;
  programView: "read" | "edit" | "hidden";
};

export const initialState = (): InvitationHandlingState => ({
  isOneSlot: undefined,
  currentModal: undefined,
  showModifyRegistration: false,
  bookings: [],
  urlRegistrationType: undefined,
  programView: "read",
});

export const invitationStateReducer = (state: InvitationHandlingState, action: Action): InvitationHandlingState => {
  let newState = state;

  switch (action.type) {
    case "load_initial_state": {
      newState = {
        ...state,

        showModifyRegistration: true,
      };
      break;
    }
    case "update_bookings": {
      const { bookings, invitation, urlAction } = action.payload;
      const { currentBookings, isOneSlot, invitationDeclined } = getInvitationConfiguration(
        bookings,
        invitation.status
      );

      newState = {
        ...state,
        isOneSlot,
        urlRegistrationType: urlAction,
        bookings: currentBookings,
        programView: invitationDeclined ? "hidden" : state.programView,
      };
      break;
    }

    case "confirm_mono_slot": {
      newState = { ...state, currentModal: "register" };
      break;
    }
    case "decline_mono_slot": {
      newState = { ...state, currentModal: "decline" };
      break;
    }
    case "decline_all_event": {
      newState = { ...state, currentModal: "declineAllEvent" };
      break;
    }
    case "register_multi_slot": {
      newState = { ...state, programView: "edit", showModifyRegistration: false };
      break;
    }
    case "close_registration_multiSlot": {
      newState = { ...state, programView: "read", showModifyRegistration: true, currentModal: action.payload };
      break;
    }
    case "hide_registration_multiSlot": {
      newState = { ...state, programView: "read", showModifyRegistration: true };
      break;
    }
    case "close_modal": {
      newState = { ...state, currentModal: undefined };
      break;
    }
  }

  // console.log("action: %o, newState: %o", action, newState);

  return newState;
};

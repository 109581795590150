import { useGetMyEventsQuery } from "queries/useGetMyEventsQuery";
import { find } from "lodash";
import * as React from "react";

type AccessCheck = "pending" | "access" | "not_access";

export const useCheckEventAccess = (eventId: string): AccessCheck => {
  const { data: allEvents, isFetching, isSuccess, isError } = useGetMyEventsQuery();
  const [checkState, setCheckState] = React.useState<AccessCheck>("pending");

  React.useEffect(() => {
    if (!isFetching && isSuccess) {
      const found = !!find(allEvents, (e) => e.id === eventId);
      setCheckState(found ? "access" : "not_access");
    }
    if (!isFetching && isError) {
      setCheckState("not_access");
    }
  }, [isFetching, isSuccess, isError, allEvents]);

  return checkState;
};

import { getHeaders, IcHttpRequestHeader } from "@ic-anywhere/ic-dal";
import { useAtom } from "jotai";
import { tokenAtom } from "state/authentAtoms";

const useQueryHeaders = (): IcHttpRequestHeader => {
  const [token] = useAtom(tokenAtom);

  return getHeaders(null, token);
};

export default useQueryHeaders;

import { useQueries } from "@tanstack/react-query";
import useCrmRepository from "api/useCrmRepository";
import { getMyInvitations } from "api/events/event.api";
import { ApiRepository, twoMinutesInMs } from "@ic-anywhere/ic-dal";
import { MyInvitationDto } from "api/events/event.types";
import { getEventByIdQuery } from "queries/useGetEventDetailQuery";
import { MergedQueryResult } from "queries/queries.typings";
import { find, some } from "lodash";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "state/userProfileAtoms";

export const invitationQueryKey = (eventId: string): string[] => [eventId, "getMyInvitations"];

export const getMyInvitsQuery = (repo: ApiRepository, eventId: string) => ({
  queryKey: invitationQueryKey(eventId),
  queryFn: () => getMyInvitations(repo, eventId),
  enabled: !!eventId,
  cacheTime: twoMinutesInMs,
  retry: false,
});

export const useGetMyInvitationsQuery = (eventId: string): MergedQueryResult<MyInvitationDto> => {
  const repo = useCrmRepository();
  const userInfo = useAtomValue(userInfoAtom);
  const queries = useQueries({ queries: [getEventByIdQuery(repo, eventId), getMyInvitsQuery(repo, eventId)] });
  const isError = some(queries, (q) => q.isError);
  const isFetching = some(queries, (q) => q.isFetching);
  const isLoading = some(queries, (q) => q.isLoading);
  const isSuccess = queries[0].isSuccess && !queries[1].isFetching;

  const [eventsResult, invitsResult] = queries;
  const { data: eventDto } = eventsResult;
  const { data: invitsDto } = invitsResult;
  const data = isSuccess
    ? {
        ...(invitsResult.isSuccess ? invitsDto : ({} as MyInvitationDto)),
        webSiteUrl: eventDto.webSiteUrl,
        isEventManager: !!find(eventDto.eventManagerContacts, ({ id }) => userInfo.userId === id),
      }
    : undefined;

  return { data, isFetching, isLoading, isSuccess, isError };
};

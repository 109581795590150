import * as React from "react";
import { some } from "lodash";
import { EventInvitationContent } from "components/EventInvitation/InformationsRow/EventInvitationContent";
import Header from "components/layout/Header/PageHeader/Header";
import { features } from "utils/logger/features";
import { Redirect, useParams } from "react-router";
import { InvitationActionType, useEventInvitations } from "components/EventInvitation/useEventInvitations";
import { RegistrationConfirmationModal } from "components/EventInvitation/Modals/RegistrationConfirmationModal";
import { ROUTES } from "common/routes";
import { buildUrl } from "utils/generateUrl";
import { CardModifyRegistration } from "./CardItem/CardModifyRegistration";
import { isSlotFull } from "./EditableSlotItem/EditableSlotItemBody";
import { NotInvitedErrorPage } from "components/EventInvitation/errors/NotInvitedErrorPage";
import { Loader } from "@ic-anywhere/ic-components";
import { useTrackOnDisplayPage } from "../common/event.tracking";

const EventInvitation: React.VFC = () => {
  const { id: eventId, action: urlAction } = useParams<{ id: string; action: string }>();
  const {
    event,
    bookings,
    isFetching,
    isUpdating,
    invitation,
    currentModal,
    isOneSlot,
    showModifyRegistration,
    programView,
    isUserEventManager,
    modifyRegistrationOneSlot,
    modifyRegistrationMultiSlot,
    saveMultiSlotEdition,
    cancelMultiSlotEdition,
    closeModal,
    getBookingsErrorStatus,
  } = useEventInvitations(eventId, urlAction as InvitationActionType);
  useTrackOnDisplayPage(features.registrationPageDisplay);

  if (isFetching) {
    return <Loader isVisible={true} />;
  }

  if (isUserEventManager) {
    return <Redirect to={buildUrl(ROUTES.EVENT_DETAIL_CORPORATE, { id: eventId })} />;
  }

  if (!isFetching && !!getBookingsErrorStatus) {
    return <NotInvitedErrorPage errorStatus={getBookingsErrorStatus} event={event} />;
  }
  return (
    <>
      <Header mainTitle={event.name} headerOptions={{ event, statusInvitation: invitation.status }} />
      <div className="container">
        {showModifyRegistration && (
          <CardModifyRegistration
            invitation={invitation}
            isOneSlot={isOneSlot}
            isUpdating={isUpdating}
            modifyRegistrationMultiSlot={modifyRegistrationMultiSlot}
            modifyRegistrationOneSlot={modifyRegistrationOneSlot}
            someSlotsAvailable={some(bookings, (bookingDetail) => !isSlotFull(bookingDetail))}
          />
        )}
        <EventInvitationContent
          event={event}
          isLoading={isFetching}
          isUpdating={isUpdating}
          bookingDetails={bookings}
          programView={programView}
          onSave={saveMultiSlotEdition}
          onCancel={cancelMultiSlotEdition}
        />
      </div>
      {currentModal && (
        <RegistrationConfirmationModal action={currentModal} slotType={bookings[0].slot.type} onClose={closeModal} />
      )}
    </>
  );
};
export default EventInvitation;

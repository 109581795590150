import * as React from "react";
import { EventDetail } from "containers/event/event.types";
import { find } from "lodash";
import Responsive from "components/common/Responsive/Responsive";
import { PdfReader } from "components/Event/Corporate/EventDocument/PdfReader";
import { ExportPdfButton } from "components/Event/Corporate/EventDocument/ExportPdfButton";
import useQueryHeaders from "api/useQueryHeaders";
import { SearchNoResult } from "@ic-anywhere/ic-components";
import { features } from "utils/logger/features";
import { useTrackOnDisplayPage } from "../../../common/event.tracking";

export const EventCorporateInvestorProfilesTab: React.VFC<{ eventDetail: EventDetail }> = ({ eventDetail }) => {
  useTrackOnDisplayPage(features.eventDetailsInvestorProfilesDisplay);
  const investorPdfResource = find(eventDetail.event.resources, (res) => res.category === "InvestorProfiles");
  const { authorization } = useQueryHeaders();

  if (!investorPdfResource) {
    return <SearchNoResult title="No investor profile" icon="search" containerClassName="mt-4" />;
  }

  const { resourceUrlAndHeader } = investorPdfResource;

  const pdfFetchInfo = {
    ...resourceUrlAndHeader,
    httpHeaders: {
      authorization,
    },
  };

  return (
    <>
      <h3 className="mt-5 mb-4">Investor profiles</h3>
      <div className="row pb-5 d-flex justify-content-between">
        <Responsive
          classNames="row h-100"
          mobileClassNames="row px-4 d-xs-block h-100"
          children={
            <PdfReader
              id="pdf-reader"
              pdfObject={pdfFetchInfo}
              hidePrintCommand={true}
              hideFullScreenCommand={true}
              hideRotationCommands={true}
              importPdfjsLib={true}
            />
          }
          mobileChildren={
            <PdfReader
              id="pdf-reader-sm"
              pdfObject={pdfFetchInfo}
              hidePrintCommand={true}
              hideFullScreenCommand={true}
              hideZoomCommands={true}
              hideRotationCommands={true}
              importPdfjsLib={true}
            />
          }
        />
        <ExportPdfButton
          eventId={eventDetail.event.id}
          resourceId={investorPdfResource.id}
          category={"InvestorProfiles"}
        />
      </div>
    </>
  );
};

import {
  DriverContact,
  Event,
  EventContact,
  EventDto,
  EventResource,
  EventResourceView,
  INVITATION_STATUS_LIST,
  MyEventInvitationDto,
  SlotDto,
} from "api/events/event.types";
import { BookingStatus, EventItem, EventUserRole } from "common/event/event.types";
import { Slot } from "containers/event/event.types";
import * as moment from "moment";
import { map, set, some, sortBy, uniqBy, uniqueId } from "lodash";
import { AfpUrls, getApiServiceUrl } from "common/getApiServiceUrl";

export const mapMyEventInvitationToEventItem = (myEvent: MyEventInvitationDto): EventItem => {
  let addressText = "";
  let city = "";
  const {
    id,
    name,
    groupName,
    category,
    startDate,
    endDate,
    venue,
    type,
    status,
    webSiteUrl,
    description,
    usefulInformation,
  } = myEvent.event;
  const isInvitee = myEvent.invitation != null;
  const invitationStatus = isInvitee ? INVITATION_STATUS_LIST[myEvent.invitation.status] : undefined;
  if (venue && venue.address) {
    const address = venue.address;
    city = address.city;
    const listAddressInfo = [address.city];
    if (address.country && address.country.name) {
      listAddressInfo.push(address.country.name);
    }
    addressText = listAddressInfo.filter(Boolean).join(" ");
  }

  const userRole = getEventUserRole(isInvitee, myEvent.isExternalSpeaker);
  return {
    id,
    startDate: moment.utc(startDate).toDate(),
    endDate: moment.utc(endDate).toDate(),
    status,
    name,
    groupName,
    category,
    type,
    invitationStatus,
    address: addressText,
    city,
    url: webSiteUrl,
    inviteeWebSiteUrl: isInvitee ? myEvent.invitation.inviteeWebSiteUrl : undefined,
    inviteeDeclineLink: isInvitee ? myEvent.invitation.inviteeDeclineLink : undefined,
    userRole,
    description,
    usefulInformation,
  };
};

export const normalizeEventDtoToEvent = (event: EventDto): Event => {
  const {
    id,
    name,
    groupName,
    category,
    startDate,
    endDate,
    venue,
    type,
    webSiteUrl,
    eventManagerContacts,
    externalSpeakerContacts,
    internalSpeakerContacts,
    corporateContacts,
    corporateContactHandout,
    businessContacts,
    bankerContacts,
    drivers,
    resources,
    description,
    usefulInformation,
  } = event;

  return {
    id,
    startDate: moment.utc(startDate).toDate(),
    endDate: moment.utc(endDate).toDate(),
    status: event.status,
    category,
    name,
    groupName,
    type,
    venue,
    webSiteUrl,
    eventManagerContacts,
    bankerContacts: sortBy(bankerContacts, ["accountName", "friendlyName"]),
    externalSpeakerContacts: sortBy(externalSpeakerContacts, ["accountName"]),
    internalSpeakerContacts: sortBy(internalSpeakerContacts, ["accountName", "friendlyName"]),
    corporateContacts,
    corporateContactHandout,
    businessContacts,
    resources: map(resources, (res) => mapToViewerResource(res, id)),
    drivers: drivers ? drivers.map((driver) => mapDriverContactToEventContact(driver)) : [],
    description,
    usefulInformation,
  };
};

export const normalizeSlotDtoToSlot = (slotDto: SlotDto): Slot => {
  const {
    id,
    address,
    addressType,
    endDate,
    startDate,
    type,
    bookings,
    venueName,
    online,
    comment,
    virtualLocation,
    capacity,
    timeZone,
  } = slotDto;
  set(address, "venueName", venueName);
  const bookedInvitee = sortBy(
    bookings.filter((booking) => booking.status === BookingStatus.Booked).map((booking) => booking.invitee),
    ["accountName", "friendlyName"]
  );

  return {
    id,
    bookings,
    invitees: bookedInvitee,
    attendees: bookedInvitee.map((invitee) =>
      invitee.accountName && invitee.jobTitle
        ? `${invitee.accountName} - ${invitee.friendlyName} (${invitee.jobTitle})`
        : `${invitee.friendlyName}`
    ),
    address,
    addressType,
    type,
    startDate,
    endDate,
    online,
    venueName,
    comment,
    virtualLocation,
    capacity,
    timeZone,
  };
};

const mapDriverContactToEventContact = (driverContact: DriverContact): EventContact => {
  const { firstName, lastName, phone, comment } = driverContact;
  return {
    id: uniqueId(),
    accountName: "Drivers",
    friendlyName: `${firstName} ${lastName}`,
    businessPhone: phone,
    comment,
  } as EventContact;
};

export const getEventUserRole = (isInvitee: boolean, isExternalSpeaker): EventUserRole => {
  if (isInvitee && isExternalSpeaker) {
    return "InvitedSpeaker";
  } else if (isInvitee) {
    return "Invitee";
  } else if (isExternalSpeaker) {
    return "Speaker";
  }
  return "Admin";
};

const hasResourceType = (event: Event, category: string) => some(event?.resources, (res) => res.category === category);

export const hasInvestorProfiles = (event: Event) => hasResourceType(event, "InvestorProfiles");

export const mapToEventDetail = (event: Event, slots: Slot[]) => ({
  event,
  slots,
  useFulContacts: {
    businessContacts: event.businessContacts ? uniqBy(event.businessContacts, "id") : [],
    internalSpeakers: event.internalSpeakerContacts ? uniqBy(event.internalSpeakerContacts, "id") : [],
    yourDriver: event.drivers ? uniqBy(event.drivers, "friendlyName") : [],
    eventManagerContacts: event.eventManagerContacts ? uniqBy(event.eventManagerContacts, "id") : [],
  },
  issuers: event.externalSpeakerContacts ? uniqBy(event.externalSpeakerContacts, "id") : [],
});

const getResourceUrl = (url: string): string => {
  const apiServiceUrl = getApiServiceUrl(url);
  const baseUrl = (apiServiceUrl as AfpUrls).v2 ?? apiServiceUrl;
  return `${baseUrl}${url}`;
};

const mapToViewerResource = (resource: EventResource, eventId: string): EventResourceView => ({
  id: resource.id,
  category: resource.category,
  resourceUrlAndHeader: {
    url: getResourceUrl(`/events/${eventId}/resources/${resource.id}`),
    httpHeaders: {
      clientApp: "ic",
    },
    withCredentials: false,
  },
  fileName: resource.fileName,
});

import { ApiRepository, ExportFile } from "@ic-anywhere/ic-dal";
import {
  EventDto,
  EventFeedbackDTO,
  GetInvitationsDto,
  MyEventInvitationsDto,
  MyInvitationDto,
  SlotsDto,
} from "api/events/event.types";
import { mapMyEventInvitationToEventItem } from "api/events/event.mapper";
import { map, uniqBy } from "lodash";
import { BookingWriteModel, EventItem } from "common/event/event.types";
import { mapToMinusOneYearIsoDate } from "utils/dateUtils";

export const EVENTS_URL = "/2.0/events";

type UpdateInvitationResponse = {
  invitationId: string;
  canAccessEventWebsite: boolean;
};

type CreateBookingResponse = {
  bookingId: string;
};

const filterOptions = { startDate: mapToMinusOneYearIsoDate(new Date()) };

export const getMyEvents = async (repo: ApiRepository): Promise<EventItem[]> => {
  const eventsResponse = await repo.get<MyEventInvitationsDto>(`${EVENTS_URL}/me`, filterOptions);

  return uniqBy(map(eventsResponse.data?.events, mapMyEventInvitationToEventItem), (x) => x.id);
};

export const getEventFeedbacks = async (repo: ApiRepository, eventId: string): Promise<EventFeedbackDTO[]> => {
  const resp = await repo.get<{ feedbacks: EventFeedbackDTO[] }>(`${EVENTS_URL}/${eventId}/feedbacks`, {
    online: true,
  });

  return resp.data?.feedbacks;
};

export const downloadInvestorPdf = (
  repository: ApiRepository,
  eventId: string,
  resourceId: string
): Promise<ExportFile> => repository.download(`${EVENTS_URL}/${eventId}/resources/${resourceId}`, {});

export const getMyInvitations = (repo: ApiRepository, eventId: string): Promise<MyInvitationDto> =>
  repo.get<MyInvitationDto>(`${EVENTS_URL}/${eventId}/invitations/me`).then((resp) => resp.data);

export const getEventById = (repo: ApiRepository, eventId: string): Promise<EventDto> =>
  repo.get<EventDto>(`${EVENTS_URL}/${eventId}`).then((resp) => resp.data);

export const getEventSlotsById = (repo: ApiRepository, eventId: string): Promise<SlotsDto> =>
  repo.get<SlotsDto>(`${EVENTS_URL}/${eventId}/slots`).then((resp) => resp.data);

export const declineEventInvitation = (repo: ApiRepository, eventId: string): Promise<string> =>
  repo
    .put<void, UpdateInvitationResponse>(`${EVENTS_URL}/${eventId}/invitations/refusal`, undefined)
    .then((resp) => resp.data.invitationId);

export const createBooking = (
  repo: ApiRepository,
  eventId: string,
  slotId: string,
  booking: BookingWriteModel
): Promise<string> =>
  repo
    .post<BookingWriteModel, CreateBookingResponse>(`${EVENTS_URL}/${eventId}/slots/${slotId}/bookings`, booking)
    .then((resp) => resp.data.bookingId);

export const updateBooking = (
  repo: ApiRepository,
  eventId: string,
  slotId: string,
  { id, ...booking }: BookingWriteModel
): Promise<void> =>
  repo
    .put<BookingWriteModel, {}>(`${EVENTS_URL}/${eventId}/slots/${slotId}/bookings/${id}`, booking)
    .then((resp) => undefined);

export const getInvitations = (
  repo: ApiRepository,
  eventId: string,
  params: {
    page: number;
    statuses?: string;
  } = { page: 0 }
): Promise<GetInvitationsDto> =>
  repo.get<GetInvitationsDto>(`${EVENTS_URL}/${eventId}/invitations`, params).then((resp) => resp.data);

import * as React from "react";

export interface ResponsiveProps {
  children: React.ReactNode;
  mobileChildren?: React.ReactNode;
  mobileClassNames?: string;
  classNames?: string;
}

const Responsive: React.FunctionComponent<ResponsiveProps> = ({
  children,
  mobileChildren = children,
  classNames = "",
  mobileClassNames = classNames,
}) => (
  <>
    <div className={`line-height-1 d-sm-block d-md-none ${mobileClassNames}`}>{mobileChildren}</div>
    <div className={`line-height-1 d-none d-md-block d-lg-block ${classNames}`}>{children}</div>
  </>
);

export default Responsive;

import { RegistrationActions, Slot } from "containers/event/event.types";
import { BookingDetail } from "api/events/event.types";
import {
  BookingModificationAction,
  BookingStatus,
  BookingUpdateModel,
  InvitationStatus,
} from "common/event/event.types";
import { chain, every, filter, get } from "lodash";
import {
  formatToDDMMM,
  formatToYYYY,
  mapFromIsoDateFormat,
  mapFromRawDateFormat,
  mapToIsoDateFormat,
} from "utils/dateUtils";

export const mapBookingDetailsToModification = (
  bookingDetails: BookingDetail,
  action: RegistrationActions,
  currentUser: string
): BookingUpdateModel => {
  const status =
    action === RegistrationActions.Accept
      ? BookingStatus.Booked
      : bookingDetails.booking && bookingDetails.booking.status === BookingStatus.Booked
      ? BookingStatus.Cancelled
      : BookingStatus.Declined;
  const booking = { status, bookedByContactId: currentUser, inviteeContactId: currentUser };
  return bookingDetails.booking
    ? {
        action: BookingModificationAction.Update,
        booking,
        slotId: bookingDetails.slot.id,
        bookingId: bookingDetails.booking.id,
      }
    : { action: BookingModificationAction.Create, booking, slotId: bookingDetails.slot.id };
};

export const isDisplayable = (bookingDetails: BookingDetail): boolean =>
  isSlotEditable(bookingDetails) || (bookingDetails.booking && bookingDetails.booking.status === BookingStatus.Booked);

export const isSlotEditable = (bookingDetails: BookingDetail): boolean =>
  bookingDetails.slot.online === true ||
  (bookingDetails.booking && bookingDetails.booking.status === BookingStatus.NoShow);

export interface GroupedSlot {
  startDateIso: string;
  bookingStatus: BookingStatus;
  slot: Slot;
}

export interface GroupedSlots<T extends GroupedSlot> {
  startDateIso: string;
  startDateDayMonth: string;
  startDateYear: string;
  slotsOfDay: T[];
}

export const groupSlots = <T extends object>(
  bookingDetails: BookingDetail[],
  additionalMapper: (b: BookingDetail) => T
): Array<GroupedSlots<GroupedSlot & T>> =>
  chain(bookingDetails)
    .map((x) => ({
      bookingStatus: get(x, "booking.status", BookingStatus.Pending),
      startDateIso: mapToIsoDateFormat(mapFromRawDateFormat(x.slot.startDate)),
      slot: x.slot,
      ...(additionalMapper(x) as any),
    }))
    .groupBy((x) => x.startDateIso)
    .map((slotsOfDay, startDateIso) => ({
      startDateIso,
      startDateDayMonth: formatToDDMMM(mapFromIsoDateFormat(startDateIso)),
      startDateYear: formatToYYYY(mapFromIsoDateFormat(startDateIso)),
      slotsOfDay,
    }))
    .orderBy(({ startDateIso }) => startDateIso)
    .value();

type InvitationConfig = {
  currentBookings: BookingDetail[];
  isOneSlot: boolean;
  bookingEditableSlots: BookingDetail[];
  bookedSlots: Slot[];
  bookingPendingSlots: BookingDetail[];
  showDeclineInvitation: boolean;
  invitationDeclined: boolean;
};
export const getInvitationConfiguration = (
  bookings: BookingDetail[],
  invitationStatus: InvitationStatus
): InvitationConfig => {
  const bookingDetails: BookingDetail[] = filter(bookings, (b) => isDisplayable(b)); // editable or already booked
  const bookingEditableSlots: BookingDetail[] = filter(bookingDetails, (b) => isSlotEditable(b));
  const isOneSlot: boolean = bookingDetails.length === 1;
  const invitationDeclined =
    invitationStatus === InvitationStatus.DeclinedNotInterested ||
    invitationStatus === InvitationStatus.DeclinedNotAvailable;

  const bookedSlots: Slot[] = filter(
    bookingDetails,
    ({ booking }) => booking && booking.status === BookingStatus.Booked
  ).map((b) => b.slot);
  const firstRegistration = every(bookingDetails, (x) => x.booking == null);

  // If invitation status declined (all declined), there isn't any pending slots
  const bookingPendingSlots: BookingDetail[] = invitationDeclined
    ? []
    : filter(bookingEditableSlots, ({ booking }) => !booking);

  const showDeclineInvitation = firstRegistration && !isOneSlot && !invitationDeclined;

  return {
    currentBookings: bookingDetails,
    bookingEditableSlots,
    isOneSlot,
    bookedSlots,
    showDeclineInvitation,
    bookingPendingSlots,
    invitationDeclined,
  };
};

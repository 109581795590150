import { useAtom } from "jotai";
import { ContactWidget } from "@ic-anywhere/ic-dal";
import { useModal } from "@ic-anywhere/ic-components";
import { navigateAsAtom, NavigateAsState } from "../../state/authentAtoms";
import { useFetchUserPermissionQuery } from "api/users/users.queries";
import { impersonateAllPermission } from "api/users/users.api";
import { useHistory } from "react-router";
import { queryClient } from "components/App/App";

type UseNavigateAsOutput = {
  navigateAs: NavigateAsState;
  handleNavigateAsChanged: (contact: ContactWidget | null) => void;
  navigateAsModal: { close: () => void; isOpen: boolean; open: () => void };
  canNavigateAs: boolean;
};

export function useNavigateAs(): UseNavigateAsOutput {
  const [navigateAs, setNavigateAs] = useAtom(navigateAsAtom);
  const [isOpen, open, close] = useModal(false);

  const { data: permissions } = useFetchUserPermissionQuery();
  const history = useHistory();

  const handleNavigateAsChanged = async (contact: ContactWidget | null) => {
    const state: NavigateAsState = contact ? { id: contact.id, name: contact.fullName, email: contact.email } : null;
    setNavigateAs(state);
    close();
    // changing navigateAs should refresh the whole app
    history.go(0);
    await queryClient.refetchQueries();
  };

  return {
    navigateAs,
    handleNavigateAsChanged,
    navigateAsModal: { close, isOpen, open },
    canNavigateAs: permissions?.includes(impersonateAllPermission),
  };
}

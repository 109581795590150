import * as React from "react";
import { EVENT_CATEGORY, TagFilter } from "containers/event/event.types";
import * as styles from "components/Event/Dashboard/TagsFilter/TagsFilter.scss";
import { filter, map } from "lodash";

const CURSOR_POINTER_CLASS = "cursor-pointer";

type TagGroupProps = {
  title: string;
  onFilter: (filterId: EVENT_CATEGORY) => void;
  tagFilters: TagFilter[];
};

const TagGroupToRename: React.FC<TagGroupProps> = ({ title, tagFilters, onFilter }) => {
  return (
    <div>
      <div className="text-secondary mb-1">{title}</div>
      {map(
        filter(tagFilters, (tf) => tf.id !== EVENT_CATEGORY.CLEAR),
        (tagFilter) => {
          return (
            <button
              key={tagFilter.id}
              id={tagFilter.id.toString()}
              className={
                tagFilter.enabled
                  ? `btn btn-toggle-${tagFilter.color} mb-1 mr-1 ${styles[CURSOR_POINTER_CLASS]} active`
                  : `btn btn-toggle-${tagFilter.color} mb-1 mr-1 ${styles[CURSOR_POINTER_CLASS]}`
              }
              onClick={() => onFilter(tagFilter.id)}
              data-category={tagFilter.category}
            >
              {tagFilter.name}
            </button>
          );
        }
      )}
    </div>
  );
};

export default TagGroupToRename;

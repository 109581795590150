import * as React from "react";
import { map } from "lodash";
import { EventsList } from "components/Event/Dashboard/EventsList/EventsList";
import { Redirect, useLocation } from "react-router";
import { ROUTES } from "common/routes";
import { useEventsDashboard } from "components/Event/Dashboard/utils/useEventsDashboard";
import { buildUrl } from "utils/generateUrl";
import { useTrackOnDisplayPage } from "../../common/event.tracking";
import { features } from "utils/logger/features";

const useSearchParams = (...params: string[]) => {
  const queryString = useLocation().search;
  const queryable = new URLSearchParams(queryString);
  const getParam = (name: string) => queryable.get(name);
  return map(params, getParam);
};

const EventDashboard: React.FC = () => {
  const [eventId, urlAction] = useSearchParams("id", "action");
  const { isFetching, events } = useEventsDashboard();
  useTrackOnDisplayPage(features.welcomePageDisplay);
  const redirectAction = !!eventId;

  if (redirectAction) {
    const action = urlAction === "decline" ? "decline" : "register";

    return <Redirect to={buildUrl(ROUTES.EVENT_INVITATION_ROADSHOW_ACTION, { id: eventId, action })} />;
  }
  return <EventsList events={events} isFetching={isFetching} />;
};

export default EventDashboard;

import { Listener, useRefListeners } from "@ic-anywhere/ic-components";
import NavigateAsModal from "components/navigateAs/NavigateAsModal";
import { useNavigateAs } from "components/navigateAs/useNavigateAs";
import * as React from "react";

interface SgHeaderProps {
  currentLanguage?: string;
  environment?: string;
}
const acReadyEvent = "sgwt-account-center--ready";
const acNavigateAsLinkClickedEvent = "sgwt-account-center--navigate-as-link-clicked";
const acStopNavigationAsEvent = "sgwt-account-center--stop-navigation-as";

type AccountCenterHtmlElement = HTMLElement & { setNavigateAsUser?: (data: any) => void };

export const SgHeader: React.FunctionComponent<SgHeaderProps> = ({ currentLanguage, environment }) => {
  const { canNavigateAs, navigateAs, handleNavigateAsChanged, navigateAsModal } = useNavigateAs();

  const [isAccountCenterReady, setIsAccountCenterReady] = React.useState<boolean>(false);

  const listeners: Listener[] = [
    { eventName: acStopNavigationAsEvent, eventHandler: async () => handleNavigateAsChanged(null) },
    { eventName: acNavigateAsLinkClickedEvent, eventHandler: navigateAsModal.open },
  ];

  const accountCenterRef = useRefListeners(listeners);

  // effects

  React.useEffect(() => {
    const handleIsReady = () => {
      const widget: any = document.querySelector("sgwt-account-center");
      if (widget) {
        if (typeof widget.setNavigateAsUser === "undefined" || typeof widget.stopNavigationAs === "undefined") {
          const handleWidgetReady = () => {
            setTimeout(() => setIsAccountCenterReady(true));
            widget.removeEventListener(acReadyEvent, handleWidgetReady);
          };
          widget.addEventListener(acReadyEvent, handleWidgetReady);
        } else {
          setTimeout(() => setIsAccountCenterReady(true));
        }
      }
    };

    handleIsReady();
  }, []);

  React.useEffect(() => {
    const currentRef: AccountCenterHtmlElement = accountCenterRef.current;
    if (isAccountCenterReady && currentRef) {
      currentRef.setNavigateAsUser(navigateAs);
    }
  }, [accountCenterRef, navigateAs, isAccountCenterReady]);

  return (
    <div>
      <NavigateAsModal
        contactId={navigateAs?.id}
        onClose={navigateAsModal.close}
        show={navigateAsModal.isOpen}
        onContactSelected={handleNavigateAsChanged}
      />
      <div className="d-flex">
        <sgwt-account-center
          ref={accountCenterRef}
          id="ic-super-header"
          environment={environment}
          mode="sg-markets"
          language={currentLanguage}
          authentication="sg-connect-v2"
          navigate-as={canNavigateAs ? "true" : undefined}
        />
      </div>
    </div>
  );
};

import * as React from "react";
import { Button, Modal } from "@sgbs-ui/core";
import { useModal } from "@ic-anywhere/ic-components";
import { ContactInfoWithLink } from "../ContactInfoWithLink";
import { isEmpty } from "lodash";

interface Props {
  friendlyName: string;
  jobTitle?: string;
  businessPhone?: string;
  mobilePhone?: string;
  mainEmail?: string;
  contactInfoModal?: boolean;
}

const ContactItem: React.FC<Props> = ({
  friendlyName,
  jobTitle,
  businessPhone,
  mobilePhone,
  mainEmail,
  contactInfoModal = true,
}) => {
  const [showModal, openModal, closeModal] = useModal();
  return (
    <>
      <div className="row col-12 mt-2 mb-md-2">
        <div className="col-12 d-lg-none text-primary d-flex align-items-center line-height-lg justify-content-between">
          {contactInfoModal ? (
            <>
              <div>
                <span className="d-block">{friendlyName}</span>
                <span>{jobTitle}</span>
              </div>
              {(!isEmpty(mobilePhone) || !isEmpty(businessPhone) || !isEmpty(mainEmail)) && (
                <Button
                  icon="person"
                  onClick={() => openModal()}
                  btnType="discreet"
                  color="secondary"
                  size="md"
                  className="rounded-circle ml-2"
                />
              )}
            </>
          ) : (
            <>
              <div>{friendlyName}</div>
              <ContactInfoWithLink mainEmail={mainEmail} displayIcon={true} />
            </>
          )}
        </div>

        <div className="col-lg-4 d-none d-lg-block text-primary">
          <span className="d-block">{friendlyName}</span>
          <span>{jobTitle}</span>
        </div>
        <div className="d-none d-lg-block col-lg-8">
          <ContactInfoWithLink businessPhone={businessPhone} mobilePhone={mobilePhone} mainEmail={mainEmail} />
        </div>
      </div>
      <Modal
        show={showModal}
        size="md"
        backdropClose={true}
        isConfirmValid={false}
        onClose={closeModal}
        title="Contact Information"
        modalDialogClassName="w-100 m-auto"
      >
        <div className="font-weight-bold mb-2">{friendlyName}</div>
        <ContactInfoWithLink businessPhone={businessPhone} mobilePhone={mobilePhone} mainEmail={mainEmail} />
      </Modal>
    </>
  );
};

export default ContactItem;

import * as React from "react";
import { classNames } from "@ic-anywhere/ic-utils";

type CardProps = {
  title?: string;
  className?: string;
};

export const Card: React.FC<CardProps> = ({ title, children, className }) => (
  <div className={classNames("card mb-4", className)}>
    <div className="card-body p-4">
      {title && <h2 className="card-title mb-4">{title}</h2>}
      {children}
    </div>
  </div>
);

import { atom } from "jotai";
import { EVENT_CATEGORY, TagFilter } from "containers/event/event.types";
import { includes, map, some } from "lodash";
import { toggleItemInArray } from "utils/arrayUtils";
import { clearFilter, corporateTagFilters } from "components/Event/Dashboard/utils/filters.constants";

const activeFiltersIdsAtom = atom<EVENT_CATEGORY[]>([]);
export const searchTermAtom = atom<string>("");

const mapActiveIdsToFilters = (all: TagFilter[], activeIds: EVENT_CATEGORY[]): TagFilter[] =>
  map(all, (tagFilter) => ({
    ...tagFilter,
    enabled: includes(activeIds, tagFilter.id),
  }));

export const activeFiltersAtom = atom(
  (get) => mapActiveIdsToFilters(corporateTagFilters, get(activeFiltersIdsAtom)),
  (get, set, id: EVENT_CATEGORY) => {
    set(activeFiltersIdsAtom, toggleItemInArray(get(activeFiltersIdsAtom), id));
  }
);

export const clearFiltersAtom = atom(
  (get) => ({
    ...clearFilter,
    visible: some(get(activeFiltersIdsAtom)),
  }),
  (get, set) => set(activeFiltersIdsAtom, [])
);

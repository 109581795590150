import { Slot } from "containers/event/event.types";
import * as React from "react";
import {
  defaultTimeFormat,
  displayDate,
  luxonDateFormatIso8601,
  mapDateWithTimeZone,
  mapIanaToShortLabel,
  parseDate,
} from "@ic-anywhere/ic-utils";
import { chain, every, filter, orderBy } from "lodash";
import { Address, BookingStatus, VirtualLocation as VirtualLocationType } from "common/event/event.types";
import { TimeZoneInfo } from "@ic-anywhere/ic-components";
import { Badge, SGBSColor } from "@sgbs-ui/core";
import { SlotLocationType } from "components/EventInvitation/EditableSlotItem/SlotLocationType";

export const isOneDateForAllSlot = (slots: Slot[]): boolean =>
  slots.length === 1 ||
  every(slots, ({ startDate, timeZone }) =>
    parseDate(startDate, timeZone).hasSame(parseDate(slots[0].startDate, slots[0].timeZone), "day")
  );

export const getReorderedSlotsByDateWithTimeZone = (slots: Slot[]): { date: string; slots: Slot[] }[] =>
  chain(slots)
    .groupBy((s) => mapDateWithTimeZone(s.startDate, luxonDateFormatIso8601, s.timeZone))
    .toPairs()
    .map(([k, v]) => ({
      date: k,
      slots: orderBy(v, (slot) => mapDateWithTimeZone(slot.startDate, defaultTimeFormat, slot.timeZone)),
    }))
    .value();

const timezoneWithoutTooltip = (startDate: string, endDate: string, timeZone?: string): string => {
  const startDateTime = displayDate(
    timeZone ? parseDate(startDate).setZone(timeZone) : parseDate(startDate),
    defaultTimeFormat
  );
  const endDateTime = displayDate(
    timeZone ? parseDate(endDate).setZone(timeZone) : parseDate(endDate),
    defaultTimeFormat
  );
  const city = timeZone ? mapIanaToShortLabel(timeZone) : null;
  if (!timeZone) {
    return `${startDateTime} - ${endDateTime}`;
  }
  return `${startDateTime} - ${endDateTime} (${city})`;
};

export const renderInfoOfSlotInProgram = (
  slot: Slot,
  options?: {
    className?: string;
    showCapacity?: boolean;
    badge?: { color: SGBSColor; text: string };
    slotTypeLocation?: { isSlotFull: boolean; address?: Address; virtualLocation?: VirtualLocationType };
  }
): JSX.Element => {
  const { timeZone, startDate, endDate, id, type, bookings, capacity } = slot;
  const displaySlotCapacity = `(${filter(bookings, (b) => b.status === BookingStatus.Booked).length}/${capacity})`;
  return (
    <>
      <li className={`${options?.className ?? ""} mt-2 d-flex d-flex align-items-center line-height-lg mb-1`}>
        <div className="d-lg-none">{timezoneWithoutTooltip(startDate, endDate, timeZone)}</div>
        <div className="d-lg-flex align-items-center line-height-lg d-none">
          <div className="d-flex align-items-center">
            <TimeZoneInfo
              period={{ startDate: parseDate(startDate), endDate: parseDate(endDate) }}
              timeZone={timeZone}
              id={id}
            />
            <span className="pl-1">{`| ${type} ${options?.showCapacity ? displaySlotCapacity : ""}`}</span>
          </div>
          {options?.badge && (
            <Badge
              text={options.badge.text}
              color={options.badge.color}
              className={`badge-flat-${options.badge.color} d-none d-lg-flex ml-2`}
              badgeType="discreet"
              square={true}
            />
          )}
          {options?.slotTypeLocation && <SlotLocationType {...options.slotTypeLocation} />}
        </div>
      </li>

      <li className={`${options?.className ?? ""} d-lg-none d-flex align-items-center line-height-lg mb-1`}>
        {`${type} ${options?.showCapacity ? displaySlotCapacity : ""}`}
      </li>
      {options?.slotTypeLocation && (
        <li className={`${options?.className ?? ""} d-lg-none d-flex align-items-center line-height-lg mb-1`}>
          <SlotLocationType {...options.slotTypeLocation} />
        </li>
      )}
    </>
  );
};

import { BookingModificationAction, BookingStatus, BookingUpdateModel } from "common/event/event.types";
import { BookingEditModel } from "components/EventInvitation/EditableSlotItem/EditableSlotItemBody";
import { BookingDetail } from "api/events/event.types";
import { map } from "lodash";

export const getOneSlotBookingModel = (
  bookingId: string,
  slotId: string,
  userId: string,
  status: BookingStatus.Booked | BookingStatus.Declined
): BookingUpdateModel[] => [
  {
    slotId,
    action: BookingModificationAction.Create,
    booking: {
      id: bookingId,
      status,
      bookedByContactId: userId,
      inviteeContactId: userId,
    },
  },
];

export const updateOneSlotBookingModel = (
  bookingId: string,
  slotId: string,
  userId: string,
  updatedStatus: BookingStatus.Booked | BookingStatus.Declined
): BookingUpdateModel[] => [
  {
    slotId,
    action: BookingModificationAction.Update,
    booking: {
      id: bookingId,
      status: updatedStatus,
      bookedByContactId: userId,
      inviteeContactId: userId,
    },
  },
];

export const mapToUpdateModel = (bookingsToModify: BookingEditModel[], userId: string): BookingUpdateModel[] =>
  bookingsToModify.map((b) => {
    return b.booking
      ? {
          slotId: b.slot.id,
          bookingId: b.booking.id,
          action: BookingModificationAction.Update,
          booking: {
            id: b.booking.id,
            status:
              b.checked === true
                ? BookingStatus.Booked
                : b.booking.status === BookingStatus.Booked
                ? BookingStatus.Cancelled
                : BookingStatus.Declined,
            bookedByContactId: userId,
            inviteeContactId: userId,
          },
        }
      : {
          slotId: b.slot.id,
          action: BookingModificationAction.Create,
          booking: {
            status: b.checked === true ? BookingStatus.Booked : BookingStatus.Declined,
            bookedByContactId: userId,
            inviteeContactId: userId,
          },
        };
  });

export const mapDetailToAllDeclinedModel = (bookings: BookingDetail[], userId: string): BookingUpdateModel[] =>
  map(bookings, (b) => {
    return b.booking
      ? {
          slotId: b.slot.id,
          bookingId: b.booking.id,
          action: BookingModificationAction.Update,
          booking: {
            id: b.booking.id,
            status: BookingStatus.Declined,
            bookedByContactId: userId,
            inviteeContactId: userId,
          },
        }
      : {
          slotId: b.slot.id,
          action: BookingModificationAction.Create,
          booking: {
            status: BookingStatus.Declined,
            bookedByContactId: userId,
            inviteeContactId: userId,
          },
        };
  });

import { EVENT_CATEGORY_FILTERS, TagFilter } from "containers/event/event.types";
import { EventItem } from "common/event/event.types";
import { chain, head, isEmpty, last, partition, sortBy, uniqBy, uniqueId } from "lodash";
import * as moment from "moment";
import { isDateBeforeToday } from "utils/dateUtils";

export const getOrderedEvents = (events: EventItem[]): EventItem[] => {
  const [nearest, past] = partition(events, ({ endDate }) => !isDateBeforeToday(endDate));
  const nearestSorted = sortBy(nearest, ({ startDate }) => moment(startDate).valueOf());
  const pastSorted = sortBy(past, ({ startDate }) => -moment(startDate).valueOf());
  return uniqBy([...nearestSorted, ...pastSorted], (event) => event.id);
};

export const getEventsFilteredByTagFilters = (events: EventItem[], tagFilters: TagFilter[]): EventItem[] => {
  const filtersToApply = tagFilters.filter((filter) => {
    return filter.enabled;
  });
  return filtersToApply.length > 0 ? filterEvents(events, filtersToApply) : events;
};

const filterEvents = (events: EventItem[], filtersToApply: TagFilter[]): EventItem[] => {
  const eventsFilteredSet = [];
  filtersToApply.forEach((filter) => {
    eventsFilteredSet.push(
      ...events.filter((event) => {
        return EVENT_CATEGORY_FILTERS[filter.name](filter, event);
      })
    );
  });

  return getOrderedEvents(eventsFilteredSet);
};

export const eventItemsGroupByGroupName = (eventItems: EventItem[]): EventItem[] => {
  const uniqueEventItems = eventItems ? uniqBy(eventItems, "id") : [];
  const [simpleWithInvitee, grouped] = partition(
    uniqueEventItems,
    (e) => isEmpty(e.groupName) || e.userRole === "Invitee"
  );
  const eventItemsBundle = chain(grouped).groupBy("groupName").map(eventItemsBundledToEventItem).value();

  return [...simpleWithInvitee, ...eventItemsBundle];
};

const eventItemsBundledToEventItem = (eventItems: EventItem[]): EventItem => {
  eventItems = sortBy(eventItems, (e) => moment(e.startDate).valueOf());
  const { groupName, type, category, userRole, startDate } = head(eventItems);
  const { endDate } = last(eventItems);
  return {
    id: uniqueId(),
    type,
    category,
    groupName,
    name: groupName,
    address: [...eventItems.map((eventItem) => eventItem.city || eventItem.address)].filter(Boolean).join(", "),
    startDate,
    endDate,
    userRole,
    bundle: true,
  };
};

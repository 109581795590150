import { useQuery, UseQueryResult } from "@tanstack/react-query";
import useCrmRepository from "api/useCrmRepository";
import { tenMinutesInMs } from "@ic-anywhere/ic-dal";
import { fetchUserPermission } from "api/users/users.api";
import { useAtom } from "jotai";
import { UserInfo } from "api/userProfile.types";
import { userInfoAtom } from "../../state/userProfileAtoms";

export function useFetchUserPermissionQuery(): UseQueryResult<string[]> {
  const repo = useCrmRepository({ disableImpersonating: true });
  const [userInfo] = useAtom<UserInfo>(userInfoAtom);

  const query = async () => {
    const result = await fetchUserPermission(repo, userInfo.userId);
    return result.permissions ?? [];
  };

  return useQuery({ queryKey: ["fetchUserPermissionQuery"], queryFn: query, enabled: userInfo.isIcUser, cacheTime: tenMinutesInMs });
}

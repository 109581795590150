import { ApiRepository, getLoggerByEnv, RepositoryConfig } from "@ic-anywhere/ic-dal";
import { useAtom } from "jotai";
import { navigateAsAtom, tokenAtom } from "../state/authentAtoms";

type UseCrmRepositoryOptions = {
  // if true, don't include impersonatingAs http header in requests
  disableImpersonating: boolean;
};

// Builds a [RepositoryConfig] from app State
export const useRepositoryConfig = (options: UseCrmRepositoryOptions | undefined): RepositoryConfig => {
  const [token] = useAtom(tokenAtom);
  const [navigateAs] = useAtom(navigateAsAtom);
  const useImpersonating = !options?.disableImpersonating ?? true;
  return {
    token,
    impersonatingAs: useImpersonating ? navigateAs?.email : undefined,
    logger: getLoggerByEnv("Events Website" as any),
  };
};

const useCrmRepository = (options?: UseCrmRepositoryOptions): ApiRepository => {
  const config = useRepositoryConfig(options);
  return ApiRepository.Crm(config);
};

export default useCrmRepository;

import { useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query";
import useCrmRepository from "api/useCrmRepository";
import { getMyEvents } from "api/events/event.api";
import { EventItem } from "common/event/event.types";
import { twoMinutesInMs } from "@ic-anywhere/ic-dal";
import { MergedQueryResult } from "queries/queries.typings";
import { sortBy } from "lodash";

export const getMyEventsQueryKey = ["getMyEvents"];

export const useGetMyEventsQuery = (): UseQueryResult<EventItem[]> => {
  const repo = useCrmRepository();

  return useQuery<EventItem[]>({
    queryKey: getMyEventsQueryKey,
    queryFn: () => getMyEvents(repo),
    enabled: true,
    cacheTime: twoMinutesInMs,
  });
};

export const usePrefectMyEventsQuery = (): (() => void) => {
  const repo = useCrmRepository();
  const client = useQueryClient();

  return () => client.prefetchQuery(getMyEventsQueryKey, () => getMyEvents(repo)).then();
};

export const useGetEventsOfGroup = (groupName: string): MergedQueryResult<EventItem[]> => {
  const repo = useCrmRepository();

  const {
    data = [],
    isFetching,
    isLoading,
    isError,
    isSuccess,
  } = useQuery<EventItem[]>({
    queryKey: getMyEventsQueryKey,
    queryFn: () => getMyEvents(repo),
    enabled: true,
    cacheTime: twoMinutesInMs,
  });

  const filtered = sortBy(data, "startDate").filter((eventItem) => eventItem.groupName === groupName);

  return { data: filtered, isFetching, isError, isSuccess, isLoading };
};

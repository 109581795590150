import * as React from "react";
import { uniqueId } from "lodash";
import { Loader } from "@sgbs-ui/core";

type PdfFetchInfo = {
  url: string;
  httpHeaders?: {
    authorization: string;
  };
  withCredentials?: boolean;
};

export interface PdfReaderProps {
  id?: string;
  pdfObject?: PdfFetchInfo;
  importPdfjsLib?: boolean;
  fixedCommands?: boolean;
  hideCommands?: boolean;
  hidePrintCommand?: boolean;
  hideFullScreenCommand?: boolean;
  hideRotationCommands?: boolean;
  hideZoomCommands?: boolean;
  canvasWidth?: number;
  canvasHeight?: number;
  canvasMaxHeight?: number;
  displayFullDocument?: boolean;
  i18n?: object;
  ref?: any;
  onPdfReaderReady?: (event: any) => void;
  onPdfReaderPdfLoaded?: (event: any) => void;
  onPdfReaderPageLoaded?: (event: any) => void;
  onPdfReaderPageRendered?: (event: any) => void;
}

export class PdfReader extends React.Component<PdfReaderProps> {
  private readonly pdfReaderId: string;
  private pdfReader: any;

  constructor(props: PdfReaderProps) {
    super(props);
    this.pdfReaderId = uniqueId("PdfReaderId");
  }

  public componentDidMount(): void {
    this.pdfReader = document.getElementById(this.pdfReaderId);

    if (this.pdfReader) {
      this.pdfReader.addEventListener("sgwt-pdf-reader--ready", this.onPdfReaderReady);
      this.pdfReader.addEventListener("sgwt-pdf-reader--pdf-loaded", this.onPdfReaderPdfLoaded);
      this.pdfReader.addEventListener("sgwt-pdf-reader--page-loaded", this.onPdfReaderPageLoaded);
      this.pdfReader.addEventListener("sgwt-pdf-reader--page-rendered", this.onPdfReaderPageRendered);
    }
  }

  public render() {
    const {
      id,
      pdfObject,
      canvasHeight,
      canvasMaxHeight,
      canvasWidth,
      displayFullDocument,
      fixedCommands,
      hideCommands,
      hideFullScreenCommand,
      hidePrintCommand,
      hideRotationCommands,
      hideZoomCommands,
      i18n,
      importPdfjsLib,
      ref,
    } = this.props;

    return !pdfObject ? (
      <Loader isVisible={true} />
    ) : (
      WidgetPdfReader({
        id,
        "pdf-url": JSON.stringify(pdfObject),
        "canvas-height": canvasHeight,
        "canvas-max-height": canvasMaxHeight,
        "canvas-width": canvasWidth,
        "display-full-document": displayFullDocument,
        "fixed-commands": fixedCommands,
        "hide-commands": hideCommands,
        "hide-full-screen-command": hideFullScreenCommand,
        "hide-print-command": hidePrintCommand,
        "hide-rotation-commands": hideRotationCommands,
        "hide-zoom-commands": hideZoomCommands,
        i18n,
        "import-pdfjs-lib": importPdfjsLib,
        ref,
      })
    );
  }

  public firstPage(): void {
    this.pdfReader.firstPage();
  }

  public previousPage(): void {
    this.pdfReader.previousPage();
  }

  public nextPage(): void {
    this.pdfReader.nextPage();
  }

  public lastPage(): void {
    this.pdfReader.lastPage();
  }

  public zoomIn(): void {
    this.pdfReader.zoomIn();
  }

  public zoomOut(): void {
    this.pdfReader.zoomOut();
  }

  public zoomReset(): void {
    this.pdfReader.zoomReset();
  }

  public rotateLeft(): void {
    this.pdfReader.rotateLeft();
  }

  public rotateRight(): void {
    this.pdfReader.rotateRight();
  }

  public fullScreen(): void {
    this.pdfReader.fullScreen();
  }

  public print(): void {
    this.pdfReader.print();
  }

  private readonly onPdfReaderReady = (event: any) => {
    this.props.onPdfReaderReady(event);
  };

  private readonly onPdfReaderPdfLoaded = (event: any) => {
    this.props.onPdfReaderPdfLoaded(event);
  };

  private readonly onPdfReaderPageLoaded = (event: any) => {
    this.props.onPdfReaderPageLoaded(event);
  };

  private readonly onPdfReaderPageRendered = (event: any) => {
    this.props.onPdfReaderPageRendered(event);
  };
}

export const WidgetPdfReader = (props) => <sgwt-pdf-reader {...props} />;

import { EVENT_CATEGORY } from "containers/event/event.types";
import { INVITATION_STATUS } from "api/events/event.types";
import * as moment from "moment";

export const clearFilter = {
  id: EVENT_CATEGORY.CLEAR,
  name: "Clear",
  color: "secondary",
  enabled: false,
  visible: false,
};

export const corporateTagFilters = [
  {
    id: EVENT_CATEGORY.CORPORATE_ACCESS,
    name: "Corporate access",
    category: "Roadshow",
    excludesTypes: ["Analyst Visit", "Credit Event"],
    types: [],
    color: "azure",
    enabled: false,
    featureLogName: "welcomePageCorporateAccessFilter",
  },
  {
    id: EVENT_CATEGORY.CREDIT_EVENT,
    name: "Credit roadshow",
    category: "Roadshow",
    excludesTypes: ["Analyst Visit"],
    types: ["Credit Event"],
    color: "spicy-mix",
    enabled: false,
    featureLogName: "welcomePageCreditRoadshowFilter",
  },
  {
    id: EVENT_CATEGORY.RESEARCH,
    name: "Research",
    category: "Roadshow",
    subCategory: "LightRoadshow",
    excludesTypes: [],
    types: ["Analyst Visit"],
    color: "faded-jade",
    enabled: false,
    featureLogName: "welcomePageResearchFilter",
  },
  {
    id: EVENT_CATEGORY.BUSINESS,
    name: "Business",
    category: "Event",
    excludesTypes: [],
    types: [],
    color: "primary",
    enabled: false,
    featureLogName: "welcomePageBusinessFilter",
  },
  {
    id: EVENT_CATEGORY.PENDING,
    name: "Pending",
    category: undefined,
    excludesTypes: [],
    types: [],
    invitationStatus: INVITATION_STATUS.PENDING,
    color: "apricot",
    enabled: false,
    featureLogName: "welcomePagePendingFilter",
  },
  {
    id: EVENT_CATEGORY.ATTENDING,
    name: "Attending",
    category: undefined,
    excludesTypes: [],
    types: [],
    invitationStatus: INVITATION_STATUS.ATTENDING,
    color: "puerto-rico",
    enabled: false,
    featureLogName: "welcomePageAttendingFilter",
  },
  {
    id: EVENT_CATEGORY.DECLINED,
    name: "Declined",
    category: undefined,
    excludesTypes: [],
    types: [],
    invitationStatus: INVITATION_STATUS.DECLINED_NOT_AVAILABLE,
    color: "valencia",
    enabled: false,
    featureLogName: "welcomePageDeclinedFilter",
  },
  {
    id: EVENT_CATEGORY.ISSUED,
    name: "Issued",
    endDate: moment().format(" MM MMM YYYY"),
    color: "heather",
    enabled: false,
    featureLogName: "welcomePageIssuedFilter",
  },
];

import { RegistrationActions, RegistrationStatus } from "containers/event/event.types";
import { BookingStatus, InvitationStatus } from "common/event/event.types";
import { SGBSColor } from "@sgbs-ui/core";

export const acceptationMessage =
  "Your participation to this event is confirmed. You can modify you registration at your convenience. Thank you";

export const declinedMessage =
  "Your participation to this event is declined. You can modify you registration at your convenience. Thank you";

export const RegistrationModalColors: { [action: number]: SGBSColor } = {
  [RegistrationActions.Accept]: "success",
  [RegistrationActions.Modify]: "success",
  [RegistrationActions.Decline]: "warning",
  [RegistrationActions.DeclineInvitation]: "warning",
  [RegistrationActions.MoreInfo]: "info",
};

export const InvitationStatusToMessageStatus = {
  [InvitationStatus.Accepted]: RegistrationStatus.Attending,
  [InvitationStatus.Pending]: RegistrationStatus.Pending,
  [InvitationStatus.DeclinedNotAvailable]: RegistrationStatus.Declined,
  [InvitationStatus.DeclinedNotInterested]: RegistrationStatus.Declined,
};

export const RegistrationMessageStatusColors: { [type: string]: SGBSColor } = {
  [RegistrationStatus.Attending]: "success",
  [RegistrationStatus.Declined]: "secondary",
  [RegistrationStatus.Pending]: "warning",
};

export const BookingMessageStatusColors: { [type: number]: SGBSColor } = {
  [BookingStatus.Booked]: "success",
  [BookingStatus.Cancelled]: "secondary",
  [BookingStatus.NoShow]: "secondary",
  [BookingStatus.Declined]: "secondary",
  [BookingStatus.Pending]: "warning",
};

export type BookingMessages = "Pending" | "Attending" | "Declined";

export const BookingMessageStatus: { [type: number]: BookingMessages } = {
  [BookingStatus.Booked]: "Attending",
  [BookingStatus.Cancelled]: "Declined",
  [BookingStatus.NoShow]: "Declined",
  [BookingStatus.Declined]: "Declined",
  [BookingStatus.Pending]: "Pending",
};

import * as React from "react";
import { capitalize, isEmpty } from "lodash";
import { formatTitleHeader } from "utils/formatUtils";
import { Event, InvitationStatus } from "common/event/event.types";
import { formatTitleDate } from "@ic-anywhere/ic-utils";
import { BackButton } from "@ic-anywhere/ic-components";
import { buildUrl } from "utils/generateUrl";
import { ROUTES } from "common/routes";
import { useHistory } from "react-router";
import { Badge } from "@sgbs-ui/core";
import {
  InvitationStatusToMessageStatus,
  RegistrationMessageStatusColors,
} from "components/EventInvitation/Modals/registration.constants";

export type HeaderOptions = {
  navBar?: React.ReactElement;
  event?: Event;
  displayBackButton?: boolean;
  statusInvitation?: InvitationStatus;
};
export interface PageHeader {
  mainTitle?: string;
  headerOptions?: HeaderOptions;
}

const Header: React.FC<PageHeader> = ({ mainTitle, headerOptions }) => {
  const history = useHistory();
  const { navBar, event, displayBackButton = true, statusInvitation } = headerOptions ?? {};
  const backToHome = () => {
    history.push(buildUrl(ROUTES.HOME));
  };
  const city = event?.venue?.address?.city;
  return (
    <div className={`line-height-1 w-100 d-print-none`}>
      <div className="d-flex border-bottom">
        <div className="container justify-content-between">
          {!isEmpty(mainTitle) && (
            <div className={`d-flex align-items-center ${statusInvitation ? "mb-2" : ""}`}>
              {displayBackButton && <BackButton size="md" onClick={backToHome} label="" />}
              <div className="d-flex flex-column ml-md-3">
                {event && (
                  <div className="pt-3 pl-1 text-truncate font-weight-bold mb-2">
                    <span className="text-secondary">
                      {formatTitleDate(event.startDate, event.endDate)}
                      {!isEmpty(city) && ` : ${city}`}
                      {statusInvitation && (
                        <Badge
                          className="ml-3"
                          badgeType="discreet"
                          text={InvitationStatusToMessageStatus[statusInvitation]}
                          square={true}
                          color={RegistrationMessageStatusColors[InvitationStatusToMessageStatus[statusInvitation]]}
                        />
                      )}
                    </span>
                  </div>
                )}
                <h1>{formatTitleHeader(capitalize(mainTitle))}</h1>
              </div>
            </div>
          )}
          {navBar}
        </div>
      </div>
    </div>
  );
};

export default Header;

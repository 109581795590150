import * as React from "react";
import { EventDetail } from "containers/event/event.types";
import { useEventFeedbacks } from "components/Event/Corporate/Tabs/useEventFeedbacks";
import { FeedbackView, Loader } from "@ic-anywhere/ic-components";
import { luxonDateFormatIso8601, toDisplayedDate } from "@ic-anywhere/ic-utils";
import { features } from "utils/logger/features";
import { featureTracking, useTrackOnDisplayPage } from "../../../common/event.tracking";

export const EventCorporateFeedbacksTab: React.VFC<{ eventDetail: EventDetail }> = ({ eventDetail }) => {
  const {
    event: { id: eventId, startDate, endDate, name },
    slots,
  } = eventDetail;
  const { feedbacks = [], isFetching } = useEventFeedbacks(eventId, slots);
  useTrackOnDisplayPage(features.eventDetailsFeedbackDisplay);

  const handleExportFeedback = () => {
    featureTracking(features.eventDetailsFeedbackExport);
    print();
  };
  return (
    <>
      {isFetching ? (
        <Loader isVisible={true} />
      ) : (
        <>
          {eventDetail && feedbacks && (
            <FeedbackView
              feedbacks={feedbacks}
              period={{
                startDate: toDisplayedDate(startDate, luxonDateFormatIso8601),
                endDate: toDisplayedDate(endDate, luxonDateFormatIso8601),
              }}
              eventName={name}
              onClickPrint={handleExportFeedback}
            />
          )}
        </>
      )}
    </>
  );
};

import * as moment from "moment";
import { isEmpty } from "lodash";

const defaultDateFormat = "ll";
const isoDateFormat = "YYYY-MM-DD";
export const DD_MMM = "DD MMM";
export const YYYY = "YYYY";
export const YYYY_M_D_H_MM = "YYYY-M-D-H-mm";
export const DASH = "-";
/***
 * Format a date based on the provided format
 * @param date Date
 * @param {Date} format the format string by default ll
 * @returns {string} formatted string
 */

export const formatDate = (date: Date | string, format = defaultDateFormat): string | undefined =>
  date ? moment(date).format(format) : undefined;

/***
 * Format a date based on the iso format YYYY-MM-DD
 * @param date Date
 * @returns {string} formatted string
 */

export const mapToIsoDateFormat = (date: Date | moment.Moment): string | null =>
  date ? moment(date).format(isoDateFormat) : null;

export const mapFromIsoDateFormat = (dateIsoFormat: string) =>
  dateIsoFormat ? moment(dateIsoFormat, isoDateFormat) : null;

export const mapToMinusOneYearIsoDate = (today: Date): string =>
  mapToIsoDateFormat(moment(today).add(-1, "y").toDate());

/***
 * @param  getTodayAction date factory method
 * Return today date without time
 * @returns {moment.Moment} today date
 */
export const getTodayDate = (getTodayAction: () => moment.Moment = moment): moment.Moment =>
  getTodayAction().startOf("day");

/***
 * Compare dates without time
 * @param date date or date time
 * @param other date or date time
 * @return {boolean}
 */
export const isDatesEqual = (date: Date | moment.Moment, other: Date | moment.Moment) =>
  toDateWithoutTime(date).diff(toDateWithoutTime(other)) === 0;

export const toDateWithoutTime = (date: Date | moment.Moment) => moment(date).startOf("day");

export const isDateBeforeToday = (value: moment.Moment | Date | string): boolean =>
  moment(value).isBefore(moment(), "day");

export const mapFromRawDateFormat = (rawDateString: string) =>
  moment(rawDateString, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);

export const formatToDDMMM = (date: Date | moment.Moment): string => {
  return date ? moment(date).format(DD_MMM) : null;
};

export const formatToYYYY = (date: Date | moment.Moment): string => {
  return date ? moment(date).format(YYYY) : null;
};

// TODO: Review dates management. Make less conversion of dates in pages.
// TODO: Remove obsolete functions after refactoring.
export const formatDateToDDMMM = (date: string): string => {
  return date ? moment(date).format(DD_MMM) : "";
};

export const formatDateToYYYY = (date: string): string => {
  return date ? moment(date, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS).format(YYYY) : "";
};

export const getDate = (stringDate: string): Date => {
  return isEmpty(stringDate) ? new Date() : moment(stringDate).toDate();
};

import * as React from "react";
import { GetBookingErrorStatus } from "queries/useGetBookingDetailsQuery";
import { Event, EventContact } from "api/events/event.types";
import { map } from "lodash";
import { ROUTES } from "common/routes";
import ButtonLink from "components/common/ButtonLink";

type Props = {
  errorStatus: GetBookingErrorStatus;
  event: Event;
};

const getEventOrganizersContacts = (contacts: EventContact[]): JSX.Element => {
  const eventOrg = contacts.length <= 2 ? "the event organiser" : "event organisers";
  const links = map(contacts, (c, idx) => (
    <a key={c.id} className="ml-1 text-secondary" href={`mailto:${c.mainEmail}`}>
      {c.friendlyName}
    </a>
  ));

  return links.reduce((acc, curr, idx) => {
    if (idx === 0) {
      return (
        <>
          {acc}
          {curr}
        </>
      );
    }
    return (
      <>
        {acc},{curr}
      </>
    );
  }, <>{eventOrg}</>);
};

export const NotInvitedErrorPage: React.VFC<Props> = ({ event, errorStatus }) => {
  const titleRule: Record<GetBookingErrorStatus, JSX.Element | string> = {
    all_in_error: "Error",
    invit_error: "This event is private",
    unknown_error: "Error",
  };

  const detailRule: Record<GetBookingErrorStatus, JSX.Element | string> = {
    all_in_error: "Please contact the event organiser or your sales representative.",
    unknown_error: "Please contact the event organiser or your sales representative.",
    invit_error: event?.eventManagerContacts ? (
      <span>Please contact {getEventOrganizersContacts(event?.eventManagerContacts)} or your sales representative</span>
    ) : (
      "Please contact the event organiser or your sales representative if you want to participate."
    ),
  };

  return (
    <div className="flex-center flex-column mt-5 mx-auto w-50">
      <div className="bg-primary-alpha-md flex-center mb-3" style={{ height: "48px", width: "48px", borderRadius: 24 }}>
        <i className="icon icon-lg line-height-1">info_outline</i>
      </div>
      <h1 className="display-3">{!errorStatus ? titleRule["unknown_error"] : titleRule[errorStatus]}</h1>
      <p className="lead mb-5">{!errorStatus ? detailRule["unknown_error"] : detailRule[errorStatus]}</p>
      {errorStatus === "invit_error" && (
        <ButtonLink className="btn btn-default btn-lg" to={ROUTES.HOME} label="Go to Homepage" />
      )}
    </div>
  );
};

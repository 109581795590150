import * as React from "react";
import { logError } from "utils/logger/logger";
import { ErrorMessage } from "components/layout/ErrorMessage/ErrorMessage";

interface ErrorState {
  hasError: boolean;
  errorMessage?: string;
}

export class ErrorHandler extends React.Component<{}, ErrorState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: undefined };
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorMessage message={this.state.errorMessage} />;
    }
    return this.props.children;
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    logError(error, errorInfo ? errorInfo.componentStack : undefined);
    this.setState({ hasError: true, errorMessage: error ? error.message : " " });
  }
}

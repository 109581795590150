import { apm } from "@elastic/apm-rum";
import { LogMessage } from "./logger.typings";

export const apmLogger = (logMessage: LogMessage, type = "user-interaction"): void => {
  const { name, message } = getApmLoggerMessage(logMessage);

  if (name && message) {
    const transaction = apm.startTransaction(name, type);
    if (transaction) {
      transaction.addLabels(message as unknown as Record<string, string>);
      setTimeout(() => {
        transaction.end();
      });
    }
  }
};

const getApmLoggerMessage = (logMessage: LogMessage): { name: string; message: LogMessage } => {
  return {
    name: logMessage?.name,
    message: logMessage,
  };
};

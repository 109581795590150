import { LogMessage, LogTypes } from "utils/logger/logger.typings";

import { getLoggerEnvironment } from "common/environment";
import { createLogger, Level } from "@dcc-cli/cli-web-logger";
import { trackAnalyticsEvent } from "./webAnalytics";
import { apmLogger } from "./apmLogger";

type Logger = ReturnType<typeof createLogger>;

const logger = createLogger("IC", getLoggerEnvironment());

export const startPerformanceMeasure = (mark: string): void => logger.startPerformanceMeasure(mark);

export const logError = (error: Error, stack: string): void => {
  logger.technical("UnknownError", `${error.message}${stack}`, Level.Error);
};

export const logMessage = (message: LogMessage): void => {
  const eventLogger: Logger = logger
    .setCustom("feature", message.feature)
    .setCustom("action", message.event)
    .setCustom("event", message.event);

  apmLogger(message, "custom-log");

  switch (message.type) {
    case LogTypes.functional:
      eventLogger.functional(message.name, message.description);
      break;
    case LogTypes.technical:
      eventLogger.technical(message.name, message.description, message.level ? message.level : Level.Error);
      break;
    case LogTypes.performance:
      eventLogger
        .setCustom("uptime", `${Math.round(window.performance.now())}`)
        .endPerformanceMeasure(message.name, message.description, message.watchKey);
  }
  trackAnalyticsEvent(message);
};

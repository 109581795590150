import * as React from "react";
import { EventContact } from "api/events/event.types";
import { Avatar } from "@ic-anywhere/ic-components";

export interface CardAvatarProps {
  issuer: EventContact;
}

export const CardAvatar: React.FunctionComponent<CardAvatarProps> = ({ issuer, children }) => (
  <div className="mb-4">
    <ul className="list-unstyled">
      <li>
        <div className="row mb-4">
          <div className="col-2">
            <Avatar name={issuer.friendlyName} additionalClassName="mr-2" avatarType="initials" sizePx={50} />
          </div>
          <div className="col-8 ml-4">
            <span className="font-weight-bold">{`${issuer.friendlyName}`}</span>
            <br />
            <span className="primary">{`${issuer.jobTitle} - ${issuer.accountName}`}</span>
          </div>
        </div>
      </li>
      {children}
    </ul>
  </div>
);

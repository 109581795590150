import * as React from "react";
import { TimeLineItem } from "@ic-anywhere/ic-components";

type Props = {
  date: Date;
  active?: boolean;
};

export const TimelineSlotMultiDateItem: React.FC<Props> = ({ date, active, children }) => (
  <TimeLineItem date={date} active={active}>
    {children}
  </TimeLineItem>
);

import * as React from "react";

export interface Props {
  position: number;
}

export const ShowMore: React.FunctionComponent<Props> = ({ position, children }) => (
  <div key={position} id={`accordion${position}`} className="card-header p-0">
    <a
      id={`attendees${position}`}
      className="btn d-flex justify-content-center p-0 collapsed mb-4"
      data-toggle="collapse"
      data-target={`#collapse${position}`}
      aria-expanded="false"
      aria-controls={`collapse${position}`}
    >
      Show more
      <i className="icon line-height-1 icon-sm ml-2 text-primary">arrow_drop_down</i>
    </a>
    <div
      id={`collapse${position}`}
      className="collapse"
      aria-labelledby={`collapse${position}`}
      data-parent={`#accordion${position}`}
    >
      {children}
    </div>
  </div>
);

import { useQueryClient } from "@tanstack/react-query";
import { usePrefectMyEventsQuery } from "queries/useGetMyEventsQuery";
import { slotsQueryKey } from "./useGetEventDetailQuery";
import { invitationQueryKey } from "./useGetMyInvitationsQuery";

export const useInvitQueriesInvalidation = (eventId: string) => {
  const client = useQueryClient();
  const prefetchMyEvents = usePrefectMyEventsQuery();

  return () => {
    client.invalidateQueries(slotsQueryKey(eventId)).then();
    client.invalidateQueries(invitationQueryKey(eventId)).then();
    client.invalidateQueries(["getMyEvents"]).then();
    prefetchMyEvents();
  };
};

export const useSlotsQueriesInvalidation = (eventId: string) => {
  const client = useQueryClient();

  return () => {
    client.invalidateQueries([eventId]).then();
  };
};

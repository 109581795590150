import * as React from "react";
import { Modal } from "@sgbs-ui/core";
import { ContactPickerWidget, NoRecord } from "@ic-anywhere/ic-components";
import { ContactWidget } from "@ic-anywhere/ic-dal";

export interface Props {
  show: boolean;
  onClose: () => void;
  contactId: string;
  onContactSelected: (contact: ContactWidget) => void;
}

const NavigateAsModal: React.FC<Props> = ({ show, onClose, contactId, onContactSelected }) => {
  const [selectedContact, setSelectedContact] = React.useState<ContactWidget | null>();

  const handleOnContactSelected = (contact: ContactWidget): void => {
    setSelectedContact(contact);
  };

  const handleOnConfirm = (): void => {
    onContactSelected(selectedContact);
  };

  return (
    <Modal title="Navigating as" show={show} onClose={onClose} isScrollable={false} onConfirm={handleOnConfirm}>
      <div style={{ minHeight: "235px" }}>
        <ContactPickerWidget
          isOnlyActive={true}
          placeholder="Search contacts"
          selectedId={selectedContact?.id ?? contactId}
          onChange={handleOnContactSelected}
        />
        {!contactId && <NoRecord reason="Please select a contact to navigate as him" icon="person_search" />}
        {contactId && (
          <NoRecord reason="Please select a different contact or remove the selected one" icon="person_search" />
        )}
      </div>
    </Modal>
  );
};

export default NavigateAsModal;

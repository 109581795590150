import * as React from "react";
import { ROUTES } from "common/routes";
import { Route, Switch } from "react-router-dom";

const corporateDetailRoutes = [
  ROUTES.EVENT_DETAIL_CORPORATE,
  ROUTES.EVENT_DETAIL_CONTACT,
  ROUTES.EVENT_DETAIL_INVITATION_STATUS,
  ROUTES.EVENT_INVESTOR_PROFILE,
  ROUTES.EVENT_FEEDBACK,
];

const EventDashboard = React.lazy(() => import("components/Event/Dashboard/EventDashboard"));
const EventDetailCorporate = React.lazy(() => import("components/Event/Corporate/EventDetailCorporate"));
const ComEvent = React.lazy(() => import("components/Event/ComEvent/ComEvent"));
const EventInvitation = React.lazy(() => import("components/EventInvitation/EventInvitation"));
const EventGroupView = React.lazy(() => import("components/Event/Corporate/EventGroups/EventGroupView"));
const Page404 = React.lazy(() => import("components/layout/ErrorPage/PAGE404"));
const Page500 = React.lazy(() => import("components/layout/ErrorPage/PAGE500"));
const Page503 = React.lazy(() => import("components/layout/ErrorPage/PAGE503"));

export const AppRoutes: React.FunctionComponent = () => {
  return (
    <React.Suspense fallback={""}>
      <Switch>
        <Route exact={true} path={[ROUTES.HOME, ROUTES.EVENTS_DASHBOARD]} component={EventDashboard} />
        <Route exact={true} path={corporateDetailRoutes} component={EventDetailCorporate} />
        <Route exact={true} path={ROUTES.EVENT_INVITATION_COMEVENT_ACTION} component={ComEvent} />
        <Route exact={true} path={ROUTES.EVENT_INVITATION_ROADSHOW_ACTION} component={EventInvitation} />
        <Route exact={true} path={ROUTES.EVENT_INVITATION_ROADSHOW} component={EventInvitation} />
        <Route exact={true} path={ROUTES.EVENT_SUMMARY_ROADSHOW} component={EventGroupView} />
        <Route exact={true} path={ROUTES.PAGE_404} component={Page404} />
        <Route exact={true} path={ROUTES.PAGE_500} component={Page500} />
        <Route exact={true} path={ROUTES.PAGE_503} component={Page503} />
        <Route component={Page404} />
      </Switch>
    </React.Suspense>
  );
};

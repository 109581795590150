import { Button, SearchInput } from "@sgbs-ui/core";
import { useAtom } from "jotai";
import { some } from "lodash";
import * as React from "react";
import { TagsFilter } from "../TagsFilter/TagsFilter";
import { activeFiltersAtom, clearFiltersAtom, searchTermAtom } from "../utils/filtersAtom";

export const EventsListHeader: React.FC = () => {
  const [activeFilters, toggleFilter] = useAtom(activeFiltersAtom);
  const [clearFilter, clearFilters] = useAtom(clearFiltersAtom);
  const [searchTerm, handleSearchTerm] = useAtom(searchTermAtom);
  const [displayFilters, setDisplayFilters] = React.useState(false);

  const onToggleDisplayFilters = () => {
    setDisplayFilters(!displayFilters);
  };
  const isFilterSelected = some(activeFilters, ({ enabled }) => enabled);
  return (
    <>
      <div className="d-flex col-lg-8 col-12 pt-4 pb-2">
        <h2 className="primary font-weight-bold mr-4 d-lg-block d-none">All events</h2>
        <div className="d-flex flex-grow-1 pb-2 justify-content-between">
          <SearchInput
            placeholder="Search by event name"
            size="lg"
            value={searchTerm}
            onChange={handleSearchTerm}
            className="col-lg-12 col-md-10 col-9 p-0"
          />
          <Button
            btnType="flat"
            color={isFilterSelected ? "info" : "secondary"}
            onClick={onToggleDisplayFilters}
            className="d-lg-none"
          >
            <em className="icon mr-1">filter_list</em>
            <span className={`${isFilterSelected ? "text-info" : "text-secondary"}`}>Filter</span>
          </Button>
        </div>
      </div>
      <div className={`d-lg-block d-${displayFilters ? "block" : "none"}`}>
        <TagsFilter onFilter={toggleFilter} tagFilters={[...activeFilters, clearFilter]} onClearFilter={clearFilters} />
      </div>
    </>
  );
};

import * as React from "react";
import { EVENT_CATEGORY, EventTagGroups, EventTargetStatus, TagFilter } from "containers/event/event.types";
import * as styles from "components/Event/Dashboard/TagsFilter/TagsFilter.scss";
import { filter, isEmpty } from "lodash";
import { features } from "utils/logger/features";
import TagGroupToRename from "./TagGroupToRename";
import { featureTracking } from "components/common/event.tracking";

const CURSOR_POINTER_CLASS = "cursor-pointer";

type Props = {
  onFilter: (filterId: EVENT_CATEGORY) => void;
  onClearFilter: () => void;
  tagFilters: TagFilter[];
};

const getTagsByType = (tags: TagFilter[], types: EVENT_CATEGORY[]) => {
  return filter(tags, (tag) => types.includes(tag.id)) ?? [];
};

export const TagsFilter: React.FC<Props> = ({ tagFilters, onFilter, onClearFilter }) => {
  const eventGroupFilters = getTagsByType(tagFilters, EventTagGroups);
  const eventStatusFilters = getTagsByType(tagFilters, EventTargetStatus);

  const filterClick = (filterId: EVENT_CATEGORY) => {
    logFiltersAction(tagFilters[filterId]);
    onFilter(filterId);
  };

  const clear = () => {
    logClearButtonAction();
    onClearFilter();
  };

  return (
    <div className="col-lg-8 col-12 card">
      <div className="body-text regular pb-2 d-none d-lg-block">
        Use tags to filter events. Please note that you need to be registered to attend the event.
      </div>
      <div className="mt-3">
        <div className="d-md-flex flex-md-wrap justify-content-between ">
          <div className="flex-grow-0 flex-shrink-0 mb-4">
            <TagGroupToRename title={"Event type"} tagFilters={eventGroupFilters} onFilter={filterClick} />
          </div>
          <div className="flex-grow-0 flex-shrink-0 mb-4">
            <TagGroupToRename title={"Event status"} tagFilters={eventStatusFilters} onFilter={filterClick} />
          </div>
        </div>
        {!isEmpty(tagFilters) && tagFilters[EVENT_CATEGORY.CLEAR]?.visible && (
          <div className="mb-4">
            <span className={`badge-flat-secondary ${styles[CURSOR_POINTER_CLASS]}`} onClick={clear}>
              Clear filter(s)
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const logFiltersAction = (filter) => {
  const featureName = `${filter.featureLogName}${filter.enabled ? "Remove" : "Activate"}`;
  featureTracking(features[featureName]);
};

const logClearButtonAction = () => {
  featureTracking(features.welcomePageClearButtonReset);
};

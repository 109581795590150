const EVENTS_ROUTES = {
  EVENTS_DASHBOARD: "/events",
  EVENT_INVITATION_COMEVENT_ACTION: "/events/:id/invitation/:action/",
  EVENT_INVITATION_ROADSHOW_ACTION: "/events/:id/roadshow/invitation/:action/",
  EVENT_INVITATION_ROADSHOW: "/events/:id/roadshow/invitation",
  EVENT_DETAIL_CORPORATE: "/events/:id/corporate",
  EVENT_DETAIL_CONTACT: "/events/:id/contact",
  EVENT_DETAIL_INVITATION_STATUS: "/events/:id/status",
  EVENT_INVESTOR_PROFILE: "/events/:id/investor",
  EVENT_FEEDBACK: "/events/:id/feedback",
  EVENT_SUMMARY_ROADSHOW: "/events/:groupName/roadshow",
};

export const ROUTES = {
  HOME: "/",
  USER_SETTING: "/userSetting",
  PAGE_404: "/404",
  PAGE_500: "/500",
  PAGE_503: "/503",
  ...EVENTS_ROUTES,
};

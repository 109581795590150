import * as React from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import * as styles from "components/Event/Dashboard/EventCard/EventCard.scss";
import { AddressPoint } from "components/Event/Corporate/AddressPoint/AddressPoint";
import { EventItem } from "common/event/event.types";
import { isDateBeforeToday } from "utils/dateUtils";
import { INVITATION_STATUS } from "api/events/event.types";
import { buildUrl } from "utils/generateUrl";
import { ROUTES } from "common/routes";
import { REGISTER } from "containers/event/event.types";
import { isEmpty } from "lodash";
import { isStringEqual } from "@ic-anywhere/ic-utils";
import { Highlight } from "@sgbs-ui/core";

const DD_MMM_YYYY = " DD MMM YYYY";

export type Props = {
  event: EventItem;
  displayMoreInfo: boolean;
  history?: History;
  searchTerm?: string;
};

const EventCard: React.FC<Props> = ({ event, searchTerm }) => {
  const startDate = moment(event.startDate).format(DD_MMM_YYYY);
  const endDate = moment(event.endDate).format(DD_MMM_YYYY);

  const { categoryDisplayName, color } = getEventDisplayInfo(event);

  return (
    <Link
      data-event="a event container"
      to={`${buildMoreInfoUrl(event)}`}
      className={
        isDateBeforeToday(event.endDate)
          ? `card card-bordered card-raising pb-2 pb-md-0 p-3 mb-2 mb-md-3 p-md-4 ${styles["past-event"]}`
          : `card card-bordered card-raising pb-2 pb-md-0 p-3 mb-2 mb-md-3 p-md-4`
      }
    >
      <div>
        <span className={`badge badge-flat-${color} badge-prepend-square `}>{categoryDisplayName}</span>
        {event.invitationStatus !== undefined && (
          <span className={`badge badge-flat-${getInvitationColor(event)} badge-prepend-square`}>
            {event.invitationStatus}
          </span>
        )}
        <div title={event.name}>
          <h3 className="font-weight-bold">
            <Highlight term={[searchTerm]} text={event.name} color="info" />
          </h3>
        </div>
      </div>
      <div>
        <div>{startDate !== endDate ? `From ${startDate} to ${endDate}` : startDate}</div>
      </div>
      <AddressPoint addressText={event.address} googleMap={false} />
    </Link>
  );
};

export default EventCard;

const getEventDisplayInfo = (event: EventItem): { categoryDisplayName: string; color: string } => {
  if (event.category === "Event") {
    return { categoryDisplayName: "Business", color: "primary" };
  }

  if (event.type === "Analyst Visit" && (event.category === "Roadshow" || event.category === "LightRoadshow")) {
    return { categoryDisplayName: "Research", color: "faded-jade" };
  }

  if (event.type !== "Credit Event") {
    return { categoryDisplayName: "Corporate Access", color: "azure" };
  }

  return { categoryDisplayName: "Credit Roadshow", color: "spicy-mix" };
};

const getInvitationColor = (event) => {
  switch (event.invitationStatus) {
    case INVITATION_STATUS.ATTENDING:
      return "puerto-rico";
    case INVITATION_STATUS.PENDING:
      return "apricot";
    case INVITATION_STATUS.DECLINED_NOT_INTERESTED:
    case INVITATION_STATUS.DECLINED_NOT_AVAILABLE:
      return "valencia";
    default:
      return "primary-alt";
  }
};

const buildMoreInfoUrl = (event: EventItem) => {
  const id = event.id;
  if (event.category === "Event") {
    return buildUrl(ROUTES.EVENT_INVITATION_COMEVENT_ACTION, { id, action: REGISTER });
  }

  // Roadshow
  if (event.userRole === "Invitee") {
    return buildUrl(ROUTES.EVENT_INVITATION_ROADSHOW, { id });
  }

  // Corporate
  if (event.userRole === "InvitedSpeaker" && isStringEqual(event.groupName, "Roadshow")) {
    return buildUrl(ROUTES.EVENT_DETAIL_CORPORATE, { id });
  }

  // Speaker, Admin
  return isEmpty(event.groupName)
    ? buildUrl(ROUTES.EVENT_DETAIL_CORPORATE, { id })
    : buildUrl(ROUTES.EVENT_SUMMARY_ROADSHOW, { groupName: encodeURIComponent(event.groupName) });
};

import { useQuery } from "@tanstack/react-query";
import useCrmRepository from "api/useCrmRepository";
import { BookingDetail, EventBookingDetails } from "api/events/event.types";
import { getEventByIdQuery, getEventSlotsByIdQuery } from "queries/useGetEventDetailQuery";
import { MergedQueryResult } from "queries/queries.typings";
import { getMyInvitsQuery } from "queries/useGetMyInvitationsQuery";
import { every, find, some } from "lodash";
import { normalizeEventDtoToEvent, normalizeSlotDtoToSlot } from "api/events/event.mapper";

export type GetBookingErrorStatus = "all_in_error" | "invit_error" | "unknown_error" | undefined;

type HookReturnType = MergedQueryResult<EventBookingDetails> & { errorStatus: GetBookingErrorStatus };

export const useGetBookingDetailsQuery = (eventId: string, userId: string): HookReturnType => {
  const repo = useCrmRepository();

  const eventsResult = useQuery(getEventByIdQuery(repo, eventId)),
    slotsResult = useQuery(getEventSlotsByIdQuery(repo, eventId)),
    invitsResult = useQuery(getMyInvitsQuery(repo, eventId));

  const queries = [eventsResult, slotsResult, invitsResult];

  const isError = some(queries, (q) => q.isError);
  const isFetching = some(queries, (q) => q.isFetching);
  const isLoading = some(queries, (q) => q.isLoading);

  const isSuccess = queries[0].isSuccess && queries[1].isSuccess && !queries[2].isFetching;

  const { data: eventDto } = eventsResult;
  const { data: slotsDto } = slotsResult;
  const { data: invitation } = invitsResult;

  const bookings = !slotsDto
    ? []
    : slotsDto.slots.map((slotDto) => {
        const booking = find(slotDto.bookings, (b) => b.inviteeContactId === userId);
        const slot = normalizeSlotDtoToSlot(slotDto);
        return { slot, booking } as BookingDetail;
      });

  const data = isSuccess
    ? {
        event: eventDto ? normalizeEventDtoToEvent(eventDto) : undefined,
        bookings,
        invitation,
      }
    : {
        event: undefined,
        bookings: [],
        invitation: undefined,
      };

  const allInError = !isFetching && every(queries, (q) => q.isError);

  const eventOkButInvitInError = !isFetching && !allInError && queries[2].isError;
  const status: GetBookingErrorStatus =
    isFetching || every(queries, (q) => q.isSuccess)
      ? undefined
      : allInError
      ? "all_in_error"
      : eventOkButInvitInError
      ? "invit_error"
      : "unknown_error";

  return { data, isFetching, isLoading, isSuccess, errorStatus: status, isError };
};

import { reduce, startsWith } from "lodash";

export const generateUrl = (path: string, value: string, key = "id") => path.replace(`:${key}`, value);

export const buildUrl = (path: string, parameters: { [key: string]: string } = {}) =>
  reduce(parameters, (acc, value, key) => acc.replace(startsWith(key, ":") ? key : `:${key}`, value), path);

export const redirectTo = (
  history: { push: (path: string) => void },
  path: string,
  parameters: { [key: string]: string }
) => history.push(buildUrl(path, parameters));

import { Event, EventContact, EventResourceCategory, EventSlotType, INVITATION_STATUS } from "api/events/event.types";
import {
  Address,
  AddressType,
  Booking,
  BookingUpdateModel,
  VenueInfo,
  VirtualLocation,
} from "common/event/event.types";
import { includes } from "lodash";
import { isDateBeforeToday } from "utils/dateUtils";

export interface Filters {
  id: EVENT_CATEGORY;
  category?: string;
  type?: string;
}

export enum EVENT_CATEGORY {
  CORPORATE_ACCESS,
  CREDIT_EVENT,
  RESEARCH,
  BUSINESS,
  PENDING,
  ATTENDING,
  DECLINED,
  ISSUED,
  CLEAR,
}

export const EVENT_CATEGORY_LIST = [
  EVENT_CATEGORY.CORPORATE_ACCESS,
  EVENT_CATEGORY.CREDIT_EVENT,
  EVENT_CATEGORY.RESEARCH,
  EVENT_CATEGORY.BUSINESS,
  EVENT_CATEGORY.PENDING,
  EVENT_CATEGORY.ATTENDING,
  EVENT_CATEGORY.DECLINED,
  EVENT_CATEGORY.ISSUED,
  EVENT_CATEGORY.CLEAR,
];

export const EVENT_CATEGORY_FILTERS = {
  "Corporate access": (filter, event) => EVENT_CATEGORY_FILTERS.getEventFilterByCategoryAndExcludesTypes(filter, event),
  "Credit roadshow": (filter, event) =>
    EVENT_CATEGORY_FILTERS.getEventFilterByCategoryAndExcludesTypes(filter, event) &&
    includes(filter.types, event.type),
  Research: (filter, event) => {
    const filterTypes = filter.types && filter.types.length > 0 && includes(filter.types, event.type);
    return (
      filterTypes &&
      ((filter.category && filter.category === event.category) ||
        (filter.subCategory && filter.subCategory === event.category))
    );
  },
  Business: (filter, event) => filter.category && filter.category === event.category,
  Pending: (filter, event) => filter.invitationStatus === event.invitationStatus,
  Attending: (filter, event) => filter.invitationStatus === event.invitationStatus,
  Declined: (filter, event) =>
    INVITATION_STATUS.DECLINED_NOT_AVAILABLE === event.invitationStatus ||
    INVITATION_STATUS.DECLINED_NOT_INTERESTED === event.invitationStatus,
  Issued: (filter, event) => filter.endDate && isDateBeforeToday(event.endDate),
  All: () => true,
  getEventFilterByCategoryAndExcludesTypes: (filter, event) =>
    filter.category &&
    filter.category === event.category &&
    filter.excludesTypes &&
    filter.excludesTypes.length > 0 &&
    !includes(filter.excludesTypes, event.type),
};

export interface Slot {
  id: string;
  bookings: Booking[];
  invitees: EventContact[];
  attendees: string[];
  address: Address;
  addressType?: AddressType;
  type: EventSlotType;
  startDate?: string;
  endDate?: string;
  online: boolean;
  venueName?: string;
  comment?: string;
  virtualLocation?: VirtualLocation;
  capacity?: number;
  timeZone?: string;
  eventVenue?: VenueInfo;
}

export interface EventBundleRoadshow {
  event: Event;
  slots: Slot[];
  issuers: EventContact[];
}

export interface EventDetail {
  event: Event;
  slots: Slot[];
  useFulContacts: {
    businessContacts?: EventContact[];
    internalSpeakers?: EventContact[];
    yourDriver?: EventContact[];
    eventManagerContacts?: EventContact[];
  };
  issuers: EventContact[];
}

export interface EventResources {
  eventId: string;
  eventName: string;
  eventGroupName?: string;
  viewerResources: Array<{ id: string; category: EventResourceCategory; resourceUrlAndHeader: PDFReaderObject }>;
}

export interface PDFReaderObject {
  url: string;
  httpHeaders?: {
    clientApp?: string;
  };
  withCredentials?: boolean;
}

export interface TagFilter {
  id: EVENT_CATEGORY;
  name: string;
  category?: string;
  subCategory?: string;
  excludesTypes?: string[];
  types?: string[];
  invitationStatus?: INVITATION_STATUS;
  endDate?: string;
  color?: string;
  enabled: boolean;
  visible?: boolean;
}

export interface Registration {
  eventId: string;
  requestedAction: RegistrationActions;
}

export enum RegistrationActions {
  Accept,
  Decline,
  DeclineInvitation,
  Modify,
  MoreInfo,
}

export interface BookingModifications {
  eventId: string;
  bookings: BookingUpdateModel[];
}

export enum RegistrationStatus {
  Attending = "Attending",
  Declined = "Declined",
  Pending = "Pending",
}

export const REGISTER = "register";
export const DECLINE = "decline";
export const DECLINE_INVITATION = "declineInvitation";

export const EventTagGroups = [
  EVENT_CATEGORY.CORPORATE_ACCESS,
  EVENT_CATEGORY.CREDIT_EVENT,
  EVENT_CATEGORY.RESEARCH,
  EVENT_CATEGORY.BUSINESS,
];

export const EventTargetStatus = [
  EVENT_CATEGORY.PENDING,
  EVENT_CATEGORY.ATTENDING,
  EVENT_CATEGORY.DECLINED,
  EVENT_CATEGORY.ISSUED,
];

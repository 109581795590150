import * as React from "react";
import { CardAvatar } from "components/EventInvitation/CardItem/CardAvatar";
import { ShowMore } from "components/EventInvitation/CardItem/ShowMore";
import { chain, isEmpty } from "lodash";
import { EventContact } from "api/events/event.types";

interface Props {
  contacts: EventContact[];
}

export const AvatarInformations: React.FC<Props> = ({ contacts }) => (
  <>
    {isEmpty(issuersSortByAccountName(contacts))
      ? ""
      : issuersSortByAccountName(contacts).map((issuer, position) =>
          position < 3 ? (
            <CardAvatar issuer={issuer} key={position} />
          ) : position === 3 ? (
            <div className="d-flex justify-content-center" key={position}>
              <ShowMore position={position} key={position}>
                <CardAvatar
                  issuer={issuer}
                  children={issuersSortByAccountName(contacts).map((innerIssuer, innerPosition) =>
                    innerPosition > 3 ? <CardAvatar issuer={innerIssuer} /> : null
                  )}
                />
              </ShowMore>
            </div>
          ) : null
        )}
  </>
);

const issuersSortByAccountName = (issuers: EventContact[]): EventContact[] => {
  return chain(issuers).sortBy("accountName").value();
};

import * as React from "react";
import Header from "components/layout/Header/Header";
import Footer from "components/layout/Footer/Footer";

export const MainLayout: React.FunctionComponent = ({ children }) => {
  return (
    <>
      <Header />
      <div className="main" role="main">
        {children}
      </div>
      <Footer />
    </>
  );
};

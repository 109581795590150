import { EventContact, INVITATION_STATUS } from "api/events/event.types";

export type EventUserRole = "Invitee" | "Speaker" | "Admin" | "InvitedSpeaker";

export interface EventItem {
  id: string;
  name: string;
  groupName?: string;
  type: string;
  startDate: Date;
  endDate: Date;
  address: string;
  city?: string;
  invitationStatus?: INVITATION_STATUS;
  category: string;
  status?: number;
  url?: string;
  inviteeWebSiteUrl?: string;
  inviteeDeclineLink?: string;
  userRole: EventUserRole;
  bundle?: boolean;
  description?: string;
  usefulInformation?: string;
}

export interface EmailDeliveryStatus {
  status: string;
  reason?: string;
}

export interface Event {
  id: string;
  category?: string;
  startDate: Date;
  endDate: Date;
  name: string;
  webSiteUrl?: string;
  inviteeWebSiteUrl?: string;
  inviteeDeclineLink?: string;
  type?: string;
  status?: number;
  invitationStatus?: number;
  venue?: VenueInfo;
  description?: string;
  usefulInformation?: string;
}

export interface VenueInfo {
  id?: string;
  name?: string;
  address: Address;
}

export interface Address {
  venueName?: string;
  street1?: string;
  street2?: string;
  street3?: string;
  zipCode?: string;
  city?: string;
  country?: CountryInfo;
}

export interface CountryInfo {
  iso2?: string;
  iso3?: string;
  name?: string;
}

export enum InvitationStatus {
  Pending = 1,
  Accepted = 2,
  DeclinedNotAvailable = 3,
  DeclinedNotInterested = 4,
}

export enum BookingStatus {
  Booked = 1,
  Cancelled = 2,
  NoShow = 3,
  Declined = 4,
  Pending = 5,
}

export enum AddressType {
  BusinessAddress = "1",
  ClientAddress = "2",
  CustomAddress = "3",
  None = "4",
}

export interface BookingWriteModel {
  id?: string;
  bookedByContactId?: string;
  inviteeContactId?: string;
  status?: BookingStatus;
}

export interface Booking extends BookingWriteModel {
  id: string;
  invitee?: EventContact;
  bookedBy?: ContactShortReference;
}

export interface ContactShortReference {
  id: string;
  friendlyName?: string;
}

export enum BookingModificationAction {
  Update,
  Create,
}

export interface BookingUpdateModel {
  action: BookingModificationAction;
  slotId: string;
  bookingId?: string;
  booking: BookingWriteModel;
}

export type VirtualLocation = {
  url: string;
  phone?: string;
  phoneCode?: string;
};

import * as React from "react";
import { EventExtractProgramProps } from "components/Event/Corporate/EventExtractProgram/EventExtractProgram";
import { isStringEmpty, mapDateWithTimeZone, mapToDisplayPeriodWithTimezone, parseDate } from "@ic-anywhere/ic-utils";
import { getReorderedSlotsByDateWithTimeZone } from "components/Event/Corporate/Tabs/SlotsDetail/SlotsDetailBody.utils";
import { Slot } from "containers/event/event.types";
import { filter, isEmpty } from "lodash";
import { AddressType, BookingStatus } from "common/event/event.types";
import { EventSlotType, showCommentForSlotTypes } from "api/events/event.types";
import { isAddressComplete } from "utils/address/addressUtil";
import { AddressPoint } from "components/Event/Corporate/AddressPoint/AddressPoint";
import VirtualLocation from "components/EventInvitation/EditableSlotItem/VirtualLocation";
import { AttendeeItem } from "components/Event/Corporate/Tabs/CardItem/AttendeeItem";

const slotIconType: Record<EventSlotType, string> = {
  [EventSlotType.OneToOne]: "person",
  [EventSlotType.Accomodation]: "hotel",
  [EventSlotType.AllDay]: "update",
  [EventSlotType.Cocktail]: "local_bar",
  [EventSlotType.Travel]: "directions_car",
  [EventSlotType.GroupBreakfast]: "local_cafe",
  [EventSlotType.GroupMeeting]: "groups",
  [EventSlotType.GroupLunch]: "restaurant",
};

function ProgramDetail({ slot }: { slot: Slot }) {
  const displaySlotCapacity = `(${filter(slot.bookings, (b) => b.status === BookingStatus.Booked).length}/${
    slot.capacity
  })`;
  const displayEventVenue = slot.type === EventSlotType.GroupLunch && slot.addressType === AddressType.BusinessAddress;
  const addressToDisplay = displayEventVenue
    ? { ...slot.eventVenue?.address, venueName: slot.eventVenue?.name }
    : slot.address;

  return (
    <li className="d-flex my-3 break-inside-avoid">
      <div className="d-flex justify-content-between w-25">
        <i className="icon icon-md">{slotIconType[slot.type]}</i>
        <p className="pt-2">
          {mapToDisplayPeriodWithTimezone(
            {
              startDate: parseDate(slot.startDate),
              endDate: parseDate(slot.endDate),
            },
            slot.timeZone
          )}
        </p>
      </div>
      <div className="ml-5">
        <p className="font-weight-bold pt-2 mb-2">{`${slot.type} ${displaySlotCapacity}`}</p>
        {!isEmpty(slot.invitees) && (
          <p className="mb-2">
            <AttendeeItem invitees={slot.invitees} />
          </p>
        )}
        {isAddressComplete(addressToDisplay) && (
          <p className="mb-2">
            <AddressPoint address={addressToDisplay} />
          </p>
        )}
        {slot.virtualLocation && slot.virtualLocation.url && (
          <p className="mb-2">
            <VirtualLocation {...slot.virtualLocation} className="ml-1 text-secondary" />
          </p>
        )}
        {!isStringEmpty(slot.comment) && showCommentForSlotTypes(slot.type) && <p className="mb-2">{slot.comment}</p>}
      </div>
    </li>
  );
}

export default function Program({ eventDetail: { slots } }: EventExtractProgramProps): JSX.Element {
  const reorderSlotsGroupByDate = getReorderedSlotsByDateWithTimeZone(slots);

  return (
    <div className="my-5">
      <ul className="list-unstyled">
        {reorderSlotsGroupByDate.map(({ slots, date }, index) => {
          return (
            <li className="mt-5 mb-5" key={index}>
              <h4 className="mt-4 mb-4 socgen-color">
                {mapDateWithTimeZone(date, "dd MMM yyyy")} - day {index + 1}
              </h4>
              <ul className="list-unstyled mb-4">
                {slots.map((slot, index) => (
                  <ProgramDetail slot={slot} key={index} />
                ))}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

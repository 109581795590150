import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { EventFeedbackDTO } from "api/events/event.types";
import useCrmRepository from "api/useCrmRepository";
import { getEventFeedbacks } from "api/events/event.api";
import { twoMinutesInMs } from "@ic-anywhere/ic-dal";

export const useEventFeedbacksQuery = (eventId: string, enabled = true): UseQueryResult<EventFeedbackDTO[]> => {
  const repo = useCrmRepository();

  return useQuery<EventFeedbackDTO[]>({
    queryKey: ["getEventFeedbacks", eventId],
    queryFn: () => getEventFeedbacks(repo, eventId),
    enabled: enabled && !!eventId,
    cacheTime: twoMinutesInMs,
  });
};

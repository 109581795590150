import * as React from "react";
import { isEmpty } from "lodash";
import { EventResourceView } from "api/events/event.types";

type Props = {
  documents: EventResourceView[];
};

export const EventDocumentsList: React.VFC<Props> = ({ documents }) => (
  <div>
    <ul className="list-unstyled">
      {isEmpty(documents)
        ? ""
        : documents.map((document, index) => (
            <li key={index}>
              <a href={`${document.resourceUrlAndHeader.url}?clientApp=ic`}>{document.fileName}</a>
            </li>
          ))}
    </ul>
  </div>
);

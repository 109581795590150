import * as React from "react";
import { isEmpty } from "lodash";
interface Props {
  title: string | React.ReactNode;
  id: number;
  accordionId: string;
  skipFirst?: boolean;
  onCollapse?: (expanded: boolean) => void;
  bottomText?: string | React.ReactNode;
}

export const CollapsibleContactsCard: React.FC<Props> = ({
  id,
  title,
  accordionId,
  bottomText,
  children,
  onCollapse,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>();

  const handleOnClick = () => {
    if (buttonRef && buttonRef.current) {
      const expanded = !JSON.parse(buttonRef.current.getAttribute("aria-expanded"));
      onCollapse?.(expanded);
    }
  };
  return (
    <div className="card mb-2 border-top p-md-3 p-1">
      <div className="pl-0 card-header" id="headingOne">
        <button
          id={`dataItem${id}`}
          className="btn btn-flat-primary justify-content-start pl-0 ml-2 mw-100 text-truncate collapsed"
          data-toggle="collapse"
          data-target={`#collapseOne${id}`}
          aria-expanded="false"
          aria-controls={"collapseTwo"}
          ref={buttonRef}
          onClick={handleOnClick}
        >
          <i className="icon icon-sm ml-0 mr-2 d-inline-block">keyboard_arrow_right</i>
          <span className="text-primary text-large font-weight-bold d-inline-block">{title}</span>
        </button>
      </div>
      <div id={`collapseOne${id}`} className="collapse" aria-labelledby="headingOne" data-parent={`#${accordionId}`}>
        <div className="card-body pt-0 pl-xs-0">{children}</div>
        {!isEmpty(bottomText) && <>{bottomText}</>}
      </div>
    </div>
  );
};

import * as React from "react";
import { CircleIcon } from "components/common/CircleIcon/CircleIcon";
import { Button, Modal } from "@sgbs-ui/core";

type Props = {
  isSaving: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const DeclineMultiConfirmationModal: React.VFC<Props> = ({ isSaving, onClose, onConfirm }) => {
  const renderHeader = () => (
    <div className="d-flex align-items-center flex-column w-100">
      <CircleIcon status="warning">
        <i className={`icon icon-md text-warning line-height-1`}>event_busy</i>
      </CircleIcon>
      <h3 className={`modal-title row text-warning mb-2`}>Decline invitation</h3>
      <p className="text-large text-secondary m-0">You are about to decline all slots, do you confirm?</p>
    </div>
  );

  const renderFooter = () => (
    <div className="modal-footer">
      <Button text="Cancel" btnType="flat" color="secondary" size="lg" className="ml-2" onClick={onClose} />
      <Button text="Confirm" onClick={onConfirm} color="warning" size="lg" disabled={isSaving} loading={isSaving} />
    </div>
  );

  return (
    <Modal
      show={true}
      size="md"
      type="warning"
      renderHeaderActions={renderHeader}
      renderFooterActions={renderFooter}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  );
};

import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { requestMe, twoMinutesInMs } from "@ic-anywhere/ic-dal";
import useCrmRepository from "api/useCrmRepository";
import { UserInfo } from "api/userProfile.types";

export const useRequestMeQuery = (enabled: boolean): UseQueryResult<UserInfo> => {
  const repo = useCrmRepository({ disableImpersonating: true });
  const qry = () =>
    requestMe(repo, ["scope", "mainEmail"]).then((res) => {
      if (!res) {
        return null;
      }
      const userInfo: UserInfo = {
        userId: res.userId,
        isIcUser: res.isIcUser,
        userEmail: res.userEmail,
        id: res.userId,
      };
      return userInfo;
    });

  return useQuery<UserInfo, string>({ queryKey: ["requestMe"], queryFn: qry, enabled, cacheTime: twoMinutesInMs });
};
